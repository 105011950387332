import React, {Component} from "react";
import {app} from "../app/app";
import {getSetting} from "../settings/settings.js";
import MainNav from "../components/layout/MainNav";
import logoFinaer from "../images/logo-finaer.svg";
import {Footer} from "../components/layout/Footer";
import GoogleMapReact from "google-map-react";
import {Loader} from "../components/elements/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Sucursales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            mainOffice: {
                name: "CASA CENTRAL",
                lat: -34.6042329,
                long: -58.4148648,
            },
            officesByArea: undefined
        };

        this.miniMapsOptions = this.miniMapsOptions.bind(this);
        this.renderMapOrImage = this.renderMapOrImage.bind(this);
    }

    componentDidMount() {
        app.apiClient().getBranchOffices().then(
            (result) => {
                let offices = result['result']['oficinas'];
                this.setState({officesByArea: offices, loading: false});
            }
        );
    }

    miniMapsOptions() {
        return {
            zoomControl: false,
            fullscreenControl: false
        };
    }

    renderMapOrImage(office) {
        if (office['es_virtual']) {
            return <img src={logoFinaer} className="finaer-logo" alt="logo de finaer"/>;
        } else {
            return (
                <GoogleMapReact
                    options={this.miniMapsOptions}
                    bootstrapURLKeys={{key: getSetting('GOOGLE_MAP_API_KEY')}}
                    defaultCenter={{
                        lat: office['latitud'],
                        lng: office['longitud']
                    }}
                    defaultZoom={16}>
                    <Marker text={office['nombre']}
                            icon="map-pin"
                            lat={office['latitud']}
                            lng={office['longitud']}/>
                </GoogleMapReact>
            );
        }
    }

    content() {
        if (this.state.loading) {
            return (
                <main className="sections">
                    <Loader message="Espere unos segundos mientras recuperamos los datos"/>
                </main>
            )
        } else {
            return (
                <main className="sections">
                    <div className="site-content cards-content">
                        <section className="section-box header-box main-office">
                            <header>
                                <h1>Sucursales Finaer</h1>
                                <p>Casa central</p>
                            </header>
                            <img src={logoFinaer} className="finaer-logo" alt="logo de finaer"/>
                            <div className="map">
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: getSetting('GOOGLE_MAP_API_KEY')}}
                                    defaultCenter={{
                                        lat: this.state.mainOffice.lat,
                                        lng: this.state.mainOffice.long
                                    }}
                                    defaultZoom={16}>
                                    <Marker text="Finaer"
                                            icon="map-pin"
                                            lat={this.state.mainOffice.lat}
                                            lng={this.state.mainOffice.long}/>
                                </GoogleMapReact>
                            </div>
                            <div className="contact-info">
                                <div className="contact-item">
                                    <i className="material-icons">location_on</i>
                                    <p>Av. Corrientes 3360, 6º</p>
                                </div>
                                <div className="contact-item">
                                    <a target="_blank" href="https://api.whatsapp.com/send?phone=541168425100"
                                       rel="noopener noreferrer"><FontAwesomeIcon className="icon-whatsapp"
                                                                                  icon={["fab", "whatsapp"]}/></a>
                                    <i className="material-icons">phone_iphone</i>
                                    <a href="tel:+541168425100">6842-5100</a>
                                </div>
                                <div className="contact-item">
                                    <i className="material-icons">mail_outline</i>
                                    <a href="mailto:info@finaersa.com.ar">info@finaersa.com.ar</a>
                                </div>
                                <div className="contact-item">
                                    <i className="material-icons">schedule</i><p>Horario de atencion: Todas nuestras
                                    oficinas atienden de lunes a viernes en el horario de 10 a 19 hs</p>
                                </div>
                            </div>
                        </section>
                        <section className="headquarters">
                            {Object.keys(this.state.officesByArea).map(area => {
                                return (
                                    <div className="headquarters-group" key={area}>
                                        <div className="group-title">
                                            <i className="material-icons">map</i>
                                            <p className="subtitle">{area}</p>
                                        </div>
                                        <div className="card-group">
                                            {this.state.officesByArea[area].map(office => {
                                                return (
                                                    <div className="card" key={office['nombre']}>
                                                        <div
                                                            className="card-image waves-effect waves-block waves-light">
                                                            {this.renderMapOrImage(office)}
                                                        </div>
                                                        <div className="card-content">
                                                            <span
                                                                className="card-title activator grey-text text-darken-1">
                                                                {office['nombre']}
                                                                <i className="material-icons right">more_vert</i>
                                                            </span>
                                                            <div className="contact-item">
                                                                <i className="material-icons">location_on</i>
                                                                <p>{office['domicilio']}</p>
                                                            </div>
                                                        </div>
                                                        <div className="card-reveal">
                                                            <span className="card-title grey-text text-darken-1">
                                                                {office['nombre']}
                                                                <i className="material-icons right">close</i>
                                                            </span>
                                                            <div className="contact-item">
                                                                <i className="material-icons">location_on</i>
                                                                <p>{office['domicilio']}</p>
                                                            </div>
                                                            <div className="contact-item">
                                                                <i className="material-icons">phone_iphone</i>
                                                                <a href={"tel:" + office['telefono']}>{office['telefono']}</a>
                                                            </div>
                                                            <div className="contact-item">
                                                                <i className="material-icons">mail_outline</i>
                                                                <a href={"mailto:" + office['email']}>{office['email']}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </section>
                    </div>
                </main>
            )
        }
    }

    render() {
        return (
            <div>
                <MainNav/>
                {this.content()}
                <Footer/>
            </div>
        );
    }
}

class Marker extends React.Component {
    render() {
        return (
            <div className="map-marker">
                <p>{this.props.text}</p>
                <FontAwesomeIcon className="icon" icon={this.props.icon}/>
            </div>
        )
    }
}

export default Sucursales;
