import React, {Component} from "react";
import "../../../styles/App.scss";
import {Col, Row} from "react-materialize";
import {DropZone} from "../../../components/request-form/Dropzone.js";
import {FinaerSelect} from "../../elements/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export class DocumentLoad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            participantId: this.defineDefaultParticipantId(),
            participantType: 'applicants'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.selectedParticipant = this.selectedParticipant.bind(this);
        this.selectedParticipantName = this.selectedParticipantName.bind(this);
        this.participantOptions = this.participantOptions.bind(this);
        this.updateDocuments = this.updateDocuments.bind(this);
        this.fullName = this.fullName.bind(this);
        this.controlledDocuments = this.controlledDocuments.bind(this);
    }

    defineDefaultParticipantId() {
        return Object.keys(this.props.applicants)[0];
    }

    selectedParticipant() {
        return this.props[this.state.participantType][this.state.participantId];
    }

    documentsData() {
        return this.selectedParticipant().documentsData;
    }

    employeeType() {
        return this.selectedParticipant().participantData['employeeType'].value();
    }

    selectedParticipantName() {
        return this.selectedParticipant().participantData['firstName'].value();
    }

    fullName(panticipantData) {
        let name = '';
        const firstName = panticipantData.firstName.value() || '';
        const lastName = panticipantData.lastName.value() || '';
        if(!firstName && !lastName) {
            return 'Sin nombre'
        }

        name += firstName;
        if(firstName && lastName) {
            name += ' ';
        }
        name += lastName;

        return name;
    }

    controlledDocuments(){
        return this.selectedParticipant().controlledDocuments;
    }

    checkItemList(document) {
        if (this.hasTheDocument(document)) {
            return <FontAwesomeIcon className="icon" icon="check"/>
        }
    }

    classOfItemList(document){
        if (this.hasTheDocument(document)) {
            return "document-accepted"
        }
    }

    hasTheDocument(document){
        return this.controlledDocuments().includes(document)
    }

    updateDocuments(newFiles) {
        this.props.updateData(
            this.state.participantType,
            this.state.participantId,
            'documentsData',
            'documents',
            newFiles
        );
    }

    handleInputChange(event) {
        const values = event.target.value.split("-");
        const participantType = values[0];
        const participantId = values[1];
        this.setState({participantType: participantType, participantId: participantId});
    }

    participantOptions() {
        let options = [];

        Object.keys(this.props.applicants).forEach((applicantId) => {
            options.push({
                label: this.fullName(this.props.applicants[applicantId].participantData),
                value: 'applicants-' + applicantId
            })
        });

        Object.keys(this.props.coApplicants).forEach((coApplicantId) => {
            options.push({
                label: this.fullName(this.props.coApplicants[coApplicantId].participantData),
                value: 'coApplicants-' + coApplicantId
            });
        });

        return options;
    }

    renderNeededDocumentation() {
        switch (this.employeeType()) {
            case 'RESPONSABLE INSCRIPTO/AUTONOMO':
                return (
                    <div>
                        <h3 className="form-subtitle">Responsables inscriptos y/o autónomos</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('CI')}>
                                {this.checkItemList('CI')}
                                <p>Constancia de inscripción</p></li>
                            <li className={this.classOfItemList('UDJG')}>
                                {this.checkItemList('UDJG')}
                                <p>DDJJ de Ganancias de personas físicas (Form 711)</p></li>
                            <li className={this.classOfItemList('U3IVA')}>
                                {this.checkItemList('U3IVA')}
                                <p>3 Últimas Presentaciones de IVA (Form 2002)</p></li>
                            <li className={this.classOfItemList('6UF')}>
                                {this.checkItemList('6UF')}
                                <p>En caso de IVA Exento, presentar 6 facturas emitidas actualizadas dentro del último trimestre</p></li>
                            <li className={this.classOfItemList('3UPA')}>
                                {this.checkItemList('3UPA')}
                                <p>3 Últimos pagos de autónomos</p></li>
                        </ul>
                    </div>
                );
            case 'MONOTRIBUTISTA':
                return (
                    <div>
                        <h3 className="form-subtitle">Monotributistas</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('DNI')}>
                                {this.checkItemList('DNI')}
                                <p>DNI Tarjeta (Frente y Dorso)</p></li>
                            <li className={this.classOfItemList('CC')}>
                                {this.checkItemList('CC')}
                                <p>Constancia de Categoría o Inscripción AFIP</p></li>
                            <li className={this.classOfItemList('U3PM')}>
                                {this.checkItemList('U3PM')}
                                <p>3 últimos pagos de monotributo</p></li>
                            <li className={this.classOfItemList('6UF')}>
                                {this.checkItemList('6UF')}
                                <p>6 últimas facturas emitidas o las de mayor importe del último trimestre</p></li>
                        </ul>
                    </div>
                );
            case 'EMPLEADO':
                return (
                    <div>
                        <h3 className="form-subtitle">Relación de dependencia</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('DNI')}>
                                {this.checkItemList('DNI')}
                                <p>DNI: Tarjeta (Frente y Dorso)</p></li>
                            <li className={this.classOfItemList('URDS')}>
                                {this.checkItemList('URDS')}
                                <p>Últimos recibos de sueldo (el último si es mensual, los dos últimos si son quincenales)</p>
                            </li>
                        </ul>
                    </div>
                );
            case 'OTRO':
                return (
                    <div>
                        <h3 className="form-subtitle">Relación de dependencia</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('DNI')}>
                                {this.checkItemList('DNI')}
                                <p>DNI (Frente y Dorso)</p></li>
                            <li className={this.classOfItemList('URDS')}>
                                {this.checkItemList('URDS')}
                                <p>Últimos recibos de sueldo (el último si es mensual, los dos últimos si son quincenales)</p>
                            </li>
                        </ul>
                        <h3 className="form-subtitle">Monotributistas</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('DNI')}>
                                {this.checkItemList('DNI')}
                                <p>DNI (Frente y Dorso)</p></li>
                            <li className={this.classOfItemList('CC')}>
                                {this.checkItemList('CC')}
                                <p>Constancia de Categoría o Inscripción AFIP</p></li>
                            <li className={this.classOfItemList('U3PM')}>
                                {this.checkItemList('U3PM')}
                                <p>3 últimos pagos de monotributo</p></li>
                            <li className={this.classOfItemList('6UF')}>
                                {this.checkItemList('6UF')}
                                <p>6 últimas facturas emitidas o las de mayor importe del ultimo trimestre</p></li>
                            <li className={this.classOfItemList('HC')}>
                                {this.checkItemList('HC')}
                                <p>Para Comerciantes: Habilitación del comercio o Constancia Trámite de Habilitación</p></li>
                            <li className={this.classOfItemList('MP')}>
                                {this.checkItemList('MP')}
                                <p>Para Profesionales: Matrícula o Licencia</p></li>
                        </ul>
                        <h3 className="form-subtitle">Responsables inscriptos y/o autónomos</h3>
                        <ul className="document-list">
                            <li className={this.classOfItemList('CI')}>
                                {this.checkItemList('CI')}
                                <p>Constancia de inscripción</p></li>
                            <li className={this.classOfItemList('UDJG')}>
                                {this.checkItemList('UDJG')}
                                <p>DDJJ de Ganancias de personas físicas (Form 711)</p></li>
                            <li className={this.classOfItemList('U3IVA')}>
                                {this.checkItemList('U3IVA')}
                                <p>3 Últimas Presentaciones de IVA (Form 2002)</p></li>
                            <li className={this.classOfItemList('6UF')}>
                                {this.checkItemList('6UF')}
                                <p>En caso de IVA Exento, presentar 6 facturas emitidas actualizadas dentro del último trimestre</p></li>
                            <li className={this.classOfItemList('3UPA')}>
                                {this.checkItemList('3UPA')}
                                <p>3 Últimos pagos de autónomos</p></li>
                            <li className={this.classOfItemList('MP')}>
                                {this.checkItemList('MP')}
                                <p>Para Profesionales: Matrícula</p></li>
                            <li className={this.classOfItemList('HC')}>
                                {this.checkItemList('HC')}
                                <p>Para Comerciantes: Habilitación del comercio</p>
                            </li>
                        </ul>
                    </div>
                );
            default:
                return (
                    <div>
                        <p>Completá los datos laborales de {this.selectedParticipantName()} para conocer los documentos
                            que tenés que presentar</p>
                    </div>
                );
        }
    }

    render() {
        return (
            <div className="form-content">
                <h2 className="form-header">Documentación requerida</h2>
                <div className="form-inputs documents-input">
                    <Row>
                        <div className="col s12">
                            <div className="react-select -padding-adjustment">
                                <label className="active -add-padding">Identificá a quién corresponden los documentos que estás cargando</label>
                                <FinaerSelect
                                    className="document-type-select"
                                    selectedOption={this.state.participantType + '-' + this.state.participantId}
                                    onChange={this.handleInputChange}
                                    options={this.participantOptions()}/>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col s={this.props.isMobile ? 12 : 6}>
                            {this.renderNeededDocumentation()}
                        </Col>
                        <Col s={this.props.isMobile ? 12 : 6}>
                            <DropZone initialDocuments={this.documentsData()['documents'].value() || []}
                                      updateDocuments={this.updateDocuments}
                                      applicantType={this.state.participantType}/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

