import React from 'react';

export class MailButton extends React.Component {
    render() {
        return (
            <button className={this.props.isDisabled ? 'mail-btn -disabled' : 'mail-btn'}
                    onClick={this.props.handleSubmit}
                    disabled={this.props.isDisabled}> Confirmar </button>
        );
    }
}