import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {HelperModal} from "../../modals/HelperModal";
import {FinaerSelect} from "../../elements/Select";
import NumberFormat from "react-number-format";
import {CheckedInput} from "../../elements/CheckedInput";
import {ControlledCheck} from "../../elements/ControlledCheck";


export class JobDataThree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seniorityOptions: this.getSeniorityOptions(40)
        };

        this.textForSeniority = this.textForSeniority.bind(this);
        this.incomeChanged = this.incomeChanged.bind(this);
    }

    hasMinimumSeniorityRequirement() {
        return this.props.participantData['employeeType'].value() === 'RESPONSABLE INSCRIPTO/AUTONOMO' ||
                this.props.participantData['employeeType'].value() === 'MONOTRIBUTISTA'
    }

    getSeniorityOptions(hastaEnAnios) {
        let options = [];

        if(!this.hasMinimumSeniorityRequirement()) {
            options.push({value: '0-3 meses', label: '0 a 3 meses'});
            options.push({value: '4-11 meses', label: '4 a 11 meses'});
        }

        options.push({value: '1 año', label: '1 año'});
        for (let cantidadAnios = 2; cantidadAnios <= hastaEnAnios; cantidadAnios++) {
            const value = cantidadAnios + ' años';
            options.push({value: value, label: value})
        }

        return options;
    }

    incomeChanged(value) {
        this.props.handleIncomeChange('income', value.value)
    }

    textForSeniority() {
        return 'Si sos Monotributista o Responsable Inscripto debés tener más de un año de antigüedad';
    }

    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Actividad laboral</h2>
                    <ControlledCheck checked={this.props.jobDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row>
                        <CheckedInput s={this.props.isMobile ? 12 : 6} type="text" label="CUIT/CUIL personal"
                                      maxLength={13}
                                      defaultValue={this.props.participantData['cuit'].value()}
                                      onChange={this.props.handleCuitInputChange('cuit')}
                                      error={this.props.participantData['cuit'].error()}
                                      checked={this.props.jobDataChecked}/>
                    </Row>
                    <Row>
                        <div className="combined-input col input-field m6 s12 -helper ">

                                <div className="react-select -helper-space">
                                    <label className="active">Antigüedad</label>
                                    <FinaerSelect
                                        selectedOption={this.props.participantData['seniority'].value()}
                                        onChange={this.props.handleInputChange('seniority')}
                                        placeholder="Seleccione"
                                        options={this.state.seniorityOptions}
                                        className={this.props.jobDataChecked ?  "-disable-select -padding-adjustment" : "-padding-adjustment"}
                                        checked={this.props.jobDataChecked}/>
                                </div>

                            <HelperModal
                                title="Antigüedad"
                                text={this.textForSeniority()}/>
                        </div>
                        <NumberFormat customInput={CheckedInput} s={this.props.isMobile ? 12 : 6} label="Ingreso neto"
                                      thousandSeparator={'.'} decimalSeparator={','} prefix={'$'}
                                      defaultValue={this.props.participantData['income'].value()}
                                      onValueChange={this.incomeChanged}
                                      error={this.props.participantData['income'].error()}
                                      checked={this.props.jobDataChecked}/>
                    </Row>
                </div>
            </div>
        );
    }
}