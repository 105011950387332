import React, {Component} from 'react';
import '../styles/App.scss';
import logo from "../images/logo-finaer.svg";
import CreateWarrantyLeadWizardStepOne from "../components/warranty-lead/CreateWarrantyLeadWizardStepOne";
import CreateWarrantyLeadWizardStepTwo from "../components/warranty-lead/CreateWarrantyLeadWizardStepTwo";
import {app} from "../app/app";
import Link from "react-router-dom/es/Link";
import CreateWarrantyLeadWizardStepThree from "../components/warranty-lead/CreateWarrantyLeadWizardStepThree";
import {withRouter} from "react-router-dom";
// import {registerGoogleTagManagerEvent} from "../lib/googleTagManager";
import WarrantyApplicationSourceParameterMapper from "../models/WarrantyApplicationSourceParameterMapper";


class CreateWarrantyLeadWizard extends Component {
    constructor(props) {
        super(props);
        let leadFields = this.defaultLeadFields();
        let actualStep = 1
        if (this._hasPreloadedLeadIn(props)) {
            leadFields = this.warrantyLeadToLeadFields(props.location.state.warrantyLead, leadFields)
            actualStep = 3
        }
        this.state = {
            loading: true,
            actualStep: actualStep,
            realEstate: [],
            cities: [],
            leadFields: leadFields,
        };

        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleLeadCreation = this.handleLeadCreation.bind(this);
        this.handleLeadEdition = this.handleLeadEdition.bind(this);
        this.handleNewWarrantyApplicationFromLead = this.handleNewWarrantyApplicationFromLead.bind(this);
        this.logInUserAndRedirectToDashboard = this.logInUserAndRedirectToDashboard.bind(this);
        this.handleStepFinished = this.handleStepFinished.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.redirectToDashboard = this.redirectToDashboard.bind(this);
    };

    _hasPreloadedLeadIn(props) {
        return props.location.state !== undefined && props.location.state.warrantyLead !== undefined;
    }

    defaultLeadFields() {
        return {
            id: {},
            name: {},
            surname: {},
            idType: {value: 'DNI'},
            idNumber: {},
            poll: {value: ''},
            email: {},
            email2: {},
            city: {},
            realEstate: {},
            newRealEstate: {},
            phoneAreaCode: {},
            phoneNumber: {},
            source: {value: this._source()},
        };
    }

    defaultFieldValue(fieldName) {
        const defaultFields = this.defaultLeadFields();
        const leadField = defaultFields[fieldName];
        return leadField ? leadField.value : undefined;
    }

    _source() {
        const query = new URLSearchParams(this.props.location.search);
        const sourceParameter = query.get("source");
        const warrantyApplicationSourceParameterMapper = new WarrantyApplicationSourceParameterMapper();
        return warrantyApplicationSourceParameterMapper.sourceCodeFor(sourceParameter, () => undefined)
    }

    componentDidMount() {
        app.apiClient().getInitRequestNeededData(this.onLoadData);
    }

    onLoadData(dataLoaded) {
        this.setState({
            provinces: dataLoaded['regions'].provinces(),
            cities: dataLoaded['regions'].cities(),
            realEstate: dataLoaded['realEstate'].realEstate(),
            loading: false
        });
    }

    // Fields management

    leadFromFields(leadFields) {
        return Object.keys(leadFields).reduce((data, currentKey) => {
            data[currentKey] = leadFields[currentKey].value;
            return data;
        }, {});
    }

    asMergeLeadFieldsWith(warrantyLead) {
        return this.warrantyLeadToLeadFields(warrantyLead, {...this.state.leadFields})
    }

    warrantyLeadToLeadFields(warrantyLead, leadFields = {}) {
        Object.entries(warrantyLead).forEach( ([fieldName, fieldValue]) => {
            const value = fieldValue || this.defaultFieldValue(fieldName);
            leadFields[fieldName] = {value: value, error: ''};
        });
        return leadFields
    }

    // Event handling

    handleLeadCreation(leadFields, aResponseCallback) {
        app.apiClient().newLead(this.leadFromFields(leadFields), aResponseCallback);
    }

    handleLeadEdition(leadFields, aResponseCallback) {
        app.apiClient().editLead(this.leadFromFields(leadFields), aResponseCallback);
    }

    handleNewWarrantyApplicationFromLead(warrantyLead, aResponseCallback) {
        // TODO: seguir en la card de conversion de lead.
        // Utilizar algo asi como #newWarrantyFromLead en lugar de #newWarrantyApplication
        // registerGoogleTagManagerEvent({'event': 'CrearSolicitud', 'event_category': 'Finaer', 'event_label': ''});
        app.apiClient().newWarrantyFromLead(warrantyLead, aResponseCallback);
    }

    logInUserAndRedirectToDashboard(warrantyApplicationNumber) {
        if (!app.userIsLoggedIn()) {
            this.loginUser(warrantyApplicationNumber, this.redirectToDashboard, this.loginError)
        } else {
            this.redirectToDashboard();
        }
    }

    loginUser(warrantyApplicationNumber, successCallback, errorCallback) {
        // Validar que esten bien actualizados los datos del lead, pensar si no deberia trabajar sobre la solicitud
        // resultante
        let applicationData = this.state.leadFields;
        app.loginUser(
            applicationData.idType.value, applicationData.idNumber.value, warrantyApplicationNumber, successCallback,
            errorCallback);
    }

    loginError() {
        throw new Error('LOGIN_ERROR');
    }

    redirectToDashboard() {
        this.props.history.push({
            pathname: '/dashboard',
            state: {
                active: ' -user-help-active',
                disabled: ' -user-help-disable'
            }
        });
    }

    handleStepFinished(warrantyLead) {
        const leadFields = this.asMergeLeadFieldsWith(warrantyLead);
        const actualStep = this.state.actualStep;
        this.setState({leadFields: leadFields, actualStep: actualStep + 1});
    }

    handleNext() {
        const actualStep = this.state.actualStep;
        this.setState({actualStep: actualStep + 1});
    }

    handlePrevious() {
        const actualStep = this.state.actualStep;
        this.setState({actualStep: actualStep - 1});
    }

    renderContent() {
        switch (this.state.actualStep) {
            case 1:
                return <CreateWarrantyLeadWizardStepOne
                    leadFields={this.state.leadFields}
                    createLead={this.handleLeadCreation}
                    editLead={this.handleLeadEdition}
                    onFinishDo={this.handleStepFinished}/>;
            case 2:
                return <CreateWarrantyLeadWizardStepTwo
                    leadFields={this.state.leadFields}
                    handlePrevious={this.handlePrevious}
                    editLead={this.handleLeadEdition}
                    onFinishDo={this.handleStepFinished}/>;
            case 3:
                return <CreateWarrantyLeadWizardStepThree
                    provinces={this.state.provinces}
                    cities={this.state.cities}
                    loading={this.state.loading}
                    realEstate={this.state.realEstate}
                    leadFields={this.state.leadFields}
                    editLead={this.handleLeadEdition}
                    newWarrantyFromLead={this.handleNewWarrantyApplicationFromLead}
                    logInUserAndRedirectToDashboard={this.logInUserAndRedirectToDashboard}
                    handlePrevious={this.handlePrevious}/>;
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="layout-request begin">
                <header>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </Link>
                </header>
                {this.renderContent()}
            </div>
        );
    }
}

export default withRouter(CreateWarrantyLeadWizard);