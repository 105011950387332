import React from "react";
import PaymentBase from "../PaymentBase";
import {LeftButton} from "../../buttons/LeftButton";
import logo from "../../../images/logo-finaer.svg";
import Link from "react-router-dom/Link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// AGUS - DESACTIVAR PROMOCION

export default class SelectPaymentCashStepThree extends PaymentBase{

    constructor(props) {
        super(props);

        this.name= 'efectivo';
    }

    _branchOfficeAddress(){
        return (
            <div className='branch-office'>
                <Link className="site-logo" to="">
                    <img src={logo} className="app-logo" alt="logo"/>
                </Link>
                <div className="client-address">
                    <h2>{this.props.warrantyInformation.office.location}</h2>
                    <div className="address-street">
                        <FontAwesomeIcon className="icon" icon='map-marker-alt'/>
                        <p>{this.props.warrantyInformation.office.address}</p>
                    </div>
                </div>
            </div>
        );
    }

    title() {
        return <h1>Pago en efectivo</h1>
    }

    body() {
        return (
            <div className="middle-bar-content payment-bar cash-payment">
                {this._amountToPay()}
                {this._onePaymentDiscount( this.props.warrantyInformation.cashDiscount)}
                <p className="payment-information-text">
                    Podrás abonar en tu sucursal más cercana concertando una cita previa
                </p>
                {this._branchOfficeAddress()}
            </div>
        );
    }

    footer() {
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="arrows-payment">
                    <LeftButton onClick={this.props.handlePrevious}/>
                    {this._confirmPaymentBtn(this.props.createPaymentLead)}
                </div>
                {/* <p style={{marginTop: '40px', width: '90%', maxWidth: '600px', fontSize: '10px', textAlign: 'justify'}}><span style={{fontWeight: 'bold'}}>Términos & Condiciones - Promoción Sale Week: </span>Promoción válida para clientes de Sistema Finaer S.A., con: A)Solicitudes de garantía iniciadas entre el 01 y 07 de Julio de 2024, y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía dentro del mismo período. B)Solicitudes de garantía iniciadas entre el  01 y 07 de Julio de 2024, y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía a más tardar el 12 de Julio de 2024. C)Solicitudes de garantía iniciadas con fecha previa al 01 de Julio de 2024 y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía entre el 01 y el 05 de Julio de 2024. Promoción no acumulable con otros descuentos o formas de pago. Descuento de 25% válido para pagos de contado en efectivo y en los términos expresados, independientemente de la duración del contrato.</p> */}
            </div>

        );
    }
}