export class LeadResponse {
    static newFrom(apiResponse) {
        let typeOfResponse = this.from(apiResponse);
        return new typeOfResponse(apiResponse);
    }

    static from(apiResponse) {
        let subclasses = [SuccessfulLeadResponse, LeadFieldErrorResponse];
        const responseClass = subclasses.find(subclass => subclass.accepts(apiResponse))
        if (responseClass === undefined) {
            throw Error(`Invalid response for ${apiResponse}`)
        } else {
            return responseClass
        }
    }

    static accepts(apiResponse) {
        throw new Error("Subclass responsibility");
    }

    constructor(apiResponse) {
        this.apiResponse = apiResponse;
        this.result = apiResponse['resultado'];
    }

    fieldMapping() {
        return {
            id: "id",
            nombre: 'name',
            apellido: 'surname',
            email: 'email',
            email2: 'email2',
            tipo_doc: 'idType',
            num_doc: 'idNumber',
            telefono_codigo: 'phoneAreaCode',
            telefono_numero: 'phoneNumber',
            origen: 'source'
        };

    }

    fieldNameFor(name) {
        return this.fieldMapping()[name]
    }

    hasErrors() {
        throw new Error("subclass responsibility");
    }

    manageIn(aComponent) {
        throw new Error("subclass responsibility");
    }
}

export default class SuccessfulLeadResponse extends LeadResponse {
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'OK';
    }

    hasErrors() {
        return false
    }

    lead() {
        const prospecto = this.apiResponse.prospecto || this.apiResponse.object;
        return Object.entries(prospecto).reduce((data, [name, value]) => {
            const leadField = this.fieldNameFor(name)
            data[leadField] = value;
            return data;
        }, {});
    }


    manageIn(aComponent) {
        aComponent.manageSuccessfulLeadResponse(this);
    }
}


export class LeadFieldErrorResponse extends LeadResponse {
    /*
    *  Ej: //{"resultado": "ERROR", "codigo": 1, "detalle": {"celular_codigo": ["Este campo es requerido."],
    * "celular_numero": ["Este campo es requerido."], "tipo_doc": ["Este campo es requerido."],
    * "num_doc": ["Este campo es requerido."]}}
    * */
    static accepts(apiResponse) {
        return apiResponse['resultado'] === 'ERROR' && apiResponse['codigo'] === 1;
    }

    manageIn(aComponent) {
        aComponent.manageLeadFieldErrorResponse(this);
    }

    hasErrors() {
        return true
    }

    errorCode() {
        return this.apiResponse['codigo'];
    }

    fieldErrors() {
        let errors = {};
        let errorsInResponse = this.apiResponse.detalle;
        if (errorsInResponse) {
            Object.keys(errorsInResponse).forEach(eachFieldName => {
                if (eachFieldName !== 'non_field_errors') {
                    let mappedField = this.fieldNameFor(eachFieldName);
                    errors[mappedField] = errorsInResponse[eachFieldName][0];
                }
            });
        }

        return errors
    }
}