import React from "react";
import logoSSN from "../../images/LogoSSN.jpg";

export class LegalFooter extends React.Component {
    render() {
        return <div className="legal-footer-extended">
            <div className="legal-footer">
                <div>
                    <p>N° de inscripción SSN</p>
                    <p> 0317 - 0618 - 0117 - 0436</p>
                </div>
                <div>
                    <p>Atención al asegurado</p>
                    <p>0800-666-8400</p>
                </div>
                <div>
                    <p>Organismo de Control</p>
                    <p>www.ssn.gob.ar</p>
                </div>
                <div>
                    <img src={logoSSN} alt=""/>
                </div>
            </div>
            <div className="legal-foot-note">
                <div className='left-foot-note'>
                    <p>
                        Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 0317 - 0618 - 0117 - 0436, de
                        conformidad con la Resolución SSN ° 38052 del 20/12/2013: Sistema Finaer SA. es una sociedad anónima según
                        la ley argentina. Los seguros son emitidos por las compañías aseguradoras. Consulte compañía aseguradoras de acuerdo al
                        producto solicitado. Superintendencia de Seguros de Nación teléfono 0800-666-8400 y su página web www.argentina.gob.ar/ssn
                    </p>
                </div>
                <div className='right-foot-note'>
                    <p>El Servicio de Atención al Asegurado está integrado por:</p>
                    <p>RESPONSABLE: Bazillo, Nahuel</p>
                    <p>SUPLENTE: Dasso, Mariano</p>
                </div>
            </div>
        </div>
    }
}