import React, {Component} from "react";
import ReactDropZone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class DropZone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: []
        };

        this.onDrop = this.onDrop.bind(this);
        this.removeFileFromList = this.removeFileFromList.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.canSendFiles = this.canSendFiles.bind(this);
    }

    onDrop(acceptedFiles) {
        let files = this.state.files;
        acceptedFiles.forEach((file) => {
            files.push({file: file, state: 'pendiente'});
        });

        this.setState({files: files});
    };

    removeFileFromList(fileName) {
        let files = this.state.files;
        this.setState({files: files.filter(fileData => fileData.file.name !== fileName)});
    }

    handleSend() {
        this.props.updateDocuments(this.state.files);
        this.setState({files: []});
    }

    canSendFiles() {
        return this.state.files.length > 0;
    }

    render() {
        const initialFiles = this.props.initialDocuments.map(fileData => (
            <li key={fileData.file.name + '-enviado'}>
                <p className="state-upload -enviado">enviado</p>
                {fileData.file.name}
            </li>
        ));

        const files = this.state.files.map(fileData => (
            <li key={fileData.file.name + fileData.file.size}>
                <p className="state-upload -cargando">
                    pendiente
                </p>
                {fileData.file.name}
                <button className="button-erase" onClick={() => this.removeFileFromList(fileData.file.name)}>
                    <FontAwesomeIcon className="icon" icon="times"/>
                </button>
            </li>
        ));

        return (
            <ReactDropZone onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => (
                    <section className="file-upload container">
                        <h3 className="form-subtitle">Carga de documentos</h3>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <div>
                                <p>Arrastrá archivos acá o hacé click acá para cargar</p>
                            </div>
                            <input {...getInputProps()} />
                        </div>
                        <aside>
                            <p className="form-subtitle">Archivos</p>
                            <ul>
                                {initialFiles}
                                {files}
                            </ul>
                            <button onClick={this.handleSend} className="main-btn" disabled={!this.canSendFiles()}>
                                Enviar archivos
                            </button>
                            <p className="warning-text">Los archivos enviados serán revisados dentro de las 48hs hábiles</p>
                        </aside>
                    </section>
                )}
            </ReactDropZone>
        );
    }
}