export class User {
    constructor(warrantyApplicationNumber) {
        this._name = undefined;
        this._authToken = undefined;
        this._warrantyApplicationNumber = warrantyApplicationNumber;
    }

    name() {
        return this._name;
    }

    warrantyApplicationNumber() {
        return this._warrantyApplicationNumber;
    }

    update(name) {
        this._name = name;
    }

    setAuthToken(token) {
        this._authToken = token;
    }

    removeAuthToken() {
        this._authToken = undefined;
    }

    authToken() {
        return this._authToken;
    }
}