import React from "react";
import {Modal} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class MercadopagoStatusModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'isOpen': true
        }

        this._onClose = this._onClose.bind(this)
    }

    _onClose() {
        this.setState({'isOpen': false});
        this.props.onClose();
    }

    _approvedBody() {
        return <p>
            Verás reflejado el mismo en el panel de inicio de tu solicitud.
        </p>
    }

    _pendingBody() {
        return <p>
            Debemos esperar la aprobación para confirmar el pago.
            Podrás consultar el estado de tu operación en el Panel de Inicio de tu solicitud.
        </p>
    }

    _rejectedBody() {
        return <div className="info-mp">
            <p>Lamentamos esta noticia.</p>
            <p>Te sugerimos contactar a la Oficina Comercial de {this.props.office.name} para gestionar con otra opción
                de pago.</p>
            <div className="office-mp-info">
                <p className="office-mp-row">
                    <span className="mp-info-span">Teléfono:</span><p>{this.props.office.phone}</p>
                </p>
                <p className="office-mp-row">
                    <span className="mp-info-span">Mail:</span><p>{this.props.office.email}</p>
                </p>
            </div>
        </div>
    }

    _parseMercadopagoInfo() {
        const status = this.props.paymentInformation.status;
        const message = this.props.paymentInformation.message;
        const statusTranslation = {
            'APROBADO': ['APROBADO', this._approvedBody()],
            'PENDIENTE': ['PENDIENTE', this._pendingBody()],
            'CANCELADO': ['CANCELADO', this._rejectedBody()]
        }
        let info = [];
        if (!statusTranslation.hasOwnProperty(status)) {
            info = [status, ''];
        } else {
            info = statusTranslation[status];
        }
        return {'status': info[0], 'body': info[1], 'message': message}
    }

    render() {
        const info = this._parseMercadopagoInfo();
        return (
            <Modal header={'Estado de tu Pago'} open={this.state.isOpen} className="modal-mercadopago"
                   modalOptions={{complete: this._onClose}}>
                <div className="modal-body">
                    <div className="modal-main mercadopago-redirect-body">
                        <p>
                            Tu pago por Mercadopago finalizó con estado:
                            <span className="mp-state-span">
                                &nbsp;{info.status}
                            </span>
                        </p>
                        <p>
                            {info.message}
                        </p>
                        {info.body}
                        <button className="modal-close" onClick={this._onClose}>
                            <FontAwesomeIcon className="icon" icon="times"/>
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}
