import {withRouter} from "react-router-dom";
import React, {Component} from 'react';
import Link from "react-router-dom/Link";
import logo from "../images/logo-finaer.svg";
import SelectPaymentMethodStep from "../components/payment-lead/step-one/SelectPaymentMethodStep";
import {app} from "../app/app";
import SelectPaymentMercadopago from "../components/payment-lead/step-two/SelectPaymentMercadopago";
import TransferOrCashPayment from "../components/payment-lead/TransferOrCashPayment";
import {Loader} from "../components/elements/Loader";
import SelectPaymentMercadoPagoWallet from "../components/payment-lead/step-two/SelectPaymentMercadoPagoWallet";


const CASH_METHOD_PAYMENT = "EFECTIVO";
const TRANSFER_METHOD_PAYMENT = "TRANSFERENCIA";
const MERCADOPAGO_METHOD_PAYMENT = "MERCADO_PAGO";
const MERCADOPAGO_WALLET_METHOD_PAYMENT = "MERCADO_PAGO_WALLET";

class CreatePaymentLeadWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            actualStep: 0,
            paymentMethod: "",
            amountOfInstallments: 1,
            warrantyInformation: props.location.state,
            mercadoPagoDiscount: undefined,
            loading: false,
            paymentInfo: {},
            installmentsAllowed: []
        }

        // Move between steps
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);

        // Buttons for sections
        this.handleRedirectDashboard = this.handleRedirectDashboard.bind(this);
        this.handleMercadoPagoPaymentWithStatus = this.handleMercadoPagoPaymentWithStatus.bind(this);
        this.settleAmountOfInstallments = this.settleAmountOfInstallments.bind(this);
        this._isAPaymentIn = this._isAPaymentIn.bind(this);

        // API
        this.createMercadoPagoApiPayment = this.createMercadoPagoApiPayment.bind(this);
    }

    // componentDidMount() {
    //     // TODO: Hacer esto una única vez en fixture
    //     app.apiClient().mercadoPagoCyberWeekDiscount((response) => {this.setState({mercadoPagoDiscount: response})})
    // }

    componentWillMount() {
        let handleApiResponse = (response) => {
            this.setState({installmentsAllowed: response.installmentsAllowed()})
        }
        const warrantyApplicationNumber = app.loggedInUser().warrantyApplicationNumber();
        app.apiClient().getWarrantyPaymentOptionsDetails(warrantyApplicationNumber, handleApiResponse);
    }

    // Move between steps

    handleNext() {
        const actualStep = this.state.actualStep;
        this.setState({loading: false, actualStep: actualStep + 1});
    }

    handlePrevious() {
        const actualStep = this.state.actualStep;
        this.setState({loading: false, actualStep: actualStep - 1});
    }

    handleRedirectDashboard(status=undefined, message=undefined) {
        let statusUrlParam = status ? 'status=' + status : '';
        let messageUrlParam = message ? 'message=' + message : ''
        this.props.history.push({
            pathname: '/dashboard',
            search: '?' + statusUrlParam + '&' + messageUrlParam,
            state: {
                active: ' -user-help-active',
                disabled: ' -user-help-disable'
            }
        });
    }

    // Api

    _paymentData() {
        let paymentMethod = this.state.paymentMethod;
        return {
            'cantidad_de_cuotas': this.state.amountOfInstallments,
            'nro_solicitud': app.loggedInUser().warrantyApplicationNumber(),
            'modo_de_pago': paymentMethod === MERCADOPAGO_WALLET_METHOD_PAYMENT ? MERCADOPAGO_METHOD_PAYMENT : paymentMethod
        }
    }

    // Select payment method

    handleMercadopagoPaymentSelected(paymentMethod){
        // If Mercado Pago is selected then i ask for payment data, as i know is one installment
        this.setState({
                paymentMethod: paymentMethod,
                amountOfInstallments: 1,
                loading: true,
                actualStep: 1
            },
            () => {
                this._requestPaymentData();
            }
        );
    }

    handlePaymentMethodSelected(paymentMethod) {
        this.setState({paymentMethod: paymentMethod}, () => {this.handleNext();});
    }

    // Select installments

    settleAmountOfInstallments(amountOfInstallments) {
        this.setState({amountOfInstallments: amountOfInstallments, loading:true},
            () => {this._requestPaymentData();});
    }


    // Request of payment details

    requestPaymentDataFor(paymentData) {
        app.apiClient().getInformationForNewWarrantyPayment(paymentData, (response) => {
            this._handlePaymentInfoApiResponse(response)
        });
    }

    _requestPaymentData() {
        const paymentData = this._paymentData();
        this.requestPaymentDataFor(paymentData);
    }

    _handlePaymentInfoApiResponse(response) {
        if (response.hasErrors()) {
            return this.handleRedirectDashboard();
        }
        this.setState({paymentInfo: response.requestDetails(), loading:false});
    }

    createMercadoPagoApiPayment(card, installment, nid, onResponse) {
        let warrantyNumber = app.loggedInUser().warrantyApplicationNumber();
        app.apiClient().settleMercadoPagoPayment(warrantyNumber, card, installment, nid, onResponse);
    }

    // Handle users elections
    handleMercadoPagoPaymentWithStatus(status, message) {
        this.handleRedirectDashboard(status, message);
    }

    _isAPaymentIn(paymentMethod) {
        return this.state.paymentMethod === paymentMethod;
    }

    _hasWarrantyInstallmentsOptions(){
        return this.state.installmentsAllowed === []
    }

    _selectPaymentMethod() {
        if (this._hasWarrantyInstallmentsOptions()) {
            return <Loader/>
        }
        return <SelectPaymentMethodStep
            loading={this.state.loading}
            mercadoPagoDiscount={this.state.mercadoPagoDiscount}
            onTransferSelected={() => this.handlePaymentMethodSelected(TRANSFER_METHOD_PAYMENT)}
            onCashSelected={() => this.handlePaymentMethodSelected(CASH_METHOD_PAYMENT)}
            onWalletSelected={() => this.handleMercadopagoPaymentSelected(MERCADOPAGO_WALLET_METHOD_PAYMENT)}
            onMercadoPagoSelected={() => this.handleMercadopagoPaymentSelected(MERCADOPAGO_METHOD_PAYMENT)}
            warrantyInformation={this.state.warrantyInformation}/>;
    }

    _renderMercadopagoPaymentContent() {
        return <SelectPaymentMercadopago
            loading={this.state.loading}
            createMercadoPagoApiPayment={this.createMercadoPagoApiPayment}
            handlePrevious={this.handlePrevious}
            hasOnePayment={true}
            createPaymentLead={this.creatPaymentLead}
            paymentInfo={this.state.paymentInfo}
            handleMercadoPagoPaymentWithStatus={this.handleMercadoPagoPaymentWithStatus}
            warrantyInformation={this.state.warrantyInformation}/>
    }

    _renderMercadoPagoWalletPaymentContent() {
        return <SelectPaymentMercadoPagoWallet
            handlePrevious={this.handlePrevious}
            hasOnePayment={true}
            paymentInfo={this.state.paymentInfo}
            warrantyInformation={this.state.warrantyInformation}/>
    }

    creatPaymentLead = (onResponse) => {
        const paymentData = this._paymentData();
        app.apiClient().settlePaymentMode(paymentData, (response) => {
            onResponse(response);
        });
    }

    _renderCashOrTransferSelected() {
        return <TransferOrCashPayment
            loading={this.state.loading}
            handleRedirectDashboard={this.handleRedirectDashboard}
            isACashPayment={this.state.paymentMethod === CASH_METHOD_PAYMENT}
            handlePrevious={this.handlePrevious}
            paymentInfo={this.state.paymentInfo}
            installmentsAllowed={this.state.installmentsAllowed}
            paymentData={() => {return this._paymentData()}}
            creatPaymentLead={this.creatPaymentLead}
            warrantyInformation={this.state.warrantyInformation}
            settlePaymentFor={this.settleAmountOfInstallments}/>
    }

    renderContent() {
        // First step is the same for everybody
        if (this.state.actualStep === 0) {
            return this._selectPaymentMethod();
        }
        if (this._isAPaymentIn(MERCADOPAGO_METHOD_PAYMENT)) {
            return this._renderMercadopagoPaymentContent()
        } else if (this._isAPaymentIn(MERCADOPAGO_WALLET_METHOD_PAYMENT)){
            return this._renderMercadoPagoWalletPaymentContent();
        } else {
            return this._renderCashOrTransferSelected();
        }
    }

    render() {
        return (
            <div className="layout-request begin">
                <header>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </Link>
                </header>
                {this.renderContent()}
            </div>
        );
    }
}

export default withRouter(CreatePaymentLeadWizard);