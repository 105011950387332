import {Modal} from "react-materialize";
import {CloseButton} from "../buttons/CloseButton";
import React from "react";
import {app} from "../../app/app";
import moment from "moment";

class PaymentSummaryWidget extends React.Component{
    constructor(props) {
        super(props);
        this.warrantyPayment = this.props.warrantyPayment;
    }

    _formatDate(stringDate){
        return moment(stringDate, "YYYY-MM-DD").format('DD/MM/YYYY')
    }

    _installmentRow(installment, index){
        return (
            <li key={index}>
                <div className="installment-data">
                    <p className="installment-item">{this._installment(index)}</p>
                    <p className="installment-item">{installment.payment ? `$${installment.payment}` : '$-'}</p>
                    <p className="installment-item installment-date">
                        {installment.payment_expiration ? this._formatDate(installment.payment_expiration) : '-'}
                    </p>
                </div>
                {this._addStatusSymbol(installment.is_paid)}
            </li>
        );
    }

    _installmentSummary(installments){
        if (!this._isPaidInMultipleInstallment()) {return;}
        return <div className="installments-description">
            <h4>Usted posee un plan de {installments.length} cuotas</h4>
            <div>
                {installments.map((installment, index) => {
                    return this._installmentRow(installment, index)
                })}
            </div>
        </div>
    }

    _installment(installmentNumber) {
        return installmentNumber === 0 ? 'Anticipo' : `Cuota ${installmentNumber}`
    }

    _isPaidInMultipleInstallment() {
        return this.warrantyPayment['installments'].length > 1;
    }

    _addStatusSymbol(isPaid) {
        if(isPaid) {
            return <span className="check">&#10004;</span>
        } else {
            return <span className="cross">&#10006;</span>
        }
    }

    _paymentSummary(){
        const installments = this.warrantyPayment['installments'];
        const installment = installments[0]
        return <div className="installments">
            {this._firstPaymentStatus(installment.is_paid)}
            {this._installmentSummary(installments)}
        </div>
    }

    _firstPaymentStatus(isPaid) {
        let firstPaymentStatus;
        if (this._isPaidInMultipleInstallment()) {
            firstPaymentStatus = isPaid ? 'Pago confirmado de anticipo' : 'Esperando confirmación de pago de anticipo';
        } else {
            firstPaymentStatus = this._oneInstallmentPaymentStatusMessage(isPaid);
        }
        return <div>{firstPaymentStatus}</div>
    }

    _oneInstallmentPaymentStatusMessage(isPaid) {
        if (isPaid) {
            return 'Pago confirmado'
        }
        return this._isAMercadoPagoPayment() ? this._mercadoPagoPaymentStatusMessage() : 'Esperando confirmación de pago';
    }

    _isAMercadoPagoPayment() {
        return this.warrantyPayment['payment_mode'] === 'Mercado Pago';
    }

    _mercadoPagoPaymentStatusMessage() {
        if (this.warrantyPayment['is_mercadopago_payment_approved']) {
            return 'Pago aceptado - Verás reflejado el pago en el término de 24 hs hábiles'
        }
        return 'Esperando confirmación de pago'
    }

    _paymentMethod(){
        let title = "Método de pago seleccionado";
        if (this._isPaidInMultipleInstallment()){
            title += " para anticipo";
        }
        return <h2 className="item">
            {`${title}: `}
            <span>{this.warrantyPayment['payment_mode']}</span>
        </h2>
    }

    _hasPaymentPlan() {
        return this.warrantyPayment['installments'].length > 0;
    }

    render() {
        return <div className="payment-method-information">
            {this._paymentMethod()}
            {this._hasPaymentPlan() ? this._paymentSummary() : ''}
            <div className="item">
                Si pagaste por billetera y algo salió mal, no te preocupes. Aguarda 10 minutos y volvé a empezar.
                Podés realizar otro intento de pago. Muchas gracias.
            </div>
        </div>
    }

}

export class PaymentMethodModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            requestWarrantyPayment: {}
        };

        this.handleApiResponse = this.handleApiResponse.bind(this);
    }

    _warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    handleApiResponse(response) {
        if (response.hasErrors()) {
            this.setState({errorMessage: response.nonFieldErrors(),
                                loading: false});
        } else {
            this.setState({requestWarrantyPayment: response.requestDetails(),
                                loading: false});
        }
    }

    componentWillMount() {
        const warrantyApplicationNumber = this._warrantyApplicationNumber();
        app.apiClient().getWarrantyPaymentDetails(warrantyApplicationNumber, this.handleApiResponse);
    }

    warrantyPaymentInformation(){
        if (this.state.loading){
            return <div>Consiguiendo la información de su pago</div>
        }
        return <PaymentSummaryWidget warrantyPayment={this.state.requestWarrantyPayment}/>
    }

    modalContent() {
        return <div className="payment-modal-body">
            {this.warrantyPaymentInformation()}
            <div className="contact-payment-item-box item">
                <p>Para cualquier consulta comunicarse a:</p>
                <p>tel: {this.props.office.phone}</p>
                <p>{this.props.office.email}</p>
            </div>
            <button className="modal-close main-btn item">
                Entendido
            </button>
        </div>
    }

    renderModalTriggerBtn(){
        return <button className={"main-btn dashboard-nav-btn payment-btn"}>
            Detalle de Pagos
        </button>
    }

    render() {
        return (
            <Modal header={<h2 className={"title-payment"}>Estado del pago de tu Garantía</h2>}
                   trigger={this.renderModalTriggerBtn()}
                   modalOptions={{complete: this.onCloseModal}}>
                <div className="modal-body">
                    <div className="modal-main">
                        {this.modalContent()}
                        <CloseButton/>
                    </div>
                </div>
            </Modal>
        );
    }
}