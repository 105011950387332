import React, {Component} from "react";
import {app} from "../app/app";
import MainNav from "../components/layout/MainNav";
import {Footer} from "../components/layout/Footer";
import {Input, Row} from "react-materialize";
import {containsSubstringLike} from "../lib/others";
import {LoaderSection} from "../components/elements/LoaderSection";
import {Paginator} from "../components/elements/Paginator";
import {ProvinceManager, CityManager} from "../models/managers";
import {FinaerAutocomplete} from "../components/elements/Autocomplete";

class Inmobiliarias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,

            realEstate: undefined,
            provinces: undefined,
            cities: undefined,

            name: '',
            city: '',
            province: '',
            total : 0,

            filteredRealEstate: undefined,
            citySelectOptions: [],
            provinceSelectOptions: [],

            provinceSelected: undefined,
            citySelected: undefined,
            enteredText: undefined,

            firstRealEstateInPage: 0,
            lastRealEstateInPage: 9,
            activePage: 1
        };

        this.onLoadRealEstate = this.onLoadRealEstate.bind(this);
        this.onLoadProvincesAndCities = this.onLoadProvincesAndCities.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleProvinceAutoComplete = this.handleProvinceAutoComplete.bind(this);
        this.handleCityAutoComplete = this.handleCityAutoComplete.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
        this.changeItemsInPage = this.changeItemsInPage.bind(this);
        this.getRealEstateToShow = this.getRealEstateToShow.bind(this);
    }

    onLoadRealEstate(dataLoaded) {
        this.setState({
            realEstate: dataLoaded.realEstate(),
            filteredRealEstate: dataLoaded.realEstate(),
            total : dataLoaded.amountOfRealEstates(),
            loading: false
        });
    }

    onLoadProvincesAndCities(dataLoaded) {
        let provinces = dataLoaded.provinces();
        let cities = dataLoaded.cities();

        this.setState({
            provinces: provinces,
            cities: cities,
            citySelectOptions: this.names(cities),
            provinceSelectOptions: this.names(provinces),
        });
    }

    componentDidMount() {
        app.apiClient().getRealEstate(this.state.activePage, this.state.name, this.state.city,
                                        this.state.province, true, false, this.onLoadRealEstate);
        app.apiClient().getProvincesAndCities(this.onLoadProvincesAndCities);
    }

    names(provincesOrCities) {
        return provincesOrCities.map((item) => item.name());
    }

    handleEnterPress(event) {
        if (event.key === 'Enter' && !this.state.loading) {
            event.preventDefault();

            this.handleSearch();
        }
    }

    handleSearch() {
        this.setState({
            loading : true,
            name : this.state.enteredText,
            province : this.state.provinceSelected,
            city : this.getIdOf(this.state.citySelected, this.state.cities)
        });
        this.setState({loading: false}, () => this.changeItemsInPage(1));
    }

    getIdOf(cityName, cities){
        let citySelected = '';
        if(cityName) {
            let city = CityManager.get(cities, cityName);
            citySelected = city.id();
        }

        return citySelected
    }

    handleTextChange(event) {
        this.setState({enteredText: event.target.value});
    }

    handleProvinceAutoComplete(provinceNameOrNull) {
        let provinceSelected;
        let citySelectOptions = this.names(this.state.cities);

        if(provinceNameOrNull) {
            let province = ProvinceManager.get(this.state.provinces, provinceNameOrNull);
            provinceSelected = province.code();
            citySelectOptions = this.names(province.cities());
        }

        this.setState({provinceSelected: provinceSelected, citySelectOptions: citySelectOptions});
    }

    handleCityAutoComplete(city) {
        this.setState({citySelected: city});
    }

    changeItemsInPage(pageSelected) {
        this.setState({
            loading : true,
            activePage: pageSelected
        });
        app.apiClient().getRealEstate(pageSelected, this.state.name, this.state.city,
                                        this.state.province, true, false, this.onLoadRealEstate);
    }

    getRealEstateToShow() {
        const filteredRealEstate = this.state.filteredRealEstate;
        // if (this.state.firstRealEstateInPage < 10) {
        //     return _.shuffle(filteredRealEstate).slice(this.state.firstRealEstateInPage, this.state.lastRealEstateInPage);
        // }

        return filteredRealEstate.slice(this.state.firstRealEstateInPage, this.state.lastRealEstateInPage);
    }

    renderFilters() {
        return (
            <section className="section-box header-box">
                <header>
                    <h1>Inmobiliarias adheridas</h1>
                    <p>Buscador de inmobiliarias</p>
                </header>
                <Row className={"-overlap-fix-above"}>
                    <div className={"col input-field m4 s12 -autocomplete"}>
                        <FinaerAutocomplete
                            label={'Provincia'}
                            itemToString={item => (item ? item : '')}
                            items={this.state.provinceSelectOptions}
                            filterFunction={(item, inputValue) => {
                                return inputValue.length > 4 && containsSubstringLike(inputValue, item)
                            }}
                            onSelect={this.handleProvinceAutoComplete}
                        />
                    </div>
                    <div className={"col input-field m4 s12 -autocomplete"}>
                        <FinaerAutocomplete
                            label={'Ciudad / Localidad'}
                            itemToString={item => (item ? item : '')}
                            items={this.state.citySelectOptions}
                            filterFunction={(item, inputValue) => {
                                return inputValue.length > 4 && containsSubstringLike(inputValue, item)
                            }}
                            onSelect={this.handleCityAutoComplete}
                        />
                    </div>
                    <Input m={4} s={12} label="Nombre" onChange={this.handleTextChange}
                           onKeyPress={this.handleEnterPress}/>
                </Row>
                <button className="main-btn" onClick={this.handleSearch} disabled={this.state.loading}>Buscar</button>
            </section>
        )
    }

    renderRealEstateList() {
        return (
            <section className="card-group real-estate">
                {this.getRealEstateToShow().map(realEstate => {
                    return (
                        <div className="card" key={realEstate.name()}>
                            <div className="card-image waves-effect waves-block waves-light">
                                <img src={realEstate.logoUrl()} alt="logo de Abrugati Propiedades"/>
                            </div>
                            <div className="card-content">
                                <span className="card-title activator grey-text text-darken-1">
                                    {realEstate.name()}
                                    <i className="material-icons right">more_vert</i>
                                </span>
                                <div className="contact-item">
                                    {
                                        realEstate.web() ?
                                            <a className="link" href={realEstate.web()}>{realEstate.web()}</a>
                                            :
                                            <p className="link">{realEstate.email()}</p>
                                    }
                                </div>
                            </div>
                            <div className="card-reveal">
                                <span className="card-title grey-text text-darken-1">
                                    {realEstate.name()}
                                    <i className="material-icons right">close</i>
                                </span>
                                <div className="contact-item">
                                    <i className="material-icons">location_on</i>
                                    <p>{realEstate.address()}</p>
                                </div>
                                <div className="contact-item">
                                    <i className="material-icons">phone_iphone</i><p>{realEstate.phone()}</p>
                                </div>
                                <div className="contact-item">
                                    <i className="material-icons">mail_outline</i>
                                    <p>{realEstate.email()}</p>
                                </div>
                                <div className="contact-item">
                                    <a className="link" href={realEstate.web()}>{realEstate.web()}</a>
                                </div>
                            </div>
                        </div>)
                    })}
            </section>
        )
    }

    renderContent() {
        if (this.state.loading) {
            return (
                <div className="site-content cards-content">
                    {this.renderFilters()}
                    <LoaderSection message="Espere unos segundos mientras recuperamos los datos"/>
                </div>
            );
        } else {
            return (
                <div className="site-content cards-content">
                    {this.renderFilters()}
                    {this.renderRealEstateList()}
                    <Paginator itemsQuantity={this.state.total}
                               itemsPerPage={this.props.resultsPerPage}
                               onSelect={this.changeItemsInPage}
                               activePage={this.state.activePage}
                               maxButtons={10}/>
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <MainNav/>
                <main className="sections">
                    {this.renderContent()}
                </main>
                <Footer/>
            </div>
        );
    }
}

Inmobiliarias.defaultProps = {
    resultsPerPage: 9
};

export default Inmobiliarias;
