import React from "react";
import {Modal} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CloseButton} from "../buttons/CloseButton";
import RequestStatus1 from '../../images/request_status_1.png';
import RequestStatus2 from '../../images/request_status_2.png';
import RequestStatus3 from '../../images/request_status_3.png';
import RequestStatus4 from '../../images/request_status_4.png';
import RequestStatus5 from '../../images/request_status_5.png';
import RequestStatus6 from '../../images/request_status_6.png';
import RequestStatus7 from '../../images/request_status_7.png';
import RequestStatus8 from '../../images/request_status_8.png';


export class RequestStatusModal extends React.Component {
    constructor(props) {
        super(props);

        this.sortedRequestStates = [
            'NUEVA', 'INCOMPLETA', 'ENANALISIS', 'APROBADA', 'PENDFIRMA', 'FIANZAFIRMADA', 'LOCACIONFIRMADA', 'OTORGADA'
        ];
    }

    comercialOfficeInformation() {
        return (
            <span>
                Oficina Comercial {this.props.requestOffice['name']}, al número {this.props.requestOffice['phone']}
            </span>
        );
    }

    stateDescription() {
        const descriptions = {
            'NUEVA':  (
                <div>
                    <p className="state-info-title">Solicitud iniciada</p>
                    <div className="assigned-office">
                        <p>
                            ¡Recibimos tu Solicitud de Garantía! A la brevedad será procesada.<br/>
                            ¿Ya la completaste? Te invitamos a revisar tu Solicitud en este panel.
                            Recordá que mientras más rápido esté completa y con la documentación cargada,
                            ¡más rápido te podremos brindar una resolución! Si tenés alguna duda, podés comunicarte
                            con un asesor para que te ayude.
                        </p>
                    </div>
                </div>
            ),
            'INCOMPLETA':  (
                <div>
                    <p className="state-info-title">Solicitud incompleta</p>
                    <div className="assigned-office">
                        <p>
                            A tu Solicitud de Garantía le faltan datos o documentación.<br/>
                            Te pedimos revisar tu Solicitud por completar los datos o cargar la documentacion
                            pendientes, de esta manera, podremos analizarla. Si necesitás más información,
                            llamanos y gustosamente te ayudaremos con tu proceso.
                        </p>
                    </div>
                </div>
            ),
            'ENANALISIS':  (
                <div>
                    <p className="state-info-title">Solicitud en análisis</p>
                    <div className="assigned-office">
                        <p>
                            Tu Solicitud se encuentra completa y en estado de En Análisis.<br/>
                            En un plazo máximo de 48 Hs. estaremos comunicándonos telefónicamente
                            para corroboración de la información y brindarte una resolución.
                        </p>
                    </div>
                </div>
            ),
            'APROBADA':  (
                <div>
                    <div className="assigned-office">
                        <p className="state-info-title">Solicitud aprobada</p>
                        <p>
                            ¡Felicitaciones! Tu Solicitud de Garantía se encuentra aprobada.<br/>
                            Te invitamos a comunicarte con nuestra&nbsp;
                            {this.comercialOfficeInformation()},<br/>
                            para continuar con tu gestión, verificar los valores de alquiler del inmueble y
                            que nos indiques si deseas realizar la firma electrónica para tu fianza, o si preferís
                            coordinar un turno para hacerlo de manera presencial.
                        </p>
                    </div>
                </div>
            ),
            'PENDFIRMA':  (
                <div>
                    <div className="assigned-office">
                        <p className="state-info-title">Pendiente de firma de fianza</p>
                        <p>
                            La firma de tu Fianza ha sido programada.<br/>
                            Si necesitás más información o modificar la cita de la firma, podés comunicarte con nuestra&nbsp;
                            {this.comercialOfficeInformation()}
                        </p>
                    </div>
                </div>
            ),
            'FIANZAFIRMADA':  (
                <div>
                    <div className="assigned-office">
                        <p className="state-info-title">Contrato de fianza firmado</p>
                        <p>
                            ¡Ya estás a un paso de lograr tu Alquiler!<br/>
                            Hemos registrado la firma de tu Garantía Finaer. El siguiente paso es la
                            Firma del Contrato de Locación, para lo cual es muy importante que te contactes
                            con tu inmobiliaria, para definir qué día firmarán el contrato de locación.<br/>
                            Si necesitás más información o requerís de nuestro apoyo, podés comunicarte con nuestra&nbsp;
                            {this.comercialOfficeInformation()}
                        </p>
                    </div>
                </div>
            ),
            'LOCACIONFIRMADA':  (
                <div>
                    <div className="assigned-office">
                        <p className="state-info-title">Contrato de locación firmado</p>
                        <p>
                            ¡Felicidades! ¡Ya firmaste tu contrato de locación. Esperamos que disfrutes de tu nuevo Alquiler!<br/>
                            Cualquier consulta, estamos para atenderte. ¡Recordá que tendrás beneficios especiales al momento
                            de renovar tu alquiler!
                        </p>
                    </div>
                </div>
            ),
            'OTORGADA':  (
                <div>
                    <div className="assigned-office">
                        <p className="state-info-title">Garantía otorgada</p>
                        <p>Tu Garantía Finaer fue otorgada. Queremos agradecer tu preferencia por nosotros.<br/>
                        Recordá que si al finalizar tu alquiler, deseas renovar, te ofreceremos un 15% de Descuento.</p>
                    </div>
                </div>
            ),
        };

        return descriptions[this.props.requestState]
    }

    defineClassFor(state) {
        const base = 'state';
        let extra = '';

        const currentState = this.props.requestState;
        if (state === currentState) {
            extra += ' -selected'
        }

        const stateIsLaterThanCurrent =
            this.sortedRequestStates.indexOf(state) >
            this.sortedRequestStates.indexOf(currentState);

        if (stateIsLaterThanCurrent) {
            extra += " -disable"
        }

        return base + extra;
    }

    render() {
        return (
            <Modal className="request-status-modal" header="Estado de solicitud:"
                       trigger={
                       <button className="state-btn">
                           Solicitud {this.props.requestState}
                           <FontAwesomeIcon className="icon" icon="eye"/>
                       </button>
                   }>
                <div className="modal-body">
                    <div className="states-container">
                        <div className={this.defineClassFor("NUEVA")}>
                            <img src={RequestStatus1} alt="request status icon"/>
                            <p className="icon-description">Solicitud iniciada</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("INCOMPLETA")}>
                            <img src={RequestStatus2} alt="request status icon"/>
                            <p className="icon-description">Solicitud incompleta</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("ENANALISIS")}>
                            <img src={RequestStatus3} alt="request status icon"/>
                            <p className="icon-description">Solicitud en análisis</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("APROBADA")}>
                            <img src={RequestStatus4} alt="request status icon"/>
                            <p className="icon-description">Solicitud aprobada</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("PENDFIRMA")}>
                            <img src={RequestStatus5} alt="request status icon"/>
                            <p className="icon-description">Pendiente de firma de fianza</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("FIANZAFIRMADA")}>
                            <img src={RequestStatus6} alt="request status icon"/>
                            <p className="icon-description">Contrato fianza firmado</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("LOCACIONFIRMADA")}>
                            <img src={RequestStatus7} alt="request status icon"/>
                            <p className="icon-description">Contrato de locación firmado</p>
                            <FontAwesomeIcon className="icon" icon="chevron-right"/>
                        </div>
                        <div className={this.defineClassFor("OTORGADA")}>
                            <img src={RequestStatus8} alt="request status icon"/>
                            <p className="icon-description">Garantía otorgada</p>
                        </div>
                    </div>
                    <div className="state-info -disable">
                        {this.stateDescription()}
                        <CloseButton/>
                    </div>
                </div>
            </Modal>
        );
    }
}

