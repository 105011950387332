import React from 'react';
import CircularProgressbar from "react-circular-progressbar";

export class FormNavItem extends React.Component {

    render() {
        return (
            <button className={'form-nav-item -' + this.props.active} onClick={this.props.onClick}>
                <p>{this.props.text}</p>
                <CircularProgressbar
                    className="section-progressbar"
                    percentage={this.props.percentage}
                    text={this.props.percentage + '%'}
                    strokeWidth="7"
                />
            </button>
        );
    }
}
