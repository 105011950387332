import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "react-router-dom/es/Link";

export class ItemButton extends React.Component {

    render() {
        return (
            <div className="home-link-item-container">
                <Link className="home-link-item" to={this.props.to} target={this.props.target}>
                    <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                    <p>{this.props.text}</p>
                </Link>
                <p>{this.props.description}</p>
            </div>
        );
    }
}
