

export class CompanyAgreement {
    constructor(id, alias, company, companyCuit) {
        this._id = id;
        this._alias = alias;
        this._company = company;
        this._companyCuit = companyCuit;
    }

    id() {
        return this._id;
    }

    alias() {
        return this._alias;
    }

    company() {
        return this._company;
    }

    companyCuit() {
        return this._companyCuit;
    }
}