export class RealEstate {
    constructor(id, name, address, phone, email, web, cities, provinces, logoUrl) {
        this._id = id;
        this._name = name;
        this._address = address;
        this._phone = phone;
        this._email = email;
        this._web = web;
        this._cities = cities;
        this._provinces = provinces;
        this._logoUrl = logoUrl;
    }

    id() {
        return this._id;
    }

    name() {
        return this._name;
    }

    address() {
        return this._address;
    }

    phone() {
        return this._phone;
    }

    email() {
        return this._email;
    }

    web() {
        return this._web;
    }

    logoUrl() {
        return this._logoUrl;
    }

    cities() {
        return this._cities;
    }

    provinces() {
        return this._provinces;
    }
}
