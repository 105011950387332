import React, {Component} from 'react';
import Link from "react-router-dom/Link";

class FailureMessageInvalidCoApplicant extends Component {
    render() {
        return (
            <div className="pre-request-result">
                <p className="secondary-title">¡Estamos a punto de terminar!</p>
                <p className="exclamation-text">{this.props.coApplicantName} no cumple con la Antigûedad laboral
                    requerida.<br/>
                    ¡Podemos intentar con otra persona y avanzar!</p>

                <button className="main-btn" onClick={this.props.handleRedirectToAddCoapplicant}>Agregar Nuevo Co-Solicitante</button>
                <br/>
                <p className="basic-text">
                    ¿Te falta información para ingresar otra persona?
                    <br/>
                    ¡No te preocupes!
                    <br/>
                    Te ofrecemos crear tu solicitud ahora y completarla en otro momento. ¡Es muy fácil!
                    <br/>
                    <button className="link-btn-pre-request" onClick={this.props.handleRedirectToWarrantyRequest}>
                        Avanzá tu Solicitud
                    </button>
                </p>


                <div className="contact">
                    <p>Si deseás más información, recordá que siempre podés comunicarte con la
                        <Link className="link" to="/sucursales" target="_blank">Oficina Finaer más cercana</Link>
                    </p>
                </div>
            </div>
        )
    }
}



export default FailureMessageInvalidCoApplicant;