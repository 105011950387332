import {range} from "../lib/others";
import {ProvinceManager, RealEstateManager} from "./managers";
import {months} from "../lib/months";


export class Field {
    constructor(name, required) {
        this._name = name;
        this._value = undefined;
        this._error = undefined;
        this._form = undefined;
        this._required = (required === undefined) ? true : required;
        this._relatedFields = [];
    }

    isRequired() {
        return this._required;
    }

    setForm(form) {
        this._form = form;
    }

    isRelatedTo(anotherField) {
        this._relatedFields.push(anotherField);
    }

    value() {
        return this._value;
    }

    error() {
        let hasError = this._error;
        return hasError ? this._error : '';
    }

    name() {
        return this._name;
    }

    setInitialValue(initialValue) {
        this.updateValue(initialValue);
    }

    updateValue(newValue) {
        this._value = newValue;
        return this;
    }

    resetErrors() {
        this._error = undefined;
    }

    shouldBeSaved() {
        return true;
    }

    isCompleted() {
        return this._value !== undefined && this._value !== '' && this._value !== null
    }

    addError(error) {
        this._error = error;
        return this;
    }

    markAsDirty() {
        this._form.setDirtyField(this);

        this._relatedFields.forEach((field) => {
            if(!this._form.isDirty(field)) {
                field.markAsDirty();
            }
        });
    }
}


export class DateField extends Field {
    static daysInMonthOfYear(month, year) {
        let lastDay;
        switch (month) {
            case "2":
                lastDay = (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28;
                break;
            case "4": case "6": case "9": case "11":
                lastDay = 30;
                break;
            default:
                lastDay = 31;
        }

        return range(1, lastDay);
    }

    static dayOptions(month, year) {
        if (month && year) {
            return DateField.daysInMonthOfYear(month, year);
        }

        return range(1, 31);
    }

    static monthOptions() {
        return months;
    }

    static yearOptions() {
        const actualYear = new Date().getFullYear();
        return range(actualYear-100, actualYear).reverse();
    }

    constructor(name) {
        super(name);
        this._day = undefined;
        this._month = undefined;
        this._year = undefined;
    }

    setInitialValue(initialValue) {
        if (initialValue) {
            this._value = initialValue;
            const date = new Date(initialValue);
            this._day = date.getUTCDate().toString();
            this._month = (date.getMonth() + 1).toString();
            this._year = date.getFullYear().toString();
        }
    }

    day() {
        return this._day;
    }

    month() {
        return this._month;
    }

    year() {
        return this._year;
    }

    updateValue(newValue) {
        const dateComponent = newValue.split("-")[0];
        this[dateComponent] = newValue.split("-")[1];

        this._value = this._year + '-' + this._month + '-' + this._day;
        return this;
    }

    shouldBeSaved() {
        return this.isCompleted();
    }

    isCompleted() {
        return this._day !== undefined && this._month !== undefined && this._year !== undefined;
    }
}


export class BirthdayDateField extends DateField {
    static yearOptions() {
        const currentYear = new Date().getFullYear();
        return range(currentYear-100, currentYear-18).reverse();
    }
}


export class DocumentField extends Field {
    constructor(name) {
        super(name);

        this._value = [];
    }

    setInitialValue(initialValue) {
        if (initialValue !== undefined) {
            this._value = initialValue.map(fileName => {
                return {file: {'name': fileName, 'file': undefined}, state: 'enviado'}
            });
        }
    }

    unSentFiles() {
        let unSentFiles = [];
        this._value.forEach(fileData => {
            if (fileData.state === "pendiente") unSentFiles.push(fileData.file);
        });

        return unSentFiles;
    }

    uploadedFiles() {
        return this._value;
    }

    updateValue(newFiles) {
        this._value.push.apply(this._value, newFiles);
        return this;
    }

    isCompleted() {
        return this._value.length > 0;
    }
}

export class AutoCompleteField extends Field {
    constructor(name, dataOptions) {
        super(name);

        this._data = dataOptions;
    }

    displayValue() {
        return this.value();
    }

    options() {
        return Object.values(this._data);
    }

    data() {
        return this._data;
    }

    empty() {
        return '';
    }

    valueFromOption(option) {
        return option;
    }

    updateValue(newValue) {
        if(!newValue) {
            this._value = this.empty();
            return
        }

        if (this.options().includes(newValue)) {
            this._value = this.valueFromOption(newValue);
        }
    }
}

export class CityAutoCompleteField extends AutoCompleteField {
    options(provinceField) {
        if (provinceField && provinceField.value() !== '' && provinceField.value() !== undefined) {
            const provinceName = provinceField.value();
            const province = ProvinceManager.getByCode(provinceField.data(), provinceName);
            return this.cityNames(province.cities());
        }

        return this.cityNames(this.data());
    }

    cityNames(cities) {
        return cities.map(city => {
            return city.name() + (this.hasARepeatedName(city, cities) ? ' - ' + city.province().name() : '')
        });
    }

    hasARepeatedName(city, cities){
        let i = 0;
        let quantity = 0;
        while(i < cities.length && quantity < 2){
            if(cities[i].name() === city.name()){
                quantity += 1;
            }
            i++;
        }
        return quantity >= 2
    }

}

export class ProvinceAutoCompleteField extends AutoCompleteField {
    value() {
        if(this._value) {
            return this._value.code()
        }

        return super.value()
    }

    displayValue() {
        if(this._value) {
            return this._value.name();
        }

        return super.displayValue()
    }

    options() {
        return this.provinceNames(this.data());
    }

    provinceNames(provinces) {
        return provinces.map(province => province.name());
    }

    valueFromOption(option) {
        return ProvinceManager.get(this._data, option);
    }

    setInitialValue(initialValue) {
        this._value = ProvinceManager.getByCode(this._data, initialValue);
    }
}


export class RealEstateAutoCompleteField extends AutoCompleteField {
    constructor(name, realEstate) {
        const dataOptions = RealEstateManager.attributeList(realEstate, '_name');
        super(name, dataOptions);

        this.realState = realEstate;
    }

    displayValue() {
        if(this._value) {
            return RealEstateManager.getById(this.realState, this._value).name();
        }

        return super.displayValue();
    }

    setInitialValue(initialValue) {
        this._value = initialValue;
    }

    valueFromOption(option) {
        return RealEstateManager.get(this.realState, option).id();
    }
}

