import React, {Component} from "react";
import "../../../styles/App.scss";
import {PersonalDataOne} from "../../../components/request-form/personal-data/PersonalDataOne";
import {PersonalDataTwo} from "../../../components/request-form/personal-data/PersonalDataTwo";
import {JobDataOne} from "../../../components/request-form/job-data/JobDataOne";
import {JobDataTwo} from "../../../components/request-form/job-data/JobDataTwo";
import {JobDataThree} from "../../../components/request-form/job-data/JobDataThree";
import {Wizard} from "../../../components/elements/Wizard.js";
import {PersonalDataThree} from "../personal-data/PersonalDataThree";
import {cuitFormat} from "../../../lib/others";


export class Applicant extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCuitInputChange = this.handleCuitInputChange.bind(this);
        this.handleAutoComplete = this.handleAutoComplete.bind(this);
        this.handleIncomeChange = this.handleIncomeChange.bind(this);
    }

    actualApplicantId() {
        return this.props.actualParticipantId;
    }

    handleInputChange(inputName) {
        return (event) => {
            const value = event.target.value;
            this.props.updateData(this.participantType(), this.actualApplicantId(), 'participantData', inputName, value);
            if (inputName === 'email'){
                this.props.onEmailChangeDo(value);
            }
        }
    }

    participantType() {
        return 'applicants';
    }

    handleCuitInputChange(inputName) {
        return (event) => {
            let cuit_input = cuitFormat(event.target.value);
            event.target.value = cuit_input;
            this.props.updateData(this.participantType(), this.actualApplicantId(), 'participantData', inputName, cuit_input);
        }
    }

    handleIncomeChange(inputName, value){
        this.props.updateData(this.participantType(), this.actualApplicantId(), 'participantData', inputName, value);
    }

    handleAutoComplete(inputName, value) {
        this.props.updateData(this.participantType(), this.actualApplicantId(), 'participantData', inputName, value);
    }

    renderContent() {
        const elements = [
            <PersonalDataOne key="1" handleInputChange={this.handleInputChange}
                             canEditId={false} personalDataChecked={this.props.personalDataChecked}
                             {...this.props}
                             confirmButtonIsShown={false}
                             isEmailConfirmed={false}
                             isEmailConfirmationSent={false}
            />,
            <PersonalDataTwo key="3" handleInputChange={this.handleInputChange}
                             handleAutoComplete={this.handleAutoComplete}
                             personalDataChecked={this.props.personalDataChecked} {...this.props}/>,
            <PersonalDataThree key="4" handleInputChange={this.handleInputChange}
                               personalDataChecked={this.props.personalDataChecked} {...this.props}/>,
            <JobDataOne key="5" handleInputChange={this.handleInputChange}
                        handleCuitInputChange={this.handleCuitInputChange}
                        jobDataChecked={this.props.jobDataChecked} {...this.props}/>,
            <JobDataTwo key="6" handleInputChange={this.handleInputChange}
                        handleAutoComplete={this.handleAutoComplete}
                        jobDataChecked={this.props.jobDataChecked} {...this.props}/>,
            <JobDataThree key="7" handleInputChange={this.handleInputChange}
                          handleCuitInputChange={this.handleCuitInputChange}
                          handleIncomeChange={this.handleIncomeChange}
                          jobDataChecked={this.props.jobDataChecked}{...this.props}/>
        ];

        if (this.props.isMobile) {
            return elements;
        } else {
            return (
                <Wizard>
                    {elements}
                </Wizard>
            )
        }
    }

    render() {
        return (
            <div className="form-content">
                {this.renderContent()}
            </div>
        )
    }
}

