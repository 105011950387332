import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input, Row} from "react-materialize";
import {HelperModal} from "../components/modals/HelperModal";
import {FinaerSelect} from "../components/elements/Select";
import {app} from "../app/app";


export class CalculatorWidget extends Component {
    /*
    * Tenemos cosas duplicadas con CalculatorControl. Refactorizar CalculatorControl para que se pueda adaptar a esta
    * pagina. Dejo pendiente el refactor hasta definir si este widget se utiliza en algun lado para ver si vale la pena
    * invertir tiempo, En principio solo lo usaba xintel (relacion comercial terminada). Falta definir si Mercado Libre
    * lo va a seguir usando.
    *
    * Tiene cosas obsoletas como la duracion del alquiler que permite seleccionar 24 meses.
    * */
    _CASH_DISCOUNT = 15;

    constructor(props) {
        super(props);
        this.state = {
            alreadyMadeACalculation: false,
            formData: {
                averageRentalValue: {},
                averageExpensesValue: {},
                contractPeriod: {
                    value: '36',
                }
            },
            rentalType: {
                value: 'Vivienda',
            },
            warrantyType: {
                value: 'nueva',
            },
            nonFieldErrors: [],
            feesQuantity: 6,
            amountInAdvance: 0,
            feesAmount: undefined,
            feesQuantityOptions: [
                {value: 1, label: 'Contado'},
                {value: 2, label: '2 cuotas (Anticipo + 1 cuota)'},
                {value: 3, label: '3 cuotas (Anticipo + 2 cuotas)'},
                {value: 4, label: '4 cuotas (Anticipo + 3 cuotas)'},
                {value: 5, label: '5 cuotas (Anticipo + 4 cuotas)'},
                {value: 6, label: '6 cuotas (Anticipo + 5 cuotas)'},
            ],
            valid: false
        };

        this.calculateCosts = this.calculateCosts.bind(this);
        this.rentalData = this.rentalData.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.textForHelper = this.textForHelper.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFeesQuantityChange = this.handleFeesQuantityChange.bind(this);
    }

    render() {
        return (
            <section className="section-box" id="calculadora">
                <header>
                    <FontAwesomeIcon className="icon" icon="calculator"/>
                    <h1>Calculadora de Costos</h1>
                </header>
                <form>
                    <p>Calculá el costo de tu Garantía Finaer completando todos los campos</p>
                    <Row>
                        <Input m={6} s={12} label="Valor promedio de tu alquiler"
                               maxlength={8} pattern="[0-9]*" type={'number'}
                               onChange={this.handleInputChange('averageRentalValue')}
                               error={this.hasError('averageRentalValue')}>
                            <HelperModal
                                title="Valor promedio de tu alquiler"
                                text={this.textForHelper()}/>
                        </Input>
                        <Input m={6} s={12} label="Valor de las expensas actuales"
                               maxlength={8} pattern="[0-9]*" type={'number'}
                               onChange={this.handleInputChange('averageExpensesValue')}
                               error={this.hasError('averageExpensesValue')}/>
                    </Row>
                    <Row className="content-radio-buttons">
                        <div className="col -text">
                            <p>Duración de tu alquiler</p>
                        </div>
                        <div className="radio-button-option">
                            <input type="radio" name="period" value={'24'}
                                   checked={this.state.formData.contractPeriod.value === '24'}
                                   onChange={this.handleInputChange('contractPeriod')}/> <p>24 Meses</p>
                        </div>
                        <div className="radio-button-option">
                            <input type="radio" name="period" value={'36'}
                                   checked={this.state.formData.contractPeriod.value === '36'}
                                   onChange={this.handleInputChange('contractPeriod')}/> <p>36 Meses</p>
                        </div>
                    </Row>
                    <Row className="paids-information">
                        <div className="combined-input col s12 m6">
                            <div className="react-select">
                                <p className="combined-input-label">Costo de la Garantía Finaer</p>
                                <label className="-add-padding">Forma de pago</label>
                                <FinaerSelect
                                    placeholder={'Seleccione una opción'}
                                    selectedOption={this.state.feesQuantity}
                                    onChange={this.handleFeesQuantityChange}
                                    options={this.state.feesQuantityOptions}/>
                            </div>
                        </div>
                        {this.renderSubmitButton()}
                    </Row>
                    <Row className="warning-text">
                        <p className="warning">Importante</p>
                        <p>Los valores son calculados según los montos ingresados.<br/>
                            El costo final corresponderá al mismo cálculo en base a los valores<br/>
                            definitivos del alquiler, según figuren en el contrato de locación.</p>
                    </Row>
                </form>
            </section>
        )
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.value, error: this.isValid(input)};
            this.setState({formData: formData, valid: form.checkValidity()})
        }
    }

    handleWarrantyTypeChange() {
        let warrantyType = this.state.warrantyType;
        return (event) => {
            let input = event.target;
            let form = input.form;
            warrantyType = {value: input.value, error: this.isValid(input)};
            this.setState({warrantyType: warrantyType, valid: form.checkValidity()})
        }
    }

    handleRentalTypeChange() {
        let rentalType = this.state.rentalType;
        return (event) => {
            let input = event.target;
            let form = input.form;
            rentalType = {value: input.value, error: this.isValid(input)};
            this.setState({rentalType: rentalType, valid: form.checkValidity()})
        }
    }

    handleFeesQuantityChange(event) {
        const newValue = event.target.value;
        this.setState({feesQuantity: newValue});
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for (let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    rentalData() {
        let formData = this.state.formData;
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {});
    }

    calculateCosts(event) {
        event.preventDefault();
        this.clearFormErrors();
        app.apiClient().calculateRentalCosts(this.rentalData(), this.handleApiResponse);
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.setState({
                feesAmount: response.feeAmount(),
                amountInAdvance: response.amountInAdvance(),
                alreadyMadeACalculation: true
            });
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({formData: formData, nonFieldErrors: response.nonFieldErrors()})
        }
    }

    amountPerMonth() {
        const feesQuantity = this.state.feesQuantity - 1;
        return Math.round(this.state.feesAmount / feesQuantity).toLocaleString();
    }

    renderSubmitButton() {
        if (this.state.alreadyMadeACalculation) {
            return (
                <div className="information-paids-box col m6 col s12">
                    {this.renderAdvancePayment()}
                    {this.renderFees()}
                    {this.renderTotal()}
                    <button className="main-btn" onClick={this.calculateCosts}>
                        Recalcular costo
                    </button>
                </div>
            )
        } else {
            return (
                <div className="submit-content col m6 col s12">
                    <button className="main-btn" onClick={this.calculateCosts}>
                        Calcular costo
                    </button>
                </div>
            )
        }
    }

    renderFees() {
        if (this.state.feesQuantity === 1) {
            return this.renderCashPayment()
        }

        return this.renderFeesPayment();
    }

    renderTotal() {
        return <div className="total-paid">
            <p>Total costo por servicio:</p>
            <p>${this.totalAmount().toLocaleString()}</p>
        </div>
    }

    renderCashPayment() {
        return <React.Fragment>
            <div className="amount-paids">
                <div className="paid-number">
                    <p>Descuento</p>
                </div>
                <p className="total">-${this.cashDiscount().toLocaleString()}</p>
            </div>
            <div className="amount-paids">
                <div className="paid-number">
                    <p className="highlight-numbers">{this.state.feesQuantity}</p>
                    <p>pago (con 15% de descuento)</p>
                </div>
                <p className="highlight-numbers total">${this.totalAmountForCashPayment().toLocaleString()}</p>
            </div>
        </React.Fragment>
    }

    renderFeesPayment() {
        return <div className="amount-paids">
            <div className="paid-number">
                <p className="highlight-numbers">{this.state.feesQuantity - 1}</p>
                <p>cuotas de</p>
            </div>
            <p className="highlight-numbers total">${this.amountPerMonth()}</p>
        </div>;
    }

    renderAdvancePayment() {
        if (this.state.feesQuantity > 1) {
            return (<div className="advance-payment-container">
                <p className="advance-payment-text">Anticipo</p>
                <p className="advance-payment-amount">${this.advancePayment()}</p>
            </div>);
        }
    }

    renderHelper(hasCommercialType) {
        if (hasCommercialType) {
            return (<HelperModal
                title="Valor promedio de tu alquiler"
                text={this.textForHelper()}/>);
        }
    }

    renderRentalValue() {
        let hasCommercialType = this.state.rentalType.value === 'Comercial';
        let rentalLabel = hasCommercialType ? 'Valor promedio de tu alquiler' : 'Ingresá el valor del primer mes de alquiler';
        return (
            <Input m={6} s={12} label={rentalLabel}
                   maxlength={8} pattern="[0-9]*"
                   onChange={this.handleInputChange('averageRentalValue')}
                   error={this.hasError('averageRentalValue')}>
                {this.renderHelper(hasCommercialType)}
            </Input>
        )
    }

    advancePayment() {
        return Math.round(this.state.amountInAdvance).toLocaleString();
    }

    totalAmount() {
        return Math.round(this.state.feesAmount + this.state.amountInAdvance)
    }

    totalAmountForCashPayment() {
        let total = this.totalAmount();
        return total - this.cashDiscountFor(total)
    }

    cashDiscount() {
        let total = this.totalAmount();
        return this.cashDiscountFor(total)
    }

    cashDiscountFor(amount) {
        return amount * this._CASH_DISCOUNT / 100
    }

    textForHelper() {
        return (
            <div className="calculator-helper-text">
                <p>
                    ¡Es muy fácil! Deberás conocer los <span>valores e incrementos de cada
                    período dentro del tiempo de tu alquiler</span> (ejemplo: incrementos semestrales,
                    anuales, etc).
                </p>
                <ul>
                    <p>Por ejemplo, tu alquiler tiene 6 incrementos:</p>

                    <li>
                        <span>Primer semestre:</span> Pagás $10.000 de Alquiler
                    </li>
                    <li>
                        <span>Segundo semestre:</span> Pagás $11.500 de Alquiler
                    </li>
                    <li>
                        <span>Tercer semestre:</span> Pagás $13.225 de Alquiler
                    </li>
                    <li>
                        <span>Cuarto semestre:</span> Pagás $15.208 de Alquiler
                    </li>
                    <li>
                        <span>Quinto semestre:</span> Pagás $16.500 de Alquiler
                    </li>
                    <li>
                        <span>Sexto semestre:</span> Pagás $18.200 de Alquiler
                    </li>
                    <li>
                        <span>Sumá los montos y dividí el resultado entre la cantidad de períodos:</span>
                        [(10.000+11.500+13.225+15.208+16.500+18.200)/6] = Promedio = $14.106
                    </li>
                </ul>
                <p className="warning-text">
                    <span>Nota:</span> Si desconocés los valores o los incrementos, podés indicar un monto
                    estimativo, recordá que el valor final dependerá de los valores reales
                    plasmados en el contrato de locación."
                </p>
            </div>
        )
    }
}