import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {FinaerSelect} from "../../elements/Select";
import {CheckedInput} from "../../elements/CheckedInput";
import {ControlledCheck} from "../../elements/ControlledCheck";


export class JobDataOne extends Component {
    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Datos laborales</h2>
                    <ControlledCheck checked={this.props.jobDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row>
                        <div className={this.props.isMobile ? "col s12" : "col s6"}>
                            <div className="react-select -padding-adjustment">
                                <label className="active -add-padding">Ocupación</label>
                                <FinaerSelect
                                    selectedOption={this.props.participantData['employeeType'].value() || ""}
                                    onChange={this.props.handleInputChange('employeeType')}
                                    placeholder="Seleccione su ocupación"
                                    options={[
                                        {label: 'Empleado', value: 'EMPLEADO'},
                                        {label: 'Monotributista', value: 'MONOTRIBUTISTA'},
                                        {label: 'Responsable inscripto', value: 'RESPONSABLE INSCRIPTO/AUTONOMO'},
                                        {label: 'Otro', value: 'OTRO'},
                                    ]}
                                    className={this.props.jobDataChecked ? "-disable-select -padding-adjustment" : "-padding-adjustment"}
                                    checked={this.props.jobDataChecked}/>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className={this.props.isMobile ? "combined-input col s12" : "combined-input col s6"}>
                            <p className="combined-input-label">Datos relacionados con la Empresa</p>
                            <CheckedInput s={12} type="text" label="CUIT/CUIL"
                                          maxLength={13}
                                          defaultValue={this.props.participantData['currentCompanyCuit'].value()}
                                          onChange={this.props.handleCuitInputChange('currentCompanyCuit')}
                                          error={this.props.participantData['currentCompanyCuit'].error()}
                                          checked={this.props.jobDataChecked}/>
                        </div>
                        <CheckedInput s={this.props.isMobile ? 12 : 6} label="Razón Social" maxLength={100}
                                      defaultValue={this.props.participantData['currentCompany'].value()}
                                      onChange={this.props.handleInputChange('currentCompany')}
                                      error={this.props.participantData['currentCompany'].error()}
                                      checked={this.props.jobDataChecked}/>
                    </Row>
                </div>
            </div>
        );
    }
}
