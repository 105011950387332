import React from "react";
import SelectPaymentTypeStep from "./step-two/SelectPaymentTypeStep";
import SelectPaymentCashStepThree from "./step-three/SelectPaymentCashStepThree";
import SelectPaymentTransferStepThree from "./step-three/SelectPaymentTransferStepThree";
import SelectPaymentTransferEndingStepFour from "./step-four/SelectPaymentTransferEndingStepFour";
import SelectAmountOfInstallmentsStep from "./step-three/SelectAmountOfInstallmentsStep";

const SELECT_PAYMENT_METHOD_STEP_NUMBER=0
const SELECT_INSTALLMENTS_STEP_NUMBER=1
const CONFIRM_PAYMENT_STEP_NUMBER=2
const FINAL_MESSAGE_STEP_NUMBER=3

export default class TransferOrCashPayment extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            actualStep: SELECT_PAYMENT_METHOD_STEP_NUMBER,
            amountOfInstallments: 0,
        }

        this.handleAmountOfInstallmentsSelected = this.handleAmountOfInstallmentsSelected.bind(this);
    }

    jumpToStep(stepNumber){
        this.setState({actualStep: stepNumber});
    }

    // Create payment

    creatPaymentLead() {
        let onResponse = (response) => this._handleCreationOfPaymentLeadResponse(response);
        this.props.creatPaymentLead(onResponse);
    }

    _handleCreationOfPaymentLeadResponse(response) {
        if (response.hasErrors()) {
            return this.handleRedirectDashboard();
        }
        if (this.props.isACashPayment) {
            this.props.handleRedirectDashboard();
        } else {
            this.jumpToStep(FINAL_MESSAGE_STEP_NUMBER);
        }
    }

    //  Handle Payment type selected
    handleOneInstallmentPaymentSelection() {
        this.setState({
                actualStep: CONFIRM_PAYMENT_STEP_NUMBER,
                amountOfInstallments: 1},
            () => {this.props.settlePaymentFor(1);})
    }

    // Handle amount of installments selected

    handleAmountOfInstallmentsSelected(amountOfInstallments) {
        let newInfo = {
            amountOfInstallments: amountOfInstallments,
            actualStep: CONFIRM_PAYMENT_STEP_NUMBER
        };
        this.setState(newInfo, () => {this.props.settlePaymentFor(amountOfInstallments);});
    }

    _selectAmountOfInstallments() {
        return <SelectAmountOfInstallmentsStep
            numberOfInstallmentsAllowed={this.props.installmentsAllowed}
            warrantyInformation={this.props.warrantyInformation}
            handlePrevious={() => {this.jumpToStep(SELECT_PAYMENT_METHOD_STEP_NUMBER)}}
            handleAmountOfInstallmentsSelected={this.handleAmountOfInstallmentsSelected}/>
    }

    renderCashPaymentContent() {
        if (this.state.actualStep === CONFIRM_PAYMENT_STEP_NUMBER) {
            return <SelectPaymentCashStepThree
                loading={this.props.loading}
                handlePrevious={() => {this.jumpToStep(SELECT_PAYMENT_METHOD_STEP_NUMBER)}}
                createPaymentLead={() => {this.creatPaymentLead()}}
                hasOnePayment={this.state.amountOfInstallments === 1}
                paymentInfo={this.props.paymentInfo}
                warrantyInformation={this.props.warrantyInformation}/>
        }
    }

    renderTransferPaymentContent() {
        if (this.state.actualStep === CONFIRM_PAYMENT_STEP_NUMBER) {
            return <SelectPaymentTransferStepThree
                loading={this.props.loading}
                handlePrevious={() => {this.jumpToStep(SELECT_PAYMENT_METHOD_STEP_NUMBER)}}
                createPaymentLead={() => {this.creatPaymentLead()}}
                hasOnePayment={this.state.amountOfInstallments === 1}
                paymentInfo={this.props.paymentInfo}
                warrantyInformation={this.props.warrantyInformation}/>
        }
        if (this.state.actualStep === FINAL_MESSAGE_STEP_NUMBER) {
            return <SelectPaymentTransferEndingStepFour
                loading={this.props.loading}
                paymentInfo={this.props.paymentInfo}
                warrantyInformation={this.props.warrantyInformation}
                hasOnePayment={this.state.amountOfInstallments === 1}
                handleNext={this.props.handleRedirectDashboard}/>
        }
    }

    _selectPaymentType() {
        return <SelectPaymentTypeStep
            handlePrevious={this.props.handlePrevious}
            installmentsAllowed={this.props.installmentsAllowed}
            onOnePaymentSelectedDo={() => {
                this.handleOneInstallmentPaymentSelection()
            }}
            onInstallmentsPaymentSelectedDo={() => {
                this.jumpToStep(SELECT_INSTALLMENTS_STEP_NUMBER);
            }}/>
    }

    renderPaymentMethodSelectedContents() {
        if (this.props.isACashPayment) {
            return this.renderCashPaymentContent();
        }
        return this.renderTransferPaymentContent();
    }

    render() {
        if (this.state.actualStep === SELECT_PAYMENT_METHOD_STEP_NUMBER) {
            return this._selectPaymentType();
        }
        if (this.state.actualStep === SELECT_INSTALLMENTS_STEP_NUMBER) {
            return this._selectAmountOfInstallments();
        } else {
            return this.renderPaymentMethodSelectedContents();
        }
    }

}