import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class RecordItem extends React.Component {

    render() {
        return (
            <div className="record-item">
                <div className="code">
                    <p className="label">Código de solicitud:</p>
                    <p className="data">{this.props.code}</p>
                </div>
                <div className="state">
                    <p className="label">Estado:</p>
                    <p className="data">{this.props.state}</p>
                </div>
                <div className="address">
                    <p className="label">Dirección del inmueble</p>
                    <p className="data">{this.props.address}</p>
                </div>
                { /* <p className="hover-data">Ver más <FontAwesomeIcon className="icon" icon="eye" /></p> */ }
            </div>
        );
    }
}
