import React, {Component} from 'react';
import MainNav from '../components/layout/MainNav';
import {Footer} from "../components/layout/Footer";


class Privacy extends Component {
    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="privacidad">
                            <header>
                                <h1 className="highlight">Política de Privacidad</h1>
                            </header>
                            <p className="basic-text non-copy-text">
                                La presente Política de Privacidad obra en línea con la Ley de Protección de Datos Personales Nº 25.326 y su decreto
                                reglamentario Nº 1558/2001 (“LPDP”) y normativa complementaria emitida por la autoridad de aplicación de la Ley de Protección de
                                Datos Personales, actualmente la Agencia de Acceso a la Información Pública (“AAIP”).
                            </p>
                            <p className="basic-text non-copy-text">
                                El sitio web https://finaersa.com.ar (“Sitio Web”) es propiedad “SISTEMA FINAER S.A.” (CUIT: 30-71528749-4), con domicilio legal en
                                Avenida Corrientes 3360, Piso 6°, Oficina 601, C.A.B.A., Código Postal: 1193 (“FINAER”).
                            </p>
                            <p className="basic-text non-copy-text">
                                Algunos de los términos utilizados con mayúscula que no se encuentren definidos en la presente Política de Privacidad se encuentran definidos
                                en los Términos y Condiciones de FINAER que podrá acceder aquí: [LINK a TyC]
                            </p>

                            <p className="basic-text non-copy-text">
                                A modo de que FINAER pueda prestar sus Servicios, se informa que será necesario acceder, recopilar, compartir ciertos datos e información personal
                                de cada Solicitante que adquiera los Servicios, la cual deberá ser suministrada voluntariamente por el Solicitante, consintiendo el uso de la misma
                                en concordancia con la presente Política de Privacidad.
                            </p>

                            <p className="basic-text non-copy-text">
                                En caso de que Usted no desee que FINAER utilice su información y/o datos personales deberá abstenerse de utilizar el Sitio Web, contratar los Servicios de FINAER
                                y en efecto abstenerse de brindar la información solicitada para el alta de los Servicios.
                            </p>

                            <p className="basic-text non-copy-text">
                                Si Usted utiliza y accede al Sitio Web y solicita los Servicios de FINAER significa que ha leído, entendido y consiente los Términos y Condiciones
                                de FINAER y la presente Política de Privacidad. En caso de que no se encontrare de acuerdo con estos, tiene la opción de no proporcionar ninguna
                                información personal. No obstante, la negativa a proporcionar su información personal impedirá que FINAER pueda ofrecerle y/o prestarle los Servicios.
                            </p>

                            <h2 className="non-copy-text">Tipo de información personal</h2>

                            <p className="basic-text non-copy-text">
                                La información y datos personales que podrán ser requeridos por FINAER en función de los Servicios, son los que se describen a continuación: nombre;
                                apellido; Tipo y N° de DNI; genero; fecha de nacimiento; domicilio particular- Calle, N°; Piso; Departamento; Localidad/Ciudad; Provincia- número de teléfono
                                fijo/celular y alternativos; dirección de correo electrónico y alternativo; ocupación, antigüedad laboral; ingreso neto; Datos del empleador y CUIT.
                            </p>

                            <p className="basic-text non-copy-text">
                                Usted deberá contar con el consentimiento de cualquier tercero cuyos datos personales suministre a FINAER en el marco de los Servicios. Asimismo, F
                                INAER podrá obtener otros datos personales suyos a través de fuentes irrestrictas de información o a través de terceros autorizados a proveer
                                dicha información.
                            </p>

                            <h2 className="non-copy-text">Uso de la información personal</h2>

                            <p className="basic-text non-copy-text">Los datos personales se recogen para los siguientes fines:</p>

                            <ul className="basic-list">
                                <li className="doc-list-item non-copy-text">
                                    Procesar, administrar, implementar y efectuar las solicitudes de los Servicios, así como para operar los Servicios en toda su extensión
                                    ya sea durante la vigencia de los mismos, su pago, sus modificaciones, bajas etc.;
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    Crear y/o diseñar nuevos o mejorar los servicios existentes de FINAER;
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    A modo de poder comunicarnos con usted con respecto a los Servicios, los Términos y Condiciones de FINAER, la presente Política de Privacidad
                                    y/o cualquier otro documento que sea considerado importante hacer de su conocimiento;
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    Para que usted pueda acceder a distintas promociones y/u ofertas de FINAER  y/o de sus socios comerciales, así como la oferta de publicidad selectiva o contenidos de interés.
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    Para proporcionar otros servicios relacionados con FINAER y/o sus socios comerciales, así como para realizar acciones de marketing y publicidad;
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    Para análisis, investigación estadística o similar;
                                </li>
                                <li className="doc-list-item non-copy-text">
                                    Otros fines que pudieran guardar relación con los descriptos anteriormente.
                                </li>
                            </ul>

                            <p className="basic-text non-copy-text">
                                Asimismo, FINAER tiene la intención de utilizar su nombre y sus datos de contacto con fines promocionales o de comercialización, incluido el envío
                                de materiales promocionales y la realización de marketing directo en relación con los siguientes productos, servicios, asesoramiento y temas:
                                seguros; educativo; reclutamiento; formación; eventos; medios de comunicación; redes sociales; noticias; acontecimientos institucionales;
                                actualidad y repercusiones sobre hechos inherentes a FINAER; novedades sobre responsabilidad social corporativa de FINAER; promoción de ayuda
                                social y políticas de solidaridad.
                            </p>

                            <p className="basic-text non-copy-text">
                                Por su parte FINAER podrá recopilar o recibir información personal de los Solicitantes a través de sus socios comerciales y agentes inmobiliarios.
                            </p>

                            <p className="basic-text non-copy-text">
                                Toda su información Personal será almacenada en una base de datos a nombre de FINAER y será tratada con los niveles de seguridad requeridos por la LPDP y
                                normativa complementaria a modo de evitar pérdidas, accesos no autorizados, alteraciones y mal usos, aunque usted asume que las medidas de seguridad no son infalibles y
                                que podrían existir casos en los que se generen accesos no autorizados, perdida de información, alteraciones y/o mal usos de su información personal. Sin embargo, para
                                estos casos FINAER realizará en la medida de sus posibilidades las acciones que crean conducentes para mitigar los riesgos indicados.
                            </p>

                            <p className="basic-text non-copy-text">
                                Se informa que FINAER podrá utilizar, conservar y compartir la información personal por usted brindada en función de los Servicios, a empresas, afiliadas y/o
                                vinculadas contractualmente, las cuales podrán encontrarse locadas en países sin legislación adecuada en materia de protección de datos personales, pero que aun así
                                cumplen con los estándares de protección, seguridad y derechos en materia de Protección de Datos Personales conforme la normativa aplicable Argentina y la LPDP.
                            </p>

                            <h2 className="non-copy-text">Derechos de los titulares de los datos</h2>

                            <p className="basic-text non-copy-text">
                                Se informa que Usted en su carácter de titular de sus datos personales tendrá la posibilidad en todo momento de ejercer los derechos de acceso, rectificación y supresión
                                de los datos conforme lo establece la LPDP y normativa complementaria.
                            </p>

                            <p className="basic-text non-copy-text">
                                Particularmente usted tiene derecho a: verificar si FINAER posee datos personales suyos en relación a los Servicios y acceder a dichos datos; exigir que se corrija cualquier
                                información personal relacionada con usted en virtud de los Servicios que sea inexacta; y conocer las políticas y prácticas de FINAER en relación con la protección de datos
                                personales.
                            </p>

                            <p className="basic-text non-copy-text">
                                Las solicitudes de acceso, corrección u otras consultas relacionadas con los Servicios deben dirigirse a: legales@finaersa.com.ar
                            </p>

                            <p className="basic-text non-copy-text">
                                FINAER deberá proporcionarle la información solicitada dentro de los 10 días desde la solicitud. Usted también tiene el derecho de solicitar la rectificación, actualización
                                y supresión de sus datos personales. FINAER  tomará las medidas pertinentes para responder a su solicitud en relación a estos derechos en un máximo de 5 días hábiles, desde
                                la recepción del reclamo del titular de los datos.
                            </p>

                            <p className="basic-text non-copy-text">
                                Se informa que la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos
                                que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
                            </p>

                            <h2 className="non-copy-text">Uso de Cookies</h2>

                            <p className="basic-text non-copy-text">
                                Las cookies son identificadores únicos colocados en su computadora u otro dispositivo por un servidor web, que contiene información que luego puede ser leída por el
                                servidor que le envió la cookie. En tal sentido, FINAER puede almacenar cookies en su computador. La información recopilada (incluidas, entre otras, sus direcciones
                                IP (y nombres de dominio), el software del navegador, los tipos y configuraciones de su navegador, la configuración de idioma, las ubicaciones geográficas, los
                                sistemas operativos, el sitio web de referencia, las páginas y los contenidos vistos y la duración de visita) se utilizará para compilar estadísticas agregadas sobre cómo
                                nuestros visitantes llegan y navegan por nuestro Sitios Web y comprender su experiencia en el Sitio Web.
                            </p>

                            <p className="basic-text non-copy-text">
                                Las cookies se utilizan para la optimización del Sitios Web. Las cookies también permiten que el Sitio Web lo recuerde a usted y sus preferencias, y adapte el Sitio Web
                                a sus necesidades. Las cookies publicitarias permiten proporcionar anuncios en nuestros Sitios Web que sean lo más relevantes posible para usted.
                            </p>

                            <p className="basic-text non-copy-text">
                                La mayoría de los navegadores web están configurados inicialmente para aceptar cookies. Si no desea recibir cookies, puede desactivar esta función en la configuración de su
                                navegador. Sin embargo, al hacerlo, es posible que no pueda disfrutar plenamente de los beneficios del Sitio Web y que algunas funciones no funcionen correctamente
                            </p>

                            <h2 className="non-copy-text">Modificaciones a la Política de Privacidad</h2>

                            <p className="basic-text non-copy-text">
                                FINAER se reserva el derecho a realizar de forma unilateral modificaciones, totales o parciales, a la presente Política de Privacidad, por lo cual podremos requerirle que
                                nos preste su expreso consentimiento a las nuevas disposiciones.
                            </p>

                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default Privacy;