import React from 'react';
import {Input, Modal, Row} from 'react-materialize'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CloseButton} from "../../components/buttons/CloseButton";
import {app} from "../../app/app";
import {containsSubstringLike} from "../../lib/others";
import {ProvinceManager} from "../../models/managers";
import {FinaerAutocomplete} from "../elements/Autocomplete";
import {FinaerSelect} from "../elements/Select";
import NumberFormat from "react-number-format";

export class ReclaimModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            formData: {
                firstName: {},
                lastName: {},
                idNumber: {},
                phoneAreaCode: {},
                phoneNumber: {},
                celAreaCode: {},
                celNumber: {},
                email: {},
                email2: {},
                renterFirstName: {},
                renterLastName: {},
                renterIdNumber: {},
                addressStreet: {},
                addressNumber: {},
                addressFloor: {},
                addressApartment: {},
                province: {},
                city: {},
                signedOnDay: {},
                signedOnMonth: {},
                signedOnYear: {},
                applicationNumber: {},
                observations: {},
            },
            nonFieldErrors: [],
            valid: false,
            citySelectOptions: [],
            provinceSelectOptions: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.hasError = this.hasError.bind(this);
        this.onLoadProvincesAndCities = this.onLoadProvincesAndCities.bind(this);
        this.handleProvinceAutoComplete = this.handleProvinceAutoComplete.bind(this);
        this.handleCityAutoComplete = this.handleCityAutoComplete.bind(this);
        this.lockdowmReclaimForm = this.lockdowmReclaimForm.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleLockdownReclaimChange = this.handleLockdownReclaimChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    onCloseModal() {
        this.setState({submitted: false});
    }

    componentDidMount() {
        app.apiClient().getProvincesAndCities(this.onLoadProvincesAndCities);
    }

    onLoadProvincesAndCities(dataLoaded) {
        let provinces = dataLoaded.provinces();
        let cities = dataLoaded.cities();

        this.setState({
            provinces: provinces,
            cities: cities,
            citySelectOptions: this.names(cities),
            provinceSelectOptions: this.names(provinces),
        });
    }

    names(provincesOrCities) {
        return provincesOrCities.map((item) => item.name());
    }

    handleProvinceAutoComplete(provinceNameOrNull) {
        let formData = this.state.formData;
        formData.province = {value: provinceNameOrNull};

        let citySelectOptions = this.names(this.state.cities);
        if(provinceNameOrNull) {
            const province = ProvinceManager.get(this.state.provinces, provinceNameOrNull);
            citySelectOptions = this.names(province.cities());
        }


        this.setState({formData: formData, citySelectOptions: citySelectOptions});
    }

    handleCityAutoComplete(cityOrNull) {
        let formData = this.state.formData;
        formData.city = {value: cityOrNull};

        this.setState({formData: formData});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clearFormErrors();
        app.apiClient().newReclaim(this.reclaimData(), this.handleApiResponse);
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.setState({submitted: true});
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({formData: formData, nonFieldErrors: response.nonFieldErrors()})
        }
    }

    reclaimData() {
        let formData = this.state.formData;
        let reclaimData = Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {});

        if(reclaimData.signedOnDay && reclaimData.signedOnMonth && reclaimData.signedOnYear) {
            reclaimData.signedOn = reclaimData.signedOnYear + '-' + reclaimData.signedOnMonth + '-' + reclaimData.signedOnDay;
        }

        return reclaimData
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.value, error: this.isValid(input)};
            this.setState({formData: formData, valid: form.checkValidity()})
        }
    }

    handleLockdownReclaimChange() {
        this.changeValue('lockdownReclaim', !this.state.formData.lockdownReclaim.value);
    }

    handleSelectChange(inputName) {
        return (event) => {
            this.changeValue(inputName, event.target.value);
        }
    }

    changeValue(inputName, value) {
        let formData = this.state.formData;
        formData[inputName] = {value: value};
        this.setState({formData: formData})
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for(let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    render() {
        return (
            <Modal header='Formulario de Incumplimiento'
                   trigger={
                       <button className={this.props.class || "main-nav-item menu-item-logout"}>
                           <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                           <p>{this.props.button || 'Informar Incumplimiento'}</p>
                       </button>
                   }
                   modalOptions={{complete: this.onCloseModal}}>
                <div className="modal-body">
                    <div className="modal-main">
                        {this.modalContent()}
                        <CloseButton/>
                    </div>
                </div>
            </Modal>)
    }

    successMessage() {
        return (
            <p>Su reclamo ha sido registrado. Será contactado a la brevedad.</p>
        )
    }

    modalContent() {
        if (this.state.submitted) {
            return this.successMessage();
        } else {
            return this.form();
        }
    }

    lockdowmReclaimForm() {
        if (this.state.formData.lockdownReclaim.value) {
            return (
                <div>
                    <Row>
                        <div className="col m6 s12 react-select input-field">
                            <FinaerSelect
                                placeholder="Período de alquiler"
                                onChange={this.handleSelectChange('rentPeriod')}
                                error={this.hasError('rentPeriod')}
                                selectedOption={this.state.formData.rentPeriod.value}
                                options={[
                                    {label: 'Abril 2020', value: '04/2020'},
                                    {label: 'Mayo 2020', value: '05/2020'},
                                    {label: 'Junio 2020', value: '06/2020'},
                                    {label: 'Julio 2020', value: '07/2020'},
                                    {label: 'Agosto 2020', value: '08/2020'},
                                    {label: 'Septiembre 2020', value: '09/2020'},
                                    {label: 'Octubre 2020', value: '10/2020'},
                                    {label: 'Noviembre 2020', value: '11/2020'},
                                    {label: 'Diciembre 2020', value: '12/2020'},
                                    {label: 'Enero 2021', value: '01/2021'},
                                ]}
                            />
                        </div>
                        <NumberFormat customInput={Input} m={6} s={12} label="Monto de alquiler correspondiente"
                                      thousandSeparator={'.'} decimalSeparator={','} prefix={'$'}
                                      onValueChange={(value) => this.changeValue('rentAmount', value.value)}
                                      error={this.hasError('rentAmount')}
                                      value={this.state.formData.rentAmount.value}/>
                    </Row>
                    <Row>
                        <NumberFormat customInput={Input} m={4} s={12} label="Monto adeudado"
                                      thousandSeparator={'.'} decimalSeparator={','} prefix={'$'}
                                      error={this.hasError('debtAmount')}
                                      onValueChange={(value) => this.changeValue('debtAmount', value.value)}
                                      value={this.state.formData.debtAmount.value}/>
                        <div className="col m4 s12 react-select input-field">
                            <FinaerSelect
                                placeholder="Tipo de reclamo"
                                selectedOption={this.state.formData.debtType.value}
                                onChange={this.handleSelectChange('debtType')}
                                error={this.hasError('debtType')}
                                options={[
                                    {label: 'Congelamiento', value: 'CONGELAMIENTO'},
                                    {label: 'Pago parcial', value: 'PAGO_PARCIAL'},
                                    {label: 'Sin registro de pago', value: 'SIN_REGISTRO_DE_PAGO'},
                                ]}
                            />
                        </div>
                        <div className="col m4 s12 react-select input-field">
                            <FinaerSelect
                                placeholder="Cuotas pactadas"
                                selectedOption={this.state.formData.feesCount.value}
                                onChange={this.handleSelectChange('feesCount')}
                                error={this.hasError('feesCount')}
                                options={[
                                    {label: '1 cuota', value: 1},
                                    {label: '3 cuotas', value: 3},
                                    {label: '6 cuotas', value: 6},
                                ]}
                            />
                        </div>
                    </Row>
                </div>
            )
        }

        return null;
    }

    form() {
        return (
            <div>
                <form>
                    <p className="form-subtitle">Datos del propietario</p>
                    <Row>
                        <Input m={4} s={12} onChange={this.handleInputChange('lastName')} label="Apellido"
                               error={this.hasError('lastName')}/>
                        <Input m={4} s={12} onChange={this.handleInputChange('firstName')} label="Nombre"
                               error={this.hasError('firstName')}/>
                        <Input m={4} s={12} onChange={this.handleInputChange('idNumber')} label="DNI"
                               error={this.hasError('idNumber')}/>
                    </Row>
                    <Row>
                        <div className="combined-input col m6 s12">
                            <p className="combined-input-label">Teléfono particular</p>
                            <Input s={4} onChange={this.handleInputChange('phoneAreaCode')} label="Cód. de área"
                                   error={this.hasError('phoneAreaCode')}/>
                            <Input s={8} onChange={this.handleInputChange('phoneNumber')} label="Nro. particular"
                                   error={this.hasError('phoneNumber')}/>
                        </div>
                        <div className="combined-input col m6 s12">
                            <p className="combined-input-label">Teléfono celular</p>
                            <Input s={4} onChange={this.handleInputChange('celAreaCode')} label="Cód. de área"
                                   error={this.hasError('celAreaCode')}/>
                            <Input s={8} onChange={this.handleInputChange('celNumber')} label="Nro. de celular"
                                   error={this.hasError('celNumber')}/>
                        </div>
                    </Row>
                    <Row>
                        <Input m={6} s={12} onChange={this.handleInputChange('email')} label="Email"
                               error={this.hasError('email')}/>
                        <Input m={6} s={12} onChange={this.handleInputChange('email2')}
                               label="Confirmar Email" error={this.hasError('email2')}/>
                    </Row>
                    <p className="form-subtitle">Datos del inquilino</p>
                    <Row>
                        <Input m={4} s={12} onChange={this.handleInputChange('renterLastName')} label="Apellido"
                               error={this.hasError('renterLastName')}/>
                        <Input m={4} s={12} onChange={this.handleInputChange('renterFirstName')} label="Nombre"
                               error={this.hasError('renterFirstName')}/>
                        <Input m={4} s={12} onChange={this.handleInputChange('renterIdNumber')} label="DNI"
                               error={this.hasError('renterIdNumber')}/>
                    </Row>
                    <p className="form-subtitle">Datos de la propiedad</p>
                    <Row>
                        <Input m={6} s={12} onChange={this.handleInputChange('addressStreet')} label="Calle"
                               error={this.hasError('addressStreet')}/>
                        <Input m={2} s={4} onChange={this.handleInputChange('addressNumber')} label="Nro."
                               error={this.hasError('addressNumber')}/>
                        <Input m={2} s={4} onChange={this.handleInputChange('addressFloor')} label="Piso"
                               error={this.hasError('addressFloor')}/>
                        <Input m={2} s={4} onChange={this.handleInputChange('addressApartment')} label="Depto."
                               error={this.hasError('addressApartment')}/>
                    </Row>
                    <Row className="-overlap-fix-above">
                        <div className={"col m6 s12 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Provincia'}
                                itemToString={item => (item ? item : '')}
                                items={this.state.provinceSelectOptions}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={this.handleProvinceAutoComplete}
                                error={this.hasError('province')}
                            />
                        </div>
                        <div className={"col m6 s12 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Ciudad / Localidad'}
                                itemToString={item => (item ? item : '')}
                                items={this.state.citySelectOptions}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={this.handleCityAutoComplete}
                                error={this.hasError('city')}
                            />
                        </div>
                    </Row>
                    <Row>

                        <Input m={12} s={12} maxLength={12} onChange={this.handleInputChange('applicationNumber')}
                               label="Código del Contrato de Fianza" error={this.hasError('applicationNumber')}/>

                        <Input m={12} s={12}
                               placeholder="Ingrese sugerencias, detalle de períodos y valores reclamados"
                               onChange={(event) => this.changeValue('observations', event.target.value)}
                               error={this.hasError('observations')}
                               maxLength={500}
                               value={this.state.formData.observations.value}
                               type={'textarea'}/>
                    </Row>
                    <Row className="submit-row">
                        <button className="main-btn" onClick={this.handleSubmit}
                                disabled={!this.state.valid}>Enviar
                        </button>
                    </Row>
                </form>
            </div>
        )
    }
}
