

export const objectFromArray = function (array, defaultValue) {
    let result = {};
    for (let element of array) {
        result[element] = defaultValue;
    }

    return result;
};

export const range = function (start, end) {
    let array = [...Array(end + 1).keys()];
    array.splice(0, start);
    return array;
};


export const swapKeysAndValues = function(aDict) {
    let swappedDict = {};

    for(let key in aDict) {
        let value = aDict[key];
        swappedDict[value] = key;
    }

    return swappedDict;
};

export const cuitFormat = function (cuit_input){
    cuit_input = cuit_input.toString();
    let masked_cuit = [];

    for(let i=0; i < cuit_input.length; i++){
        if(cuit_input[i] !== '-' && (i === 2 || i === 11)){
            masked_cuit[i] = '-';
            masked_cuit[i+1] = cuit_input[i];
            i++;
        }else{
            masked_cuit[i] = cuit_input[i];
        }
    }

    return masked_cuit.join('');
};


export const trimmedString = function(aString, length) {
    return aString.substring(0, length) + " . . ."
};


export const withoutAccentMarks = function(aString) {
      return aString.replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
};


export const containsSubstringLike = function(substring, aString) {
    return withoutAccentMarks(aString.toLowerCase()).includes(withoutAccentMarks(substring.toLowerCase()));
};

export const containsSubstring = function(list, substring) {
    return list.some(item => {
        return containsSubstringLike(substring, item);
    });
};