import {getSetting} from '../settings/settings.js';
import {FakeApi, RemoteApi} from "../api/api.js";
import {ApiClient} from "../api/apiClient.js";
import {User} from "../models/User";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faFileAlt} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faFileAlt);

export class App {
    constructor() {
        this.client = undefined;
        this._user = undefined;
    }

    setUpApi() {
        const remoteApiUrl = getSetting('API_URL');
        const remoteApiUrlV2 = getSetting('API_URL_V2');
        if (remoteApiUrl) {
            return new RemoteApi(remoteApiUrl, remoteApiUrlV2);
        } else {
            return new FakeApi();
        }
    }

    setUpApiClient() {
        const api = this.setUpApi();
        this.client = new ApiClient(api);
    }

    apiClient() {
        if (this.client === undefined) {
            this.setUpApiClient();
        }

        return this.client;
    }

    userDataIsInSession() {
        const isWarrantySaved= localStorage.getItem('warrantyApplicationNumber') !== null;
        const isTokenSaved= localStorage.getItem('token') !== null;
        return isTokenSaved && isWarrantySaved;
    }

    userIsLoggedIn() {
        return this.loggedInUser() !== undefined;
    }

    loginUser(idType, idNumber, warrantyApplicationNumber, successCallback, errorCallback) {
        let self = this;
        let credentials = {docType: idType, docNumber: idNumber, requestCode: warrantyApplicationNumber};

        this.apiClient().login(credentials, (response) => {
            if (response.hasErrors()) {
                errorCallback(response);
            } else {
                self.newUser(warrantyApplicationNumber, response.token());
                self._storeUserDataInSession(warrantyApplicationNumber, response.token())
                successCallback();
            }
        });
    }

    newUser(warrantyApplicationNumber, token) {
        this._user = new User(warrantyApplicationNumber);
        this._user.setAuthToken(token);
    }

    _storeUserDataInSession(warrantyApplicationNumber, token){
        localStorage.setItem('token', token);
        localStorage.setItem('warrantyApplicationNumber', warrantyApplicationNumber);
    }

    _getUserDataInSession(){
        return {
            'warrantyApplicationNumber': localStorage.getItem('warrantyApplicationNumber'),
            'token': localStorage.getItem('token')
        }
    }

    wppUrl() {
        return "https://api.whatsapp.com/send?phone=541168425100"
    }

    logOutUser() {
        this._user.removeAuthToken();
        this._user = undefined;
        localStorage.clear();
    }

    loggedInUser() {
        if (this._user === undefined && this.userDataIsInSession()){
            const userData = this._getUserDataInSession()
            this.newUser(userData.warrantyApplicationNumber, userData.token);
        }
        return this._user;
    }
}

export let app = new App();