import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class DashboardMainNavButton extends React.Component {
    render() {
        return (
            <button className={"main-btn select-payment-method-btn" + this.props.class}
                    onClick={this.props.onClick}>
                <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                <p>{this.props.text}</p>
            </button>
        );
    }
}