import TagManager from 'react-gtm-module'
import {getSetting} from "../settings/settings";



export function initGoogleTagManager() {
    const gtmId = getSetting('GTM_ID');
    if (gtmId) {
        googleTagManagerAPI = new GoogleTagManagerAPI(gtmId);
    }
}

export function registerGoogleTagManagerEvent(attributes) {
    googleTagManagerAPI.registerEvent(attributes);
}


class GoogleTagManagerAPI {
    constructor(gtmId) {
        TagManager.initialize({
            gtmId: gtmId
        });
    }

    registerEvent(attributes) {
        window.dataLayer.push(attributes);
    }
}


class FakeGoogleTagManagerAPI {
    registerEvent(attributes) {
        console.log(attributes);
    }
}


var googleTagManagerAPI = new FakeGoogleTagManagerAPI();
