

export class City {
    constructor(id, name, province) {
        this._id = id;
        this._name = name;
        this._province = province;

        this._province.addCity(this);
    }

    id() {
        return this._id;
    }

    name() {
        return this._name;
    }

    province() {
        return this._province;
    }
}