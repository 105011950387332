import React from "react";
import LoadCreditCardForm from "./LoadCreditCardForm";

export default class LoadCreditCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.formData
        }
        this.completeCreditNumber = this.completeCreditNumber.bind(this);
        this.completeCardHolderName = this.completeCardHolderName.bind(this);
        this.completeSecurityCode = this.completeSecurityCode.bind(this);
        this.completeExpirationDateFor = this.completeExpirationDateFor.bind(this);
    }

    // Methods for validation

    errorMessage = (inputName) => {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    updateFormData(formData) {
        this.setState({formData: formData },
            () => {
                this.props.updateFormData(this.state.formData);
        });
    }

    updateFieldValue = (fieldName, newValue, error) => {
        let formData = this.state.formData;
        formData[fieldName] = {value: newValue, error: error};
        this.updateFormData(formData);
    }

    completeSecurityCode(securityCode) {
        this._updateSecurityCodeErrors(securityCode);
    }

    _updateSecurityCodeErrors(securityCode= undefined){
        securityCode = securityCode ? securityCode : this.state.formData.securityCode.value;
        let error = this.props.paymentMethod.isSecurityCodeValid(securityCode) ? '' : 'Inválida';
        this.updateFieldValue('securityCode', securityCode, error);
    }

    async completeCreditNumber(newCardNumber) {
        let cardNumber = newCardNumber.replace(/\D/g,'');
        let onPaymentLoaded = (cardNumber, paymentMethod) => {
            let cardNumberError = paymentMethod.isCardNumberValid(cardNumber) ? '' : 'Inválida';
            this._updateSecurityCodeErrors();
            this.updateFieldValue('creditCardNumber', cardNumber, cardNumberError);
        }
        await this.props.guessPaymentMethod(cardNumber, onPaymentLoaded);
    }

    completeCardHolderName(cardHolderName) {
        let error = cardHolderName.length !== 0 ? '' : 'Inválida';
        this.updateFieldValue('cardholderName', cardHolderName, error)
    }

    _validateExpirationDateFor(month, year) {
        let date = new Date();
        let actualYear = parseInt(date.getFullYear().toString().substring(2, 4));
        if (actualYear > year || month < 0 || month > 12) {
            return 'Inválida';
        }
        if (date.getMonth() > month && actualYear === year) {
            return 'Inválida';
        }
        return '';
    }

    completeExpirationDateFor(newDate) {
        let formData = this.state.formData;
        let expirationDate = newDate.split("/")
        let month = expirationDate.length > 0 ? expirationDate[0] : '';
        let year = expirationDate.length > 1 ? expirationDate[1] : '';
        let error = this._validateExpirationDateFor(parseInt(month), parseInt(year));
        formData.expirationDate = {month: month, year: year, error: error};
        this.updateFormData(formData);
    }

    render() {
        return (
            <LoadCreditCardForm
                form={this.state.formData}
                paymentMethod={this.props.paymentMethod}
                completeExpirationDateFor={this.completeExpirationDateFor}
                completeCreditNumber={this.completeCreditNumber}
                completeSecurityCode={this.completeSecurityCode}
                completeCardHolderName={this.completeCardHolderName}
                handleNIDChange={this.handleNIDChange}
                errorMessage={this.errorMessage}
            />
        )
    }
}