import React, {Component} from "react";
import Downshift from "downshift";


export class FinaerAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.filter = this.filter.bind(this);
        this.optionString = this.optionString.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.disabled = this.disabled.bind(this);
        this.selectedItem = this.selectedItem.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    filter(item, inputValue) {
        let filterFunction = this.props.filterFunction || this.defaultFilterFunction();
        return filterFunction(item, inputValue);
    }

    defaultFilterFunction() {
        return (item, inputValue) => !inputValue || item.toLowerCase().includes(inputValue.toLowerCase())
    }

    onSelect() {
        return this.props.onSelect || this.defaultOnSelectCallback()
    }

    disabled(){
        return this.props.disabled
    }

    defaultOnSelectCallback() {
        return selectedItem => {};
    }

    optionString(item) {
        return this.props.optionString ? this.props.optionString(item) : this.props.itemToString(item);
    }

    handleStateChange() {
        return this.props.handleStateChange || ((changes) => {})
    }

    render() {
        return (
            <div>
                {this.renderLabel()}
                <Downshift
                    selectedItem={this.selectedItem()}
                    onStateChange={this.handleStateChange()}
                    onChange={this.onSelect()}
                    itemToString={this.props.itemToString}>
                    {({
                          getInputProps,
                          getItemProps,
                          getLabelProps,
                          getMenuProps,
                          isOpen,
                          inputValue,
                          highlightedIndex,
                          selectedItem,
                          clearSelection
                      }) => (
                        <div className="new-autocomplete">
                            {this.renderInput(getInputProps, clearSelection)}
                            {this.renderOptionMenu(isOpen, getMenuProps, inputValue, getItemProps)}
                        </div>
                    )}
                </Downshift>
            </div>
        )
    }

    selectedItem() {
        return this.props.selectedItem;
    }

    renderLabel() {
        if(this.props.selectedItem) {
            return (<label className="active">{this.props.label}</label>)
        }

        return null;
    }

    renderOptionMenu(isOpen, getMenuProps, inputValue, getItemProps) {
        const filteredItems = this.props.items.filter(item => this.props.filterFunction(item, inputValue));
        const menuIsOpen = isOpen && filteredItems.length > 0;

        return menuIsOpen ? this.optionMenu(getMenuProps, filteredItems, getItemProps) : null;
    }

    optionMenu(getMenuProps, filteredItems, getItemProps) {
        return (
            <ul className="autocomplete-list" {...getMenuProps()}>
                {filteredItems.map((item, index) => (
                    <li
                        {...getItemProps({
                            key: index,
                            index,
                            item,
                            className: 'autocomplete-item',
                        })}>
                        {this.optionString(item)}
                    </li>
                ))}
            </ul>);
    }

    isChecked(){
        return this.props.checked;
    }

    renderInput(getInputProps, clearSelection) {
        return <input
            disabled={this.disabled() || this.isChecked()}
            {...getInputProps({
            placeholder: this.props.label,
            className: this.isValidClass(),
            onChange: e => {
                if (e.target.value === '') {
                    clearSelection()
                }
            },
        })} />;
    }

    isValidClass() {
        return this.props.error ? 'invalid' : ''
    }
}