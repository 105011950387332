import React from "react";
import "../../styles/App.scss";


export default class CreateWarrantyLeadWizardStep extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false,
            leadFields: props.leadFields,
            hasValidFields: this._hasRequiredFields(props.leadFields),
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.nextButtonPressed = this.nextButtonPressed.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
        this.handleLedResponse = this.handleLedResponse.bind(this);
    }

    // Validating
    _hasRequiredFields(warrantyLead) {
        return this._requiredFields().every(field => warrantyLead[field].value !== undefined)
    }

    _requiredFields() {
        throw Error("subclass Responsibility")
    }

    _areValidLeadFields(leadFields) {
        throw Error("subclass Responsibility")
    }

    // State
    updateLeadFieldsStateWith(leadFields) {
        this.setState({leadFields: leadFields, hasValidFields: this._areValidLeadFields(leadFields)})
    }

    // Event handling
    handleInputChange(inputName) {
        let leadFields = this.state.leadFields;

        return (event) => {
            leadFields[inputName] = {value: event.target.value};
            this.updateLeadFieldsStateWith(leadFields);
        }
    }

    nextButtonPressed(event) {
        event.preventDefault();
    }

    handleEnterPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();

            if(this.state.hasValidFields) {
                this.nextButtonPressed(event);
            }
        }
    }

    editLead() {
        this.props.editLead(this.state.leadFields, this.handleLedResponse);
    }

    // Response management
    handleLedResponse(response) {
        response.manageIn(this);
        this.setState({processing: false});
    }

    manageSuccessfulLeadResponse(response){
        this.props.onFinishDo(response.lead())
    }

    manageLeadFieldErrorResponse(response){
        this.updateFieldErrorsFrom(response.fieldErrors());
    }

    // Fields management
    updateFieldErrorsFrom(fieldErrors) {
        let leadFields = {...this.state.leadFields};
        Object.entries(fieldErrors).forEach(([fieldName, errorMessage]) => {
            leadFields[fieldName].error = errorMessage;
        });
        this.updateLeadFieldsStateWith(leadFields)
    }

    // Errors
    hasErrorsIn(warrantyLead) {
        return Object.keys(warrantyLead).some( eachField => warrantyLead[eachField].error );
    }

    errorMessageFor(inputName) {
        let inputHasError = this.state.leadFields[inputName].error;
        return inputHasError ? this.state.leadFields[inputName].error : '';
    }

    setError(warrantyLead, inputName, errorMessage) {
        warrantyLead[inputName].error = errorMessage;
    }

    resetError(warrantyLead, inputName) {
        warrantyLead[inputName].error = '';
    }

    // Rendering
    nextButtonShouldBeDisabled() {
        return !this.state.hasValidFields || this.state.processing;
    }
}
