import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {FinaerAutocomplete} from "../../elements/Autocomplete";
import {containsSubstringLike} from "../../../lib/others";


export class PropertyDataThree extends Component {
    render() {
        return (
            <div key={1}>
                <h2 className="form-header">Inmobiliaria</h2>
                <div className="form-inputs">
                    <Row>
                        <div className={"col input-field s12 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Inmobiliaria'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.property['realState'].options()}
                                selectedItem={this.props.property['realState'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={(realState) => this.props.handleAutoComplete({'realState': realState})}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

