import React, {Component} from "react";
import {FinaerAutocomplete} from "./Autocomplete";


export class FinaerTypeAhead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: props.selectedItem || null
        };

        this.handleStateChange = this.handleStateChange.bind(this);
    }

    handleStateChange(changes) {
        const callback = this.onChangeCallback();
        if (changes.hasOwnProperty('selectedItem')) {
            this.setState({selectedItem: changes.selectedItem}, () => callback(this.state.selectedItem))
        } else if (changes.hasOwnProperty('inputValue')) {
            this.setState({selectedItem: changes.inputValue}, () => callback(this.state.selectedItem))
        }
    }

    onChangeCallback() {
        return this.props.onChange || this.defaultOnChangeCallback()
    }

    defaultOnChangeCallback() {
        return (value) => {};
    }

    render() {
        return (
            <FinaerAutocomplete
                itemToString={this.props.itemToString}
                items={this.props.items}
                selectedItem={this.state.selectedItem}
                filterFunction={this.props.filterFunction}
                onSelect={this.props.onSelect}
                handleStateChange={this.handleStateChange}
            />
        )
    }
}