import React from "react";
import {Pagination} from "react-materialize";

export class Paginator extends React.Component {
    _calculateNumberOfPages() {
        const numberOfPages = Math.ceil(this.props.itemsQuantity / this.props.itemsPerPage);
        return numberOfPages > 0 ? numberOfPages : 1;
    }

    render() {
        const numberOfPages = this._calculateNumberOfPages();
        return <Pagination items={numberOfPages}
                           activePage={this.props.activePage}
                           onSelect={this.props.onSelect}
                           maxButtons={10}/>
    }
}