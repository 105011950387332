import React from "react";
import {slide as Menu} from "react-burger-menu";
import Link from "react-router-dom/es/Link";
import LoginModal from "../../components/modals/LoginModal";
import {app} from "../../app/app";
import {ReclaimModal} from "../modals/ReclaimModal";

export class SidebarMenu extends React.Component {
    renderLogin() {
        if (app.userIsLoggedIn()) {
            return <Link className="menu-item" to="/dashboard">Seguí tu Solicitud</Link>;
        } else {
            return <LoginModal/>;
        }
    }

    renderReclaimModal() {
        return <ReclaimModal/>
    }

    reclaimAndSuggestionsGoogleForm() {
        return "https://docs.google.com/forms/d/e/1FAIpQLSedFYtDVkdhYC3moa3P-4_sH2TMxE-qDnZLsLAGj4hYcmXvDQ/viewform"
    }

    render() {
        return (
            <Menu {...this.props}>
                {this.renderLogin()}
                <Link className="menu-item" to="/inicio">Solicita tu Garantía</Link>
                <Link className="menu-item" to="/calculadora">Calcular costos</Link>
                <Link className="menu-item" to="/requisitos">Requisitos</Link>
                <Link className="menu-item" to="/garantia">Nuestra Garantía</Link>
                <Link className="menu-item" to="/seguros-complementarios">Seguros Complementarios</Link>
                <Link className="menu-item" to="/sucursales">Oficinas Finaer</Link>
                <Link className="menu-item" to="/informacion#">Propietarios e Inmobiliarias</Link>
                {this.renderReclaimModal()}
                <a className="menu-item" href={this.reclaimAndSuggestionsGoogleForm()} target="_blank"
                   rel="noopener noreferrer">Sugerencias & Reclamos</a>
                <Link className="menu-item" to="/inmobiliarias">Buscador de Inmobiliarias</Link>
            </Menu>
        );
    }
}
