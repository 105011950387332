import PaymentBase from "../PaymentBase";
import {LeftButton} from "../../buttons/LeftButton";
import React from "react";
import {RedirectToMercadoPagoButton} from "../../buttons/RedirectToMercadoPagoButton";
import {app} from "../../../app/app";

export default class SelectPaymentMercadoPagoWallet extends PaymentBase {

    constructor(props) {
        super(props);
        this.state = {
            preferenceId : undefined,
            waitingForAnswer: false
        }
    }

    title(){
        if (this.isPaymentConfirmed()) {
            return <h1>Has confirmado pagar con tu billetera de Mercadopago</h1>
        }
        return <h1>Paga con tu billetera de Mercadopago</h1>
    }

    isPaymentConfirmed() {
        return this.state.preferenceId !== undefined;
    }

    body() {
        if (this.isPaymentConfirmed()) {
            return this.renderRedirectToWalletPanel();
        }
        return this.renderConfirmationPanel();
    }

    _marginNotes() {
        return (
            <div className="installments-bar">
                <p className="payment-information-text">
                    Abonando con tu billetera cancelás el total de la Garantía Finaer.
                    Para continuar, presiona <span className='span-precio'>CONFIRMAR</span>
                </p>
                <p className="interest-rates-message">
                    La billetera de Mercado Pago no contempla el pago en cuotas.
                    Para abonar en 12 cuotas, retrocede y selecciona la opción MERCADO PAGO.
                </p>
            </div>
        );
    }

    renderConfirmationPanel() {
        return (
            <div className="middle-bar-content payment-bar">
                {this._amountToPayMercadoPagoSection()}
                {this._marginNotes()}
                {this._promotionsFooter()}
            </div>
        );
    }

    renderRedirectToWalletPanel() {
        return (
            <div className="middle-bar-content payment-bar installments-bar">
                {this._amountToPayMercadoPagoSection()}
                <p className="payment-information-text">
                    Abonando con tu billetera cancelás el total de la Garantía Finaer.
                </p>
            </div>
        );
    }

    _createMercadoPagoReference = () => {
        let onResponse = (response) => {
            this.setState({
                "preferenceId": response.preferenceId(),
                "waitingForAnswer": false
            })
        }
        let warrantyNumber = app.loggedInUser().warrantyApplicationNumber();
        this.setState({waitingForAnswer: true}, () => {
            let amountToPay = this.getMercadoPagoFee()
            app.apiClient().getMercadoPagoPreference(warrantyNumber, amountToPay, onResponse);
        })
    }

    _confirmBtn() {
        return this._confirmPaymentBtn(this._createMercadoPagoReference);
    }

    _renderButton() {
        if (this.isPaymentConfirmed()) {
            return <RedirectToMercadoPagoButton preferenceId={this.state.preferenceId}/>
        } else if (this.state.waitingForAnswer) {
            return <p>Espere un momento</p>
        }
        return this._confirmBtn()
    }

    _renderLeftButton() {
        if (!this.state.paying) {
            return <LeftButton onClick={this.props.handlePrevious}/>
        }
    }

    footer() {
        return (
            <div className="arrows-payment mercadopago-wallet-options">
                {this._renderLeftButton()}
                {this._renderButton()}
            </div>
        );
    }
}