import React from 'react';
import '../../styles/App.scss';
import {Button, Input, Row} from "react-materialize";
import {LeftButton} from "../../components/buttons/LeftButton";
import {FinaerSelect} from "../elements/Select";
import {idDocuments} from "../../lib/idDocuments";
import IdNumberValidator from "../../models/IdNumberValidator";
import CreateWarrantyLeadWizardStep from "./CreateWarrantyLeadWizardStep";


export default class CreateWarrantyLeadWizardStepTwo extends CreateWarrantyLeadWizardStep {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleIdNumberChange = this.handleIdNumberChange.bind(this);
        this.handleIdTypeChange = this.handleIdTypeChange.bind(this);

        this._form = React.createRef();
    }

    // Validating
    _requiredFields(application) {
        return ['idType', 'idNumber', 'phoneAreaCode', 'phoneNumber'];
    }

    _areValidLeadFields(leadFields) {
        return this.form().checkValidity() && !this.hasErrorsIn(leadFields);
    }

    // Errors
    _errorMessageIfIdNumberIsInvalid(idNumber, leadFields) {
        const idType = leadFields.idType.value;
        let validator = IdNumberValidator.NewFor(idType,idNumber);
        return validator.validate();
    }

    // Convenience
    form() {
        return this._form.current;
    }

    _idNumberFieldWith(newIdNumber, leadFields) {
        return {value: newIdNumber, error: this._errorMessageIfIdNumberIsInvalid(newIdNumber, leadFields)};
    }

    // Event handling
    handleIdTypeChange(event) {
        let leadFields = {...this.state.leadFields};
        leadFields['idType'] = {value: event.target.value};
        let idNumber = leadFields.idNumber.value;
        leadFields['idNumber'] = this._idNumberFieldWith(idNumber, leadFields);
        this.updateLeadFieldsStateWith(leadFields);
    }

    handleIdNumberChange(event) {
        let leadFields = {...this.state.leadFields};
        let newIdNumber = event.target.value;
        leadFields['idNumber'] = this._idNumberFieldWith(newIdNumber, leadFields);
        this.updateLeadFieldsStateWith(leadFields);
    }

    nextButtonPressed(event) {
        super.nextButtonPressed(event);
        this.setState({processing: true});
        this.editLead();
    }

    // Rendering
    render() {
        return (
            <main>
                <h1>Solicitud de Garantía</h1>
                <p className="bajada">Completar los siguientes datos personales es importante para poder comenzar con la
                    solicitud</p>
                <form ref={this._form}>
                    <div className="middle-bar">
                        <div className="middle-bar-content">
                            <Row>
                                <div className="combined-input col s12">
                                    <p className="combined-input-label">Documento</p>
                                    <div className="col input-field m4 s12">
                                        <div className="react-select -padding-adjustment">
                                            <FinaerSelect
                                                selectedOption={this.state.leadFields.idType.value}
                                                onChange={this.handleIdTypeChange}
                                                placeholder="Tipo"
                                                required={true}
                                                options={Object.values(idDocuments)}/>
                                        </div>
                                    </div>
                                    <Input m={8} s={12} onChange={this.handleIdNumberChange} label="Nro. de Documento"
                                           value={this.state.leadFields.idNumber.value} required={true} maxLength={10}
                                           error={this.errorMessageFor('idNumber')} />
                                </div>
                            </Row>
                            <Row>
                                <div className="combined-input col m12 s12">
                                    <p className="combined-input-label">Teléfono Celular</p>
                                    <Input m={4} s={12} onChange={this.handleInputChange('phoneAreaCode')}
                                           onKeyPress={this.handleEnterPress}
                                           value={this.state.leadFields.phoneAreaCode.value } required={true}
                                           label="Cód. de área" maxLength={6} pattern="[0-9]*" validate/>
                                    <Input m={8} s={12} onChange={this.handleInputChange('phoneNumber')}
                                           onKeyPress={this.handleEnterPress}
                                           value={this.state.leadFields.phoneNumber.value} required={true}
                                           label="Nro. de Celular" maxLength={10} pattern="[0-9]*"
                                           validate/>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <div className="button-group">
                        <LeftButton onClick={this.props.handlePrevious}/>
                        <Button className="round-btn"
                                type="submit"
                                onClick={this.nextButtonPressed}
                                disabled={this.nextButtonShouldBeDisabled()}>
                            {this.props.hasPrequalification ? 'Confirmar' : 'Avanzar para terminar'}
                        </Button>
                    </div>
                </form>
            </main>
        );
    }
}
