import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HelperModal} from "../modals/HelperModal";

export class ControlledCheck extends React.Component {

    render() {
        if (this.props.checked) {
            return (<div className="spinner-checked">
                <p>Controlado</p>
                <div className={"checked-icons"}>
                    <FontAwesomeIcon className="icon" icon="check"/>
                    <HelperModal
                        title="Control"
                        text={'Ya controlamos los datos ingresados y son correctos. No es necesario que los modifiques'}/>
                </div>
            </div>);
        }
        return (null);
    }
}