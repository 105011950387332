import React, {Component} from "react";
import {Wizard} from "../../elements/Wizard";
import {PropertyDataOne} from "../property-data/PropertyDataOne";
import {PropertyDataTwo} from "../property-data/PropertyDataTwo";
import {PropertyDataThree} from "../property-data/PropertyDataThree";


export class Property extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAutoComplete = this.handleAutoComplete.bind(this);
    }

    handleInputChange(inputName) {
        return (event) => {
            const value = event.target.value;
            this.props.updateData({[inputName]: value});
        }
    }

    handleAutoComplete(fieldsToUpdate) {
        this.props.updateData(fieldsToUpdate);
    }

    renderContent() {
        const elements = [
            <PropertyDataOne key="1" handleInputChange={this.handleInputChange}
                             handleAutoComplete={this.handleAutoComplete} {...this.props}/>,
            <PropertyDataTwo key="2" handleInputChange={this.handleInputChange} {...this.props}/>,
            <PropertyDataThree key="3" handleInputChange={this.handleInputChange}
                               handleAutoComplete={this.handleAutoComplete} {...this.props}/>,
        ];

        if (this.props.isMobile) {
            return elements;
        } else {
            return (
                <Wizard>
                    {elements}
                </Wizard>
            )
        }
    }

    render() {
        return (
            <div className="form-content">
                {this.renderContent()}
            </div>
        )
    }
}