import React from 'react';
import {Preloader} from "react-materialize";

export class LoaderSection extends React.Component {

    render() {
        return (
            <div className="loader-section-content">
                <Preloader size='big'/>
                <p className="message">{this.props.message}</p>
            </div>
        );
    }
}
