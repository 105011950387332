import React from "react";
import PaymentBase from "../PaymentBase";

export default class SelectPaymentTransferEndingStepFour extends PaymentBase {

    title() {
        return <h1>Verifique su correo</h1>
    }

    body() {
        let action = this._action();
        return (
            <div className="middle-bar-content payment-bar transfer-payment-end">
                <p className="payment-information-text">
                    {action} a tu cuenta <span>{this.props.warrantyInformation.applicantEmail}</span> los datos
                    bancarios para realizar la transferencia.
                </p>
                {this._paymentReceiptMessage()}
                <p className="payment-information-text">
                    La acreditación del pago se realiza dentro de las 24 horas hábiles de haber sido informado el mismo.
                </p>
                <p className="payment-information-text">
                    Te contactaremos para avisarte.
                </p>
            </div>
        );
    }

    _action() {
        if (this.props.hasOnePayment) {
            return 'Enviaremos';
        }
        return this.props.warrantyInformation.office.hasBankAccount ? 'Hemos enviado' : 'Enviaremos';
    }

    _paymentReceiptMessage() {
        let initialMessage = 'Finalizada la misma, deberás enviar el comprobante de pago a ';
        if (this.props.hasOnePayment) {
            return <p className="payment-information-text">
                {initialMessage} <span>cobros@finaersa.com</span>.
            </p>;
        } else {
            return <p className="payment-information-text">
                {initialMessage} la Oficina Comercial que gestiona tu
                solicitud <span>{this.props.warrantyInformation.office.email}</span>.
            </p>;
        }
    }

    footer() {
        return (
            <div className="arrows-payment">
                <button onClick={this.props.handleNext} className={"main-btn confirm-payment-btn"}>Finalizar</button>
            </div>
        );
    }
}