import React, {Component} from "react";
import "../../../styles/App.scss";
import {PersonalDataOne} from "../../../components/request-form/personal-data/PersonalDataOne";
import {PersonalDataTwo} from "../../../components/request-form/personal-data/PersonalDataTwo";
import {JobDataOne} from "../../../components/request-form/job-data/JobDataOne";
import {JobDataTwo} from "../../../components/request-form/job-data/JobDataTwo";
import {JobDataThree} from "../../../components/request-form/job-data/JobDataThree";
import {Wizard} from "../../../components/elements/Wizard.js";
import {PersonalDataThree} from "../personal-data/PersonalDataThree";
import {cuitFormat} from "../../../lib/others";

export class CoApplicant extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: {
                state: PersonalDataOne.EMAIL_NOT_CONFIRMED,
                address: undefined
            },
            lastAddressWithConfirmationSent: undefined
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCuitInputChange = this.handleCuitInputChange.bind(this);
        this.handleAutoComplete = this.handleAutoComplete.bind(this);
        this.handleIncomeChange = this.handleIncomeChange.bind(this);
        this.handleEmailConfirmation = this.handleEmailConfirmation.bind(this);
    }

    componentDidMount() {
        this.changeEmailState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.personalDataOrEmailHasChanged(prevProps)) {
            this.changeEmailState();
        }
    }

    personalDataOrEmailHasChanged(prevProps) {
        return this.hasChangedParticipantEmailState() || this.hasChangedParticipant(prevProps);
    }

    hasChangedParticipant(prevProps){
        return this.hasSelectedParticipanFromProps(prevProps) &&
            this.participantData() !== this.participantDataFromProps(prevProps)
    }

    hasChangedParticipantEmailState(){
        return this.isNotNewParticipant() && this.participantData()['email'].value() !== this.state.email.address
    }

    changeEmailState() {
        // Aca tenemos un problema, al editar un particiapnte el backend no le envia toda la informacion al front.
        // Solo le responde "OK" en el caso existoso. Por lo que al editar el email no se actualiza su estado.
        // Por ahora solo voy a tener en cuenta el estado si corresponde a la misma direccion de email
        if (this.isNotNewParticipant()){
            const participantData = this.participantData();
            const emailState = participantData['stateEmail'].value();
            const emailAddress = participantData['email'].value();
            let newEmailState;
            if (emailState && emailAddress && emailState.direccion === emailAddress) {
                newEmailState = emailState.estado
            } else {
                if (this.state.lastAddressWithConfirmationSent === emailAddress){
                    newEmailState = PersonalDataOne.CONFIRMATION_REQUEST_SENT;
                }
                else {
                    newEmailState = PersonalDataOne.EMAIL_NOT_CONFIRMED;
                }
            }
            this.setState({email: {state: newEmailState, address: emailAddress}});
        }
    }

    isNotNewParticipant() {
        return this.participantData() != null;
    }

    isEmailConfirmed() {
        return this.state.email.state === PersonalDataOne.EMAIL_CONFIRMED;
    }

    isEmailConfirmationSent(){
        return this.state.email.state === PersonalDataOne.CONFIRMATION_REQUEST_SENT;
    }

    actualCoApplicantId() {
        return this.props.actualParticipantId;
    }

    emailConfirmation() {
        return this.props.emailConfirmation(this.participantType(), this.actualCoApplicantId())
    }

    handleEmailConfirmation(event) {
        event.preventDefault();
        this.emailConfirmation().then(response => {
            const result = response.result;
            this.setState({
                email: {state: result.estado_email, address: result.email},
                lastAddressWithConfirmationSent: result.email
            });
        });
    }

    handleCuitInputChange(inputName) {
        return (event) => {
            let cuit_input = cuitFormat(event.target.value);
            event.target.value = cuit_input;
            this.props.updateData('coApplicants', this.actualCoApplicantId(), 'participantData', inputName, cuit_input);
        }
    }

    handleInputChange(inputName) {
        return (event) => {
            const value = event.target.value;
            this.props.updateData('coApplicants', this.actualCoApplicantId(), 'participantData', inputName, value);
        }
    }

    handleIncomeChange(inputName, value){
        this.props.updateData('coApplicants', this.actualCoApplicantId(), 'participantData', inputName, value);
    }

    handleAutoComplete(inputName, value) {
        this.props.updateData('coApplicants', this.actualCoApplicantId(), 'participantData', inputName, value);
    }

    participantData() {
        if (this.hasSelectedParticipanFromProps(this.props)) {
            return this.participantDataFromProps(this.props);
        }
        return null
    }

    hasSelectedParticipanFromProps(props){
        return props.actualParticipantId != null
    }

    participantDataFromProps(props) {
        return props.coApplicants[props.actualParticipantId].participantData;
    }

    participantType() {
        return 'coApplicants';
    }

    renderContent() {
        if (!this.hasSelectedParticipanFromProps(this.props)) {
            return (
                <div className="loader-content">
                    <p className="message">Aún no tiene cosolicitantes</p>
                </div>
            )
        }

        const elements = [
            <PersonalDataOne key="1" handleInputChange={this.handleInputChange}
                             participantData={this.participantData()}
                             canEditId={true} {...this.props}
                             personalDataChecked={this.props.personalDataChecked}
                             emailConfirmation={this.handleEmailConfirmation}
                             confirmButtonIsShown={!this.isEmailConfirmed()}
                             isEmailConfirmed={this.isEmailConfirmed()}
                             isEmailConfirmationSent={this.isEmailConfirmationSent()}
            />,
            <PersonalDataTwo key="3" handleInputChange={this.handleInputChange}
                             handleAutoComplete={this.handleAutoComplete}
                             participantData={this.participantData()} {...this.props}
                             personalDataChecked={this.props.personalDataChecked}/>,
            <PersonalDataThree key="4" handleInputChange={this.handleInputChange}
                               participantData={this.participantData()} {...this.props}
                               personalDataChecked={this.props.personalDataChecked}/>,
            <JobDataOne key="5" handleInputChange={this.handleInputChange}
                        handleCuitInputChange={this.handleCuitInputChange}
                        participantData={this.participantData()}
                        jobDataChecked={this.props.jobDataChecked} {...this.props}/>,
            <JobDataTwo key="6" handleInputChange={this.handleInputChange}
                        handleAutoComplete={this.handleAutoComplete}
                        participantData={this.participantData()}
                        jobDataChecked={this.props.jobDataChecked} {...this.props}/>,
            <JobDataThree key="7" handleInputChange={this.handleInputChange}
                          handleCuitInputChange={this.handleCuitInputChange}
                          handleIncomeChange={this.handleIncomeChange}
                          participantData={this.participantData()}
                          jobDataChecked={this.props.jobDataChecked} {...this.props}/>

        ];

        if (this.props.isMobile) {
            return elements;
        } else {
            return (
                <Wizard>
                    {elements}
                </Wizard>
            )
        }
    }

    render() {
        return (
            <div className="form-content">
                {this.renderContent()}
            </div>
        )
    }
}
