import React, {Component} from "react";
import "../../../styles/App.scss";
import {Input, Row} from "react-materialize";
import {range} from "../../../lib/others";
import NumberFormat from "react-number-format";


export class PropertyDataTwo extends Component {
    constructor(props) {
        super(props);

        this.rentalPeriods = this.rentalPeriods.bind(this);
        this.periodName = this.periodName.bind(this);
        this.handleRentalTypeChange = this.handleRentalTypeChange.bind(this);
        this.handleRentalPeriodChange = this.handleRentalPeriodChange.bind(this);
        this.handleRentalValueChange = this.handleRentalValueChange.bind(this);
    }

    handleRentalTypeChange(event) {
        const newRentalType = event.target.value;
        const rentalPeriod = this.props.property.rentalPeriod.value();
        let fieldsToUpdate = {rentalType: newRentalType};

        this.resetUnusedAmountFields(fieldsToUpdate, rentalPeriod);
        this.updateFields(fieldsToUpdate);
    }

    handleRentalPeriodChange(event) {
        const newRentalPeriod = event.target.value;
        let fieldsToUpdate = {rentalPeriod: newRentalPeriod};

        this.resetUnusedAmountFields(fieldsToUpdate, newRentalPeriod);
        this.updateFields(fieldsToUpdate);
    }

    handleRentalValueChange(inputName, value) {
        this.props.updateData({[inputName]: value});
    }

    resetUnusedAmountFields(fieldsToUpdate, rentalPeriod) {
        const rentalPeriods = this.rentalPeriods(rentalPeriod);

        if (rentalPeriods.length > 0) {
            const lastPeriod = rentalPeriods[rentalPeriods.length - 1];
            const periodsToReset = range(lastPeriod + 1, 9);
            periodsToReset.forEach(period => {
                fieldsToUpdate['amountPeriod' + period] = 0;
            });
        }
    }

    updateFields(fieldsToUpdate) {
        this.props.updateData(fieldsToUpdate);
    }

    rentalPeriods(rentalPeriod) {
        const amountOfPeriodsPerYear = {
            'ANUAL': 1,
            'SEMESTRAL': 2,
            'OCTOMESTRAL': 1.5,
            'CUATRIMESTRAL': 3
        };

        if (rentalPeriod) {
            const lastPeriod = Math.ceil(amountOfPeriodsPerYear[rentalPeriod] * this._rentalLength());
            return range(1, lastPeriod);
        }

        return [];
    }

    rentalType() {
        return this.props.property['rentalType'].value();
    }

    periodName() {
        const names = {
            'ANUAL': 'Año',
            'SEMESTRAL': 'Semestre',
            'OCTOMESTRAL': 'Octomestre',
            'CUATRIMESTRAL': 'Cuatrimestre'
        };

        const rentalPeriod = this.props.property.rentalPeriod.value();

        return rentalPeriod !== undefined ? names[rentalPeriod] : ''
    }

    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <h2 className="form-header">Valores de Alquiler</h2>
                <div className="form-inputs">
                    {this.renderRentalType()}
                    {this.renderRentalPriceAdjustment()}
                    {this.renderRentalPrice()}
                    {this.renderWarningDisabledRentalValues()}
                </div>
            </div>
        );
    }

    renderRentalPeriods() {
        if (this.isCommercialRental()) {
            let periodName = this.periodName();
            let rentalPeriods = this.rentalPeriods(this.props.property.rentalPeriod.value());
            return rentalPeriods.map(rentalPeriodNumber => {
                return this.renderAmountPeriod(rentalPeriodNumber, rentalPeriodNumber + 'º ' + periodName)
            })
        }
        else {
            return this.renderAmountPeriod(1, "Alquiler")
        }
    }

    renderAmountPeriod(rentalPeriodNumber, label) {
        const fieldName = 'amountPeriod' + rentalPeriodNumber;
        return (<NumberFormat customInput={Input} key={fieldName} s={2}
                              label={label}
                              thousandSeparator={'.'} decimalSeparator={','} prefix={'$'}
                              defaultValue={this.props.property[fieldName].value()}
                              onValueChange={(value) => this.handleRentalValueChange(fieldName, value.value)}
                              error={this.props.property[fieldName].error()}
                              disabled={this.isRentalValueEditionDisabled()}/>)
    }

    renderRentalPrice() {
        return <Row>
            <div className={"combined-input col s12"}>
                <p className="combined-input-label">Montos de alquiler</p>
                {this.renderRentalPeriods()}
                <NumberFormat customInput={Input} s={2} label="Expensas"
                              thousandSeparator={'.'} decimalSeparator={','} prefix={'$'}
                              defaultValue={this.props.property['expenses'].value()}
                              onValueChange={(value) => this.handleRentalValueChange('expenses', value.value)}
                              error={this.props.property['expenses'].error()}
                              disabled={this.isRentalValueEditionDisabled()}/>
            </div>
        </Row>;
    }

    renderRentalPriceAdjustment() {
        if (this.isCommercialRental()) {
            return <Row className="content-radio-buttons">
                <div className="col -text">
                    <p>Actualización</p>
                </div>
                <div className="radio-button-option">
                    <input type="radio" value={'CUATRIMESTRAL'}
                           name={'rentalPeriod'}
                           defaultChecked={this.props.property['rentalPeriod'].value() === 'CUATRIMESTRAL'}
                           onChange={this.handleRentalPeriodChange}/>
                    <p>Cuatrimestral</p>
                </div>
                <div className="radio-button-option">
                    <input type="radio" value={'SEMESTRAL'}
                           name={'rentalPeriod'}
                           defaultChecked={this.props.property['rentalPeriod'].value() === 'SEMESTRAL'}
                           onChange={this.handleRentalPeriodChange}/>
                    <p>Semestral</p>
                </div>
                <div className="radio-button-option">
                    <input type="radio" value={'OCTOMESTRAL'}
                           name={'rentalPeriod'}
                           defaultChecked={this.props.property['rentalPeriod'].value() === 'OCTOMESTRAL'}
                           onChange={this.handleRentalPeriodChange}/>
                    <p>Octomestral</p>
                </div>
                <div className="radio-button-option">
                    <input type="radio" value={'ANUAL'}
                           name={'rentalPeriod'}
                           defaultChecked={this.props.property['rentalPeriod'].value() === 'ANUAL'}
                           onChange={this.handleRentalPeriodChange}/>
                    <p>Anual</p>
                </div>
            </Row>;
        }
    }

    renderRentalType() {
        return <Row className="content-radio-buttons">
            <div className="col -text">
                <p>Tipo de alquiler</p>
            </div>
            <div className="radio-button-option">
                <input type="radio" value={'VIVIENDA'}
                       name={'rentalType'}
                       defaultChecked={this.props.property['rentalType'].value() === 'VIVIENDA'}
                       onChange={this.handleRentalTypeChange}/>
                <p>Vivienda</p>
            </div>
            <div className="radio-button-option">
                <input type="radio" value={'COMERCIAL'}
                       name={'rentalType'}
                       defaultChecked={this.isCommercialRental()}
                       onChange={this.handleRentalTypeChange}/>
                <p>Comercial</p>
            </div>
        </Row>;
    }

    renderWarningDisabledRentalValues(){
        if (this.isRentalValueEditionDisabled()) {
            return(
                <p className="form-disabled-warning">
                    Para modificar los montos de alquiler, comunícate con tu oficina comercial asignada. </p>
            )
        }
    }

    isRentalValueEditionDisabled() {
        return this.props.property['isContractExtension'].value();
    }

    isCommercialRental() {
        return this.props.property['rentalType'].value() === 'COMERCIAL';
    }

    _rentalLength() {
        return 3;
    }
}