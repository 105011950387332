import React from "react";
import PaymentBase from "../PaymentBase";
import {SelectPaymentMethodBtn} from "../../buttons/SelectPaymentMethodBtn";
import {LeftButton} from "../../buttons/LeftButton";

export default class SelectPaymentTypeStep extends PaymentBase{

    title(){
        return <h1>Forma de pago</h1>
    }

    _isOneInstallmentAValidPaymentOption(){
        return this.props.installmentsAllowed.includes(1);
    }

    _canPayInMultipleInstallments(){
        let installmentsAllowedWithoutInitialPayment = this.props.installmentsAllowed.filter(installment => installment > 1);
        // TODO: extender listas de js con isEmpty
        return installmentsAllowedWithoutInitialPayment.length > 0;
    }

    _renderCashOption(){
        if (this._isOneInstallmentAValidPaymentOption()) {
            return  <SelectPaymentMethodBtn text="Contado" icon="money-bill" onFinishDo={this.props.onOnePaymentSelectedDo}/>
        }
    }

    _renderInstallmentsOption(){
        if (this._canPayInMultipleInstallments()) {
            return <SelectPaymentMethodBtn text="En cuotas" icon="money-bill" onFinishDo={this.props.onInstallmentsPaymentSelectedDo}/>
        }
    }

    body() {
        return (
            <div className="middle-bar-content payment-bar">
                {this._renderCashOption()}
                {this._renderInstallmentsOption()}
            </div>
        );
    }

    footer() {
        return (
            <div className="arrows-payment">
                <LeftButton onClick={this.props.handlePrevious}/>
            </div>
        );
    }
}