import React, {Component} from "react";
import '../styles/App.scss';
import {app} from "../app/app";

class Renewal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            branch_office_name : '',
            branch_office_email : '',
            branch_office_telephone : '',
            branch_office_location : '',
            applicant_name : ''
        }
    }

    componentDidMount() {
        app.apiClient().getRenewal(this.props.match.params.nro).then(result => {
            let branch_office_name = result.branch_office_name();
            let branch_office_email = result.branch_office_email();
            let branch_office_telephone = result.branch_office_telephone_number();
            let branch_office_location = result.branch_office_location();
            let branch_office_type = result.branch_office_type();
            let branch_office_image_url = result.branch_office_image_url();
            let warranty_number = result.warranty_number();
            let applicant = result.applicant_name();
            this.setState({
                branch_office_name: branch_office_name,
                branch_office_email: branch_office_email,
                branch_office_telephone: branch_office_telephone,
                branch_office_location: branch_office_location,
                branch_office_type: branch_office_type,
                branch_office_image_url: branch_office_image_url,
                applicant_name: applicant,
                warranty_number: warranty_number,
            });
        })

    }

    render() {
        return (
            <div style={{ width: '599px', margin: '0 auto' }}>
                <div style={{ height: '30px', textAlign: 'center', paddingTop: '10px', verticalAlign: 'middle', fontFamily: "'Montserrat', sans-serif", color: '#4A4A49', fontSize: '8pt' }}>
                    Si no puede visualizar correctamente este mail, haga <a href="http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_prorroga/solicitante_prorroga.html" style={{ color: '#e30613' }}>CLICK AQUÍ</a>
                </div>
                <div style={{ width: '599px', border: '1px solid #FCFCFC', margin: '0 auto' }}>
                    <div style={{width: '599px', margin: '0 auto'}}>
                        <div>
                            <img
                                src="https://ftp.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_prorroga/img/cabezal.jpg"
                                alt="Cabezal" style={{width: '600px'}}/>
                        </div>
                        <div style={{paddingLeft: '71px', paddingRight: '71px'}}>
            <span style={{
                fontFamily: "'Montserrat', sans-serif",
                textAlign: 'justify',
                color: '#2f3743',
                fontSize: '13pt',
                lineHeight: 1.8,
                fontWeight: 'bold'
            }}>
              Hola, <span style={{color: '#e30613'}}>{this.state.applicant_name}</span>.<br/>Hemos generado tu pedido de prórroga.
            </span>
                            <br/><br/>
                            <span style={{
                                fontFamily: "'Montserrat', sans-serif",
                                textAlign: 'justify',
                                color: '#4A4A49',
                                fontSize: '11pt',
                                lineHeight: 1.8
                            }}>
              <span style={{fontWeight: 'bold'}}>Tené en cuenta el nuevo código de tu Solicitud</span>: <strong
                                style={{color: '#e30613'}}>{this.state.warranty_number}</strong><strong>.</strong>
              <br/><br/>
              <a href="https://finaersa.com.ar/" target="_blank" rel="noreferrer" style={{color: '#e30613'}}>Hacé CLICK aquí, y seleccioná la opción SEGUÍ TU SOLICITUD</a> para poder completar los datos requeridos y actualizar tu documentación.<br/><br/>
              Recordá que podés hacer el seguimiento de tu solicitud o despejar cualquier duda, comunicándote con la oficina asignada:<br/><br/>
            </span>
                        </div>
                        <div>
                            <td colSpan="3" >
                                {this.renderOfficeMap()}
                            </td>
                        </div>
                        <div>
                            <img
                                src="https://ftp.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_prorroga/img/pie.jpg"
                                alt="Pie" useMap="#Map2"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOfficeMap() {
        return (
            <table style={{ width: '100%', borderSpacing: '0', borderCollapse: 'collapse' }}>
                <tbody>
                <tr>
                    <td style={{ width: '69px' }} rowSpan="6"></td>
                    <td style={{ width: '88px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#F8DFC7', height: '72px' }}>
                        <img src="http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_fianza_firmada/img/consulta.png" alt="Consulta" />
                    </td>
                    <td style={{ height: '72px', width: '372px', backgroundColor: '#F8DFC7', verticalAlign: 'middle' }}>
            <span style={{ fontFamily: "'Montserrat', sans-serif", textAlign: 'justify', color: '#e30613', fontSize: '15pt', fontWeight: 'normal' }}>
              <strong>Oficina {this.state.branch_office_type} {this.state.branch_office_name}</strong>
            </span>
                    </td>
                    <td style={{ width: '71px' }} rowSpan="6"></td>
                </tr>
                <tr>
                    <td style={{ height: '51px', backgroundColor: '#FCEFE3', textAlign: 'center', verticalAlign: 'center' }}>
                        <img src="http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_fianza_firmada/img/bullet.png" alt="Bullet" />
                    </td>
                    <td style={{ backgroundColor: '#FCEFE3' }}>
            <span style={{ fontFamily: "'Montserrat', sans-serif", textAlign: 'justify', color: '#4A4A49', fontSize: '10pt' }}>
              <strong>{this.state.branch_office_location}</strong>
            </span>
                    </td>
                </tr>
                <tr>
                    <td style={{ height: '51px', backgroundColor: '#FCEFE3', textAlign: 'center', verticalAlign: 'center' }}>
                        <img src="http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_fianza_firmada/img/bullet.png" alt="Bullet" />
                    </td>
                    <td style={{ backgroundColor: '#FCEFE3' }}>
            <span style={{ fontFamily: "'Montserrat', sans-serif", textAlign: 'justify', color: '#4A4A49', fontSize: '10pt' }}>
              <strong>{this.state.branch_office_telephone}</strong>
            </span>
                    </td>
                </tr>
                <tr>
                    <td style={{ height: '51px', backgroundColor: '#FCEFE3', textAlign: 'center', verticalAlign: 'center' }}>
                        <img src="http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_fianza_firmada/img/bullet.png" alt="Bullet" />
                    </td>
                    <td style={{ backgroundColor: '#FCEFE3' }}>
            <span style={{ fontFamily: "'Montserrat', sans-serif", textAlign: 'justify', color: '#4A4A49', fontSize: '10pt' }}>
              <a href={`mailto:${this.state.branch_office_email}`} style={{ textDecoration: 'none' }}>
                <strong style={{ color: '#e30613' }}>{this.state.branch_office_email}</strong>
              </a>
            </span>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ backgroundColor: '#FCEFE3' }}>
                        <img src={this.state.branch_office_image_url} alt="Mapa de la Oficina" style={{ border: '0' }} />
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

export default Renewal;