import React from 'react';

export class MercadoPagoBtn extends React.Component {
    render() {
        return (
            <button className={this.props.class} onClick={this.props.onFinishDo}>
                <p>Pago con tarjeta de crédito / débito</p>
            </button>
        )
    }
}