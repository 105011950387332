import React, {Component} from 'react';
import '../styles/App.scss';
import logo from "../images/logo-finaer.svg";
import Link from "react-router-dom/es/Link";
import SuccessMessage from "../components/prequalification-result/SuccessMessage";
import FailureMessage from "../components/prequalification-result/FailureMessage";
import {withRouter} from "react-router-dom";
import {registerGoogleTagManagerEvent} from "../lib/googleTagManager";
import FailureMessageInvalidCoApplicant from "../components/prequalification-result/FailureMessageInvalidCoApplicant";
import FailureMessageWithMaximumNumberOfCoApplicants
    from "../components/prequalification-result/FailureMessageWithMaximumNumberOfCoApplicants";
import FailureMessageAddNewCoApplicant from "../components/prequalification-result/FailureMessageAddNewCoApplicant";
import {app} from "../app/app";
import WarrantyApplicationSourceParameterMapper from "../models/WarrantyApplicationSourceParameterMapper";

class PrequalificationResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prequalify: props.location.state.prequalify,
            source: props.location.state.source,
            warrantyPrequalificationNumber: props.location.state.warrantyPrequalificationNumber,
            warrantyPrequalification: props.location.state.warrantyPrequalification,
            numberOfCoApplicants: props.location.state.numberOfCoApplicants || 0,
            coApplicant: props.location.state.coApplicant,
            coApplicantAdded: props.location.state.coApplicantAdded || false,
        };

        this.handleRedirectToWarrantyRequest = this.handleRedirectToWarrantyRequest.bind(this);
        this.handleRedirectToAddCoapplicant = this.handleRedirectToAddCoapplicant.bind(this);
    };

    handleRedirectToWarrantyRequest(event) {
        event.preventDefault();
        let eventDescription = this.state.prequalify ? "IniciaSolicitudConPrecalificacionPositiva" : "IniciaSolicitudConPrecalificacionNegativa";
        registerGoogleTagManagerEvent({
            'event': eventDescription,
            'event_category': 'Finaer', 'event_label': ''
        });
        this.getLeadFromPrequalification()
    }

    getLeadFromPrequalification() {
        app.apiClient().getLeadFromPrequalification({
            warrantyPrequalificationId: this.state.warrantyPrequalificationNumber
        }, (response) => {
            if (!response.hasErrors()){
                this.linkToCreateWarrantyLeadWizard(response.lead())
            }
            console.log(response)
        });
    }

    source() {
        const warrantyApplicationSourceParameterMapper = new WarrantyApplicationSourceParameterMapper();
        return warrantyApplicationSourceParameterMapper.sourceCodeFor(this.state.source, () => undefined)
    }

    linkToCreateWarrantyLeadWizard(aWarrantyLead){
        this.props.history.push({
            pathname: '/inicio',
            state: {
                warrantyLead: aWarrantyLead,
            }
        })
    }

    handleRedirectToAddCoapplicant(event) {
        event.preventDefault();
        registerGoogleTagManagerEvent({
            'event': 'IngresoParaAgregarCosolicitantePrecalifcacion',
            'event_category': 'Finaer', 'event_label': ''
        });
        this.props.history.push({
            pathname: '/precalificacion-co',
            state: {
                source: this.state.source,
                warrantyPrequalificationNumber: this.state.warrantyPrequalificationNumber,
                warrantyPrequalification: this.state.warrantyPrequalification,
                numberOfCoApplicants: this.state.numberOfCoApplicants + 1,
            }
        })
    }

    render() {
        return (
            <div className="layout-request begin">
                <header>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </Link>
                </header>
                <main>
                    {this.renderContent()}
                </main>
            </div>
        );
    }

    renderContent() {
        if (this.state.prequalify) {
            return <SuccessMessage handleRedirect={this.handleRedirectToWarrantyRequest}/>
        }
        return this.renderFailureCases();
    }

    renderFailureCases() {
        if (!this.hasCoApplicant()) {
            return <FailureMessage handleRedirectToAddCoapplicant={this.handleRedirectToAddCoapplicant}
                                   handleRedirectToWarrantyRequest={this.handleRedirectToWarrantyRequest}/>
        } else {
            if (this.state.coApplicantAdded) {
                return this.renderFailureWhenACoApplicantWasAdded();
            } else {
                return <FailureMessageInvalidCoApplicant
                    handleRedirectToAddCoapplicant={this.handleRedirectToAddCoapplicant}
                    handleRedirectToWarrantyRequest={this.handleRedirectToWarrantyRequest}
                    coApplicantName={this.coApplicantName()}
                />
            }
        }
    }

    renderFailureWhenACoApplicantWasAdded() {
        if (this.reachTheMaximumNumberOfCoApplicants()) {
            return <FailureMessageWithMaximumNumberOfCoApplicants
                handleRedirectToAddCoapplicant={this.handleRedirectToAddCoapplicant}
                handleRedirectToWarrantyRequest={this.handleRedirectToWarrantyRequest}
            />
        }
        return <FailureMessageAddNewCoApplicant
            handleRedirectToAddCoapplicant={this.handleRedirectToAddCoapplicant}
            handleRedirectToWarrantyRequest={this.handleRedirectToWarrantyRequest}
        />
    }

    hasCoApplicant() {
        return this.state.numberOfCoApplicants > 0;
    }

    reachTheMaximumNumberOfCoApplicants() {
        return this.state.numberOfCoApplicants > 2;
    }

    coApplicantName() {
        return `${this.state.coApplicant.name.value} ${this.state.coApplicant.surname.value}`;
    }
}

export default withRouter(PrequalificationResult);