import React, {Component} from 'react';
import {HelperModal} from "../../components/modals/HelperModal";
import Link from "react-router-dom/Link";

class FailureMessage extends Component {
    render() {
        return (
            <div className="pre-request-result">
                <p className="secondary-title">Según la información suministrada requerimos<br/>que incluyas un Co-Solicitante<HelperModal
                    title="¿Qué es un Co-Solicitante?"
                    text={this.textForHelper()}/></p>
                <p className="exclamation-text">¡Es muy fácil!</p>

                <button className="main-btn" onClick={this.props.handleRedirectToAddCoapplicant}>Agregar Co-Solicitante</button>
                <br/>
                <p className="basic-text">
                    ¿Te falta información para ingresar otra persona?
                    <br/>
                    ¡No te preocupes!
                    <br/>
                    Te ofrecemos crear tu solicitud ahora y completarla en otro momento. ¡Es muy fácil!
                    <br/>
                    <button className="link-btn-pre-request" onClick={this.props.handleRedirectToWarrantyRequest}>
                        Avanzá tu Solicitud
                    </button>
                </p>


                <div className="contact">
                    <p>Si deseás más información, recordá que siempre podés comunicarte con la
                        <Link className="link" to="/sucursales" target="_blank">Oficina Finaer más cercana</Link>
                    </p>
                </div>
            </div>

        )
    }

    textForHelper() {
        return (
                <p>
                    Es una persona que te
                    acompañará en tu Solicitud, y cuyos ingresos serán sumados a los tuyos para
                    ayudar a aprobar tu Garantía. Sus datos y documentación pueden ser cargados
                    directamente desde tu propia Solicitud.
                </p>
        )
    }
}



export default FailureMessage;