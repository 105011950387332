import React from 'react';
import {MainButton} from "../buttons/MainButton";
import corner from "../../images/corner.svg";
import calculadora from "../../images/calculadora.svg";
import casas from "../../images/casas.svg";

export class InfoBox extends React.Component {

    render() {
        return (
            <div className={'box-content -info ' + this.props.class}>
                <div className="box">
                    <h2>Inmobiliarias y propietarios</h2>
                    <p><span>+10.000</span> Inmobiliarias adheridas.</p>
                    <p><span>+70.000</span> Propietarios ya confiaron en nosotros.</p>
                    <MainButton to="/informacion" text="Más información"/>
                    <img src={casas} className="casas" alt="ilustración de casas"/>
                </div>
                <img src={corner} className="corner" alt="elemento decorativo"/>
            </div>
        );
    }
}

export class UserBox extends React.Component {

    render() {
        return (
            <div className={'box-content -user ' + this.props.class}>
                <div className="box">
                    <h2>¿Qué necesito?</h2>
                    <div className="btn-list">
                        <MainButton to="/requisitos" text="Requisitos"/>
                        <MainButton to="/calculadora" text="Calcular Costos"/>
                    </div>
                    <img src={calculadora} className="calculadora" alt="ilustración de una calculadora"/>
                    <MainButton class="-box" to="/inicio" text="Solicitar Garantía"/>
                </div>
                <img src={corner} className="corner" alt="elemento decorativo"/>
            </div>
        );
    }
}
