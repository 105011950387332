import React from "react";
import {Modal, Input, Row} from "react-materialize";
import {CloseButton} from "../buttons/CloseButton";
import {app} from "../../app/app";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FinaerSelect} from "../elements/Select";
import ErrorMessageCreator from "../../models/ErrorMessageCreator";

export class RegretModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: undefined,
            formData: {
                idType: {value: 'DNI'},
                idNumber: {},
                email: {},
                phoneNumber: {},
                warrantyNumber: {},
                name:{},
                lastname:{}
            },
            valid: this.validApplicationForRegret()
        };

        this._form = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleIdNumberChange = this.handleIdNumberChange.bind(this);
        this.handleIdTypeChange = this.handleIdTypeChange.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.clearFormErrors = this.clearFormErrors.bind(this);
        this.hasError = this.hasError.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    validApplicationForRegret() {
        let requiredFields = ['idType', 'email', 'idNumber', 'warrantyNumber'];
        if (!this.state) return false;
        return requiredFields.every(
            field => ((this.state.formData[field].value !== undefined) && (this.state.formData[field].valid === true))
        )
    }

    form() {
        return this._form.current;
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for(let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    regretData() {
        let formData = this.state.formData;
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clearFormErrors();
        app.apiClient().sendRegret(this.regretData(), this.handleApiResponse);
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            const code = response.arrepentimiento.codigo;
            this.setState({code: code});
        }
    }

    modalContent() {
        if (this.wasRegretSuccesfullyRegisterd()) {
            return this.renderSuccessMessage(this.state.code)
        } else {
            return this.renderForm();
        }
    }

    wasRegretSuccesfullyRegisterd() {
        return this.state.code !== undefined;
    }

    noErrorsIn(formData) {
        for (var field in formData) {
            if(formData[field].error) {
                return false;
            }
        }

        return true
    }

    formRegretisValid(formData) {
        return this.form().checkValidity() && this.noErrorsIn(formData);
    }

    updateFormData(formData) {
        this.setState({formData: formData, valid: this.formRegretisValid(formData)})
    }

    handleInputChange(inputName, checkValueForErrorMessage){
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            formData[inputName] = {value: input.value, error: checkValueForErrorMessage(input)};
            this.updateFormData(formData)
        }
    }

    handleIdTypeChange(event) {
        let formData = this.state.formData;
        let docType =  event.target.value;
        formData['idType'] = {value: docType};
        let idNumber = formData.idNumber.value;
        formData['idNumber'] = {value: idNumber, error: ErrorMessageCreator.errorMessageIfIdNumberIsInvalid(idNumber, docType)};
        this.updateFormData(formData)
    }

    handleIdNumberChange(event) {
        let formData = this.state.formData;
        let newIdNumber = event.target.value;
        formData['idNumber'] = {value: newIdNumber, error: ErrorMessageCreator.errorMessageIfIdNumberIsInvalid(newIdNumber, formData['idType'].value)};
        this.updateFormData(formData)
    }

    onCloseModal() {
        this.setState({code: undefined});
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    renderSuccessMessage(code) {
        return (
            <div className="success-msg-item" id="arrepentimientoExitoso">
                <p>Hemos recibido tu requerimiento y responderemos a la brevedad.</p>
                <p>El código de identificación de tu pedido es: <b>{code}</b></p>
                <p>Te hemos enviado un mail  al correo informado con el nro de código de identificación de arrepentimiento.</p>
                <p>SI no lo recibes en tu bandeja de entrada, te aconsejamos revisar  la carpeta de Spam o Correo No deseado.</p>
                <p>Muchas Gracias!</p>
            </div>
        )
    }

    renderIcon() {
        if (this.props.icon) {
            return <FontAwesomeIcon className="icon" icon={this.props.icon}/>
        }
    }

    renderModalTriggerBtn(){
        return (
            <div className="home-link-item-container">
                <button className={this.props.class + " styless-btn"}>
                    {this.renderIcon()}
                    <p>{this.props.buttonTitle}</p>
                </button>
                <p>{this.props.buttonDescription}</p>
            </div>
        );
    }

    renderForm() {
        return (
            <form ref={this._form}>
                <Row>

                    <Input s={6} label="Nombre" required={true}
                           onChange={this.handleInputChange('name', ErrorMessageCreator.errorMessageSimpleField)}
                           error={this.hasError('name')}/>

                    <Input s={6} label="Apellido" required={true}
                           onChange={this.handleInputChange('lastname', ErrorMessageCreator.errorMessageSimpleField)}
                           error={this.hasError('lastname')}/>
                    <div className="col input-field m4 s12">
                        <div className="react-select -padding-adjustment">
                            <FinaerSelect
                                selectedOption={this.state.formData.idType.value}
                                onChange={this.handleIdTypeChange}
                                placeholder="Tipo"
                                required={true}
                                options={[
                                    {label: 'DNI', value: 'DNI'},
                                    {label: 'Pasaporte', value: 'PAS'},
                                ]}/>
                        </div>
                    </div>
                    <Input s={8} label="Nro. de Documento" required={true}
                           onChange={this.handleIdNumberChange}
                           error={this.hasError('idNumber')}/>
                    <Input s={6} label="Nro de solicitud" placeholder="A-1A2ABC" required={true}
                           onChange={this.handleInputChange('warrantyNumber', ErrorMessageCreator.errorMessageSimpleField)}
                           error={this.hasError('warrantyNumber')}/>
                    <Input s={6} label="Número de teléfono" required={true}
                           onChange={this.handleInputChange('phoneNumber', ErrorMessageCreator.errorMessageSimpleField)}
                           error={this.hasError('phoneNumber')}/>
                    <Input s={12} label="Email" required={true}
                           onChange={this.handleInputChange('email', ErrorMessageCreator.errorMessageIfInvalidEmail)}
                           error={this.hasError('email')} type={'email'}/>
                </Row>
                <Row className="submit-row">
                    <button className="main-btn"
                            disabled={!this.state.valid}
                            onClick={this.handleSubmit}>
                        Enviar
                    </button>
                </Row>
            </form>
        )
    }

    render() {
        return (
            <Modal header='Formulario de Arrepentimiento'
                   trigger={this.renderModalTriggerBtn()}
                   modalOptions={{complete: this.onCloseModal}}>
                <div className="modal-body">
                    <div className="modal-main">
                        {this.modalContent()}
                        <CloseButton/>
                    </div>
                </div>
            </Modal>
        );
    }

}