import React from "react";
import {Modal, Input, Row} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalAside} from "./ModalAside";
import {app} from "../../app/app";
import {CloseButton} from "../../components/buttons/CloseButton";
import Link from "react-router-dom/es/Link";
import PropTypes from 'prop-types'


export class ContactModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            formData: {
                fullName: {},
                email: {},
                phoneNumber: {},
                question: {}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.contactData = this.contactData.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.isValid = this.isValid.bind(this);
        this.clearFormErrors = this.clearFormErrors.bind(this);
        this.hasError = this.hasError.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal() {
        this.setState({submitted: false});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clearFormErrors();
        app.apiClient().sendQuestions(this.contactData(), this.handleApiResponse);
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.setState({submitted: true});
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({formData: formData, nonFieldErrors: response.nonFieldErrors()})
        }
    }

    contactData() {
        let formData = this.state.formData;
        let contactData = {emailTo: this.props.showDestinationEmail}
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, contactData);
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.value, error: this.isValid(input)};
            this.setState({formData: formData, valid: form.checkValidity()})
        }
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for(let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    renderIcon() {
        if (this.props.icon) {
            return <FontAwesomeIcon className="icon" icon={this.props.icon}/>
        }
    }

    modalContent() {
        if (this.state.submitted) {
            return this.renderSuccessMessage()
        } else {
            return this.renderForm();
        }
    }

    renderSuccessMessage() {
        return (
            <p>Gracias por enviarnos tu consulta. Te contactaremos a la brevedad.<br/>
                También podés comunicarte al 6842-5100 o acercarte a cualquiera de nuestras <Link to="/sucursales">Oficinas Comerciales</Link>.</p>
        )
    }

    renderForm() {
        return (
            <form>
                <Row>
                    <Input s={12} label="Nombre y Apellido"
                           onChange={this.handleInputChange('fullName')}
                           error={this.hasError('fullName')}/>
                    <Input s={12} label="Email"
                           onChange={this.handleInputChange('email')}
                           error={this.hasError('email')}/>
                    <Input s={12} label="Teléfono"
                           onChange={this.handleInputChange('phoneNumber')}
                           error={this.hasError('phoneNumber')}/>
                    <Input s={12} type="textarea" label="Consulta"
                           onChange={this.handleInputChange('question')}
                           error={this.hasError('question')}/>
                </Row>
                <Row className="submit-row">
                    <p className="message">Envíanos tu consulta y nos comunicaremos a la brevedad</p>
                    <button className="main-btn" onClick={this.handleSubmit}>Enviar</button>
                </Row>
            </form>
        )
    }

    render() {
        return (
            <Modal header='Formulario de Consulta'
                   trigger={
                       <button className={this.props.class}>
                           {this.renderIcon()}
                           <p>{this.props.button}</p>
                       </button>
                   }
                   modalOptions={{complete: this.onCloseModal}}>
                <div className="modal-body">
                    <div className="modal-main">
                        {this.modalContent()}
                        <CloseButton/>
                    </div>
                    <ModalAside showDestinationEmail={this.props.showDestinationEmail}/>
                </div>
            </Modal>
        );
    }
}

ContactModal.propTypes = {
    button: PropTypes.string,
    showDestinationEmail: PropTypes.string,
}

ContactModal.defaultProps = {
    button: "¿Alguna duda? Consultanos",
    showDestinationEmail: 'info@finaersa.com.ar',
}
