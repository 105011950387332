import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {FinaerAutocomplete} from "../../elements/Autocomplete";
import {containsSubstringLike} from "../../../lib/others";
import {CheckedInput} from "../../elements/CheckedInput";
import {ControlledCheck} from "../../elements/ControlledCheck";


export class PersonalDataTwo extends Component {

    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Domicilio personal</h2>
                    <ControlledCheck checked={this.props.personalDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row>
                        <CheckedInput s={6} label="Calle" maxLength={100}
                                      defaultValue={this.props.participantData['addressStreet'].value()}
                                      onChange={this.props.handleInputChange('addressStreet')}
                                      error={this.props.participantData['addressStreet'].error()}
                                      checked={this.props.personalDataChecked}/>
                        <CheckedInput s={2} label="Número" maxLength={10}
                                      defaultValue={this.props.participantData['addressStreetNumber'].value()}
                                      onChange={this.props.handleInputChange('addressStreetNumber')}
                                      error={this.props.participantData['addressStreetNumber'].error()}
                                      checked={this.props.personalDataChecked}/>
                        <CheckedInput s={2} label="Piso" maxLength={10}
                                      defaultValue={this.props.participantData['addressStreetFloor'].value()}
                                      onChange={this.props.handleInputChange('addressStreetFloor')}
                                      error={this.props.participantData['addressStreetFloor'].error()}
                                      checked={this.props.personalDataChecked}/>
                        <CheckedInput s={2} label="Depto." maxLength={10}
                                      defaultValue={this.props.participantData['addressStreetApartment'].value()}
                                      onChange={this.props.handleInputChange('addressStreetApartment')}
                                      error={this.props.participantData['addressStreetApartment'].error()}
                                      checked={this.props.personalDataChecked}/>
                    </Row>
                    <Row>
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Provincia'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.participantData['addressProvince'].options()}
                                selectedItem={this.props.participantData['addressProvince'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={(province) => {this.props.handleAutoComplete('addressProvince', province)}}
                                checked={this.props.personalDataChecked}
                            />
                        </div>
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Ciudad / Localidad'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.participantData['addressCity'].options(this.props.participantData['addressProvince'])}
                                selectedItem={this.props.participantData['addressCity'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={(city) => this.props.handleAutoComplete('addressCity', city)}
                                checked={this.props.personalDataChecked}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}