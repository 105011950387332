import {Response} from "./responses";

export class CalculateRentalCostsResponse extends Response {
    constructor(apiResponse) {
        super(apiResponse);
        this._result = apiResponse['object'];
    }

    installmentsReport() {
        return this._result.posibles_planes_de_cuotas.map(entry => {
            return {
                totalFeeAmount: entry['monto_final'],
                totalFeeAmountWithoutDiscounts: entry['honorario_sin_descuentos'],
                discountPercentage: entry['porcentaje_de_descuento_aplicado'],
                discountFee: entry['descuento_aplicado'],
                feesQuantity: entry['cantidad_de_cuotas'],
                amountInAdvance: entry['anticipo'],
                feesAmount: entry['monto_cuotas'],
                lastDayOfDiscount: entry['fecha_limite_descuento']
            }
        })
    }

    mercadoPagoDiscount() {
        return this._result['porcentaje_descuento_mercadopago'];
    }

    rentValue() {
        return this._result['alquiler'];
    }

    expenses() {
        return this._result['expensas'];
    }

    contractPeriod() {
        return this._result['duracion_del_contrato_en_meses'];
    }

    // TODO: Analizar si este método está siendo utilizado y sacarlo en caso contrario
    mapFields(name) {
        let mapping = {
            alquiler: 'averageRentalValue',
            expensas: 'averageExpensesValue',
            duracion_contrato: 'contractPeriod'
        };

        return mapping[name]
    }
}