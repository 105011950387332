import React, {Component} from "react";

class DebtFreeNote extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL;
        let fileUrl = publicUrl + '/notificationDebtFree.docx';
        return (
            <main>
            <div className="layout-request begin">
                <div className="debt-free-download">
                    <p>Para descargar la nota para pedir libre deuda <a href={fileUrl}>haga click aquí</a></p>
                </div>
            </div>
            </main>
        );
    }
}

export default DebtFreeNote;