import React, {Component} from "react";
import {Row} from "react-materialize";
import {FinaerSelect} from "../../elements/Select";


export class CompanyAgreement extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.companyAgreementsOptions = this.companyAgreementsOptions.bind(this);
    }

    companyAgreementsOptions() {
        let options = [{value: '', label: 'Ninguno'}];

        this.props.options.forEach((agreement) => {
            options.push({value: agreement.id(), label: this.agreementName(agreement)})
        });

        return options;
    }

    agreementName(agreement) {
        return agreement.company() + ' - ' + agreement.companyCuit();
    }

    handleInputChange(inputName) {
        return (event) => {
            const value = event.target.value;
            this.props.updateData(inputName, value);
        }
    }

    render() {
        return (
            <div className="form-content">
                <h2 className="form-header">Convenios Corporativos</h2>
                <div className="form-inputs">
                    <Row>
                        <div className="col s12">
                            <div className="react-select -padding-adjustment">
                                <label className="active -add-padding">Convenio</label>
                                <FinaerSelect
                                    placeholder="Seleccione un convenio"
                                    selectedOption={this.props.companyAgreement || ''}
                                    options={this.companyAgreementsOptions()}
                                    onChange={this.handleInputChange('companyAgreement')}/>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        )
    }
}