import React from 'react';
import {FormNavItem} from "../../../../components/request-form/nav/web/FormNavItem";
import {NavBarItemCollapsible} from "../../../../components/request-form/nav/web/NavBarItemCollapsible";
import {FormNavInfo} from "../../../../components/request-form/nav/web/FormNavInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router-dom";
import {app} from "../../../../app/app";

class FormNav extends React.Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        app.logOutUser();
        this.props.history.push('/');
    }

    propertyFormProgress() {
        return this.props.property.progress;
    }

    companyAgreementFormProgress() {
        return 100;
    }

    formProgress(dataType, participantType = undefined) {
        let self = this;
        let types;

        if (participantType) {
            types = [participantType];
        } else {
            types = ['applicants', 'coApplicants'];
        }

        let participantsQuantity = 0;
        let totalProgress = 0;

        types.forEach(function (participantType) {
            Object.keys(self.props[participantType]).forEach(function (participantId) {
                const participant = self.props[participantType][participantId];
                let dataTypeProgress = participant.progress[dataType];
                participantsQuantity += 1;
                totalProgress += dataTypeProgress;
            });
        });

        return Math.ceil(totalProgress / participantsQuantity) || 0;
    }

    items() {
        return [
            <div key={'items'}>
                <div className="nav-action">
                    <FormNavItem
                        key="Solicitante"
                        text="Solicitante"
                        percentage={this.formProgress('participantData', 'applicants')}
                        onClick={() => this.props.changeStage('Applicant')}
                        active={this.props.active === 'Applicant' ? 'active' : ''}/>
                    <NavBarItemCollapsible
                        key="Co-Solicitante"
                        text="Co-Solicitante"
                        percentage={this.formProgress('participantData', 'coApplicants')}
                        onClick={() => this.props.changeStage('CoApplicant')}
                        {...this.props}/>
                    <FormNavItem
                        key="Documentos"
                        text="Documentos"
                        percentage={this.formProgress('documentsData')}
                        onClick={() => this.props.changeStage('DocumentLoad')}
                        active={this.props.active === 'DocumentLoad' ? 'active' : ''}/>
                    <FormNavItem
                        key="Inmueble"
                        text="Inmueble"
                        percentage={this.propertyFormProgress()}
                        onClick={() => this.props.changeStage('Property')}
                        active={this.props.active === 'Property' ? 'active' : ''}/>
                    <FormNavItem
                        key="Convenios"
                        text="Convenios"
                        percentage={this.companyAgreementFormProgress()}
                        onClick={() => this.props.changeStage('CompanyAgreement')}
                        active={this.props.active === 'CompanyAgreement' ? 'active' : ''}/>
                </div>
                <div key={'info'}>
                    <FormNavInfo key="Info" requestOffice={this.props.requestOffice}
                                 applicationNumber={this.props.applicationNumber}/>
                </div>
            </div>

        ]
    }

    renderMobile() {
        return (
            <div
                className={this.props.mobileMenuIsOpen ? "form-nav -mobile-menu -open-form-menu" : "form-nav -mobile-menu"}>
                <button className="form-mobile-close" onClick={this.props.closeMobileMenu}>
                    <FontAwesomeIcon className="icon" icon="times"/>
                </button>
                <button className="logout-btn" onClick={this.handleLogout}>
                    <p>Salir</p>
                    <FontAwesomeIcon className="icon" icon="sign-out-alt"/>
                </button>
                {this.items()}
            </div>
        );
    }

    renderWeb() {
        return (
            <div className="form-nav">
                {this.items()}
            </div>
        );
    }

    render() {
        if (this.props.isMobile) {
            return this.renderMobile();
        } else {
            return this.renderWeb();
        }
    }
}

export default withRouter(FormNav);