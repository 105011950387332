import React, {Component} from "react";
import "../../styles/App.scss";
import RequestNav from "../../components/layout/RequestNav.js";
import FormNav from "../../components/request-form/nav/web/FormNav";
import {Header} from "../../components/request-form/Header";
import {Applicant} from "../../components/request-form/applicant/Applicant";
import {CoApplicant} from "../../components/request-form/co-applicant/CoApplicant";
import {DocumentLoad} from "../../components/request-form/documents/DocumentLoad";
import {CompanyAgreement} from "../../components/request-form/company-agreement/CompanyAgreement";
import {withRouter} from "react-router-dom";
import Link from "react-router-dom/es/Link";
import {Property} from "./property/property";
import {PersonalDataOne} from "./personal-data/PersonalDataOne";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Form extends Component {
    constructor(props) {
        super(props);
        let initialCoApplicantId = this.lastCoApplicantId();
        let actualCoApplicantPersonalDataChecked = false;
        let actualCoApplicantJobDataChecked = false;
        if (Object.keys(this.props.coApplicantsDataChecked).length !== 0) {
            actualCoApplicantPersonalDataChecked = this.props.coApplicantsDataChecked[initialCoApplicantId]['personalDataChecked'];
            actualCoApplicantJobDataChecked = this.props.coApplicantsDataChecked[initialCoApplicantId]['jobDataChecked'];
        }

        this.state = {
            actualStage: 'Applicant',
            applicantEmailConfirmationSent: false,
            applicantEmailConfirmationAddress: undefined,
            showEmailConfirmationWarning: true,
            actualCoApplicantId: initialCoApplicantId,
            mobileMenuIsOpen: false,
            coApplicantsDataChecked: this.props['coApplicantsDataChecked'],
            actualCoApplicantPersonalDataChecked: actualCoApplicantPersonalDataChecked,
            actualCoApplicantJobDataChecked: actualCoApplicantJobDataChecked,
        };

        this.changeStage = this.changeStage.bind(this);
        this.changeCoApplicant = this.changeCoApplicant.bind(this);
        this.openMobileMenu = this.openMobileMenu.bind(this);
        this.closeMobileMenu = this.closeMobileMenu.bind(this);
        this.handleApplicantEmailChange = this.handleApplicantEmailChange.bind(this);
        this.applicantEmailConfirmation = this.applicantEmailConfirmation.bind(this);
        this.closeConfirmationEmailWarning = this.closeConfirmationEmailWarning.bind(this);
    };

    handleApplicantEmailChange(newEmailAddress){
        this.setState({
            applicantEmailConfirmationSent: newEmailAddress === this.state.applicantEmailConfirmationAddress })
    }

    changeStage(newStage) {
        this.setState({actualStage: newStage});

        if (newStage !== 'CoApplicant') {
            this.closeMobileMenu();
        }
    }

    applicantEmailConfirmation(){
        this.props.emailConfirmation('applicants', this.actualApplicantId()).then(response => {
            if (response.result.estado_email === PersonalDataOne.CONFIRMATION_REQUEST_SENT){
                this.setState({
                    applicantEmailConfirmationSent: true,
                    applicantEmailConfirmationAddress: response.result.email,
                })
            }
        })
    }

    changeCoApplicant(coApplicantId) {
        let actualCoApplicantPersonalDataChecked = false;
        let actualCoApplicantJobDataChecked = false;
        if (Object.keys(this.props.coApplicantsDataChecked).length !== 0 && coApplicantId in this.state.coApplicantsDataChecked) {
            actualCoApplicantPersonalDataChecked = this.state.coApplicantsDataChecked[coApplicantId]['personalDataChecked'];
            actualCoApplicantJobDataChecked = this.state.coApplicantsDataChecked[coApplicantId]['jobDataChecked'];
        }
        this.setState({
            actualCoApplicantId: coApplicantId,
            actualStage: 'CoApplicant',
            actualCoApplicantPersonalDataChecked: actualCoApplicantPersonalDataChecked,
            actualCoApplicantJobDataChecked: actualCoApplicantJobDataChecked
        });
        this.closeMobileMenu();
    }

    openMobileMenu() {
        this.setState({mobileMenuIsOpen: true});
    }

    closeMobileMenu() {
        this.setState({mobileMenuIsOpen: false});
    }

    lastCoApplicantId() {
        const coApplicantsQuantity = Object.keys(this.props.coApplicants).length;
        return Object.keys(this.props.coApplicants)[coApplicantsQuantity - 1];
    }

    actualCoApplicantId() {
        return this.state.actualCoApplicantId || this.lastCoApplicantId();
    }

    renderStage() {
        switch (this.state.actualStage) {
            case "Applicant":
                const actualApplicantId = this.actualApplicantId();
                return <Applicant isMobile={this.props.isMobile}
                                  participantData={this.props.applicants[actualApplicantId].participantData}
                                  actualParticipantId={actualApplicantId}
                                  updateData={this.props.updateParticipantData}
                                  personalDataChecked={this.props.applicantPersonalDataChecked}
                                  jobDataChecked={this.props.applicantJobDataChecked}
                                  onEmailChangeDo={this.handleApplicantEmailChange}
                />;
            case "CoApplicant":
                return <CoApplicant isMobile={this.props.isMobile}
                                    coApplicants={this.props.coApplicants}
                                    actualParticipantId={this.actualCoApplicantId()}
                                    updateData={this.props.updateParticipantData}
                                    personalDataChecked={this.state.actualCoApplicantPersonalDataChecked}
                                    jobDataChecked={this.state.actualCoApplicantJobDataChecked}
                                    emailConfirmation={this.props.emailConfirmation}
                />;
            case "DocumentLoad":
                return <DocumentLoad isMobile={this.props.isMobile}
                                     applicants={this.props.applicants}
                                     coApplicants={this.props.coApplicants}
                                     updateData={this.props.updateParticipantData}/>;
            case "Property":
                return <Property isMobile={this.props.isMobile}
                                 property={this.props.property.data}
                                 realEstate={this.props.realEstate}
                                 updateData={this.props.updatePropertyData}/>;
            case "CompanyAgreement":
                return <CompanyAgreement isMobile={this.props.isMobile}
                                         companyAgreement={this.props.companyAgreement}
                                         options={this.props.companyAgreements}
                                         updateData={this.props.updateCompanyAgreementData}/>;
            default:
                return null;
        }
    }

    renderConfirmationEmailWarning() {
        if (this.applicantHasNotEmailConfirmed()) {
            return (
                <div className={"mail-confirmation-warning-container"}>
                    {this.emailConfirmationAlert()}
                    <button onClick={this.closeConfirmationEmailWarning}>
                        <FontAwesomeIcon className="icon" icon="times"/>
                    </button>
                </div>
            )
        }
    }

    closeConfirmationEmailWarning() {
        this.setState({showEmailConfirmationWarning: false})
    }

    emailConfirmationAlert(){
        if (!this.state.applicantEmailConfirmationSent){
            return <p className={"base-text"}> {"Debes confirmar tu email antes de continuar. "}
                <Link className={"link"} to={"#"} onClick={this.applicantEmailConfirmation}>¡Hace click aca!</Link>
                {" Si ya lo has confirmado, por favor ignora este mensaje, " +
                "pronto se actualizará la información."}
            </p>
        }
        else {
            return <p className={"base-text"}>
                {`Te hemos enviado un email a ${this.state.applicantEmailConfirmationAddress},`+
                " por favor has click en el link de confirmación. Si ya lo has hecho, por favor ignora este mensaje, " +
                "pronto se actualizará la información."}
            </p>
        }
    }

    applicantHasNotEmailConfirmed() {
        const actualApplicantId = this.actualApplicantId();
        let participantData = this.props.applicants[actualApplicantId].participantData;
        let stateEmail = participantData['stateEmail'].value();
        return stateEmail['estado'] !== PersonalDataOne.EMAIL_CONFIRMED;
    }

    actualApplicantId() {
        return Object.keys(this.props.applicants)[0];
    }

    renderFormBody() {
        let showEmailConfirmationWarning = this.state.showEmailConfirmationWarning
        if (this.props.isMobile) {
            return (
                <div className="form-body-container">
                    {this.state.mobileMenuIsOpen ?
                        <div className="overlay-form-menu -open-form-menu" onClick={this.closeMobileMenu}/> : null
                    }
                    <div className="form-body">
                        <Header saving={this.props.saving}/>
                        {this.renderStage()}
                        {showEmailConfirmationWarning ?
                            this.renderConfirmationEmailWarning() : null
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="form-body">
                    <Header saving={this.props.saving}/>
                    {this.renderStage()}
                    {showEmailConfirmationWarning ?
                        this.renderConfirmationEmailWarning() : null
                    }
                </div>
            )
        }
    }

    render() {
        return (
            <div className={this.props.isMobile ? "layout-request form -mobile" : "layout-request form"}>
                <RequestNav openMobileMenu={this.openMobileMenu}
                            isMobile={this.props.isMobile}/>
                <main>
                    <FormNav active={this.state.actualStage}
                             mobileMenuIsOpen={this.state.mobileMenuIsOpen}
                             changeStage={this.changeStage}
                             actualCoApplicantId={this.actualCoApplicantId()}
                             changeCoApplicant={this.changeCoApplicant}
                             closeMobileMenu={this.closeMobileMenu}
                             applicationNumber={this.props.applicationNumber}
                             requestOffice={this.props.requestOffice}
                             {...this.props}/>
                    {this.renderFormBody()}
                </main>
            </div>
        );
    }
}

export default withRouter(Form);