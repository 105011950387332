import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "react-router-dom/es/Link";
import {Preloader} from "react-materialize";

export class Header extends Component {
    renderSaveStatus() {
        if (this.props.saving) {
            return (
                <div className="spinner-save-content">
                    <p>Guardando</p>
                    <Preloader size="small" color="blue" className="spinner-save"/>
                </div>
            )
        } else {
            return (
                <div className="spinner-saved">
                    <p>Guardado</p>

                    <FontAwesomeIcon className="icon" icon="check"/>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="form-status-container">
                <Link to="/dashboard" className="back-btn">Volver al menú inicial</Link>
                {this.renderSaveStatus()}
            </div>
        )
    }
}