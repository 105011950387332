import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class CloseButton extends React.Component {
    render() {
        return (
            <button className="modal-close" onClick={this.props.onClick}>
                <FontAwesomeIcon className="icon" icon="times"/>
            </button>
        );
    }
}
