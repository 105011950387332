import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import logo from '../../images/logo-finaer-alt-grey.svg';
import {ContactModal} from "../../components/modals/ContactModal";
import Link from "react-router-dom/Link";
import {LegalFooter} from "./LegalFooter";

export class Footer extends React.Component {

    render() {
        return (
            <footer className="main-footer-extended">
                <div className='main-footer'>
                    <div className="company-info">
                        <img src={logo} className="app-logo" alt="logo"/>
                        <div className="info">
                            <p><span>Casa Central:</span> - Av. Corrientes 3360 - 6to Piso</p>
                            <a href="tel:+541168425100">6842-5100</a> | <a
                            href="mailto:info@finaersa.com.ar">info@finaersa.com.ar</a>
                        </div>
                        <div className="data-fiscal">
                            <a href="http://qr.afip.gob.ar/?qr=lOG7cKcVoOSXi_fjMR3xJg,," target="_F960AFIPInfo">
                                <img className="img-data-fiscal" src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                                     border="0" alt={""}/>
                            </a>
                            <a href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/30715287494--RL-2021-16186848-APN-DNPDP#AAIP"
                               target="_blank" rel="noopener noreferrer">
                                <img className="img-data-tad"
                                     src="https://www.argentina.gob.ar/sites/default/files/aaip-isologo.png"
                                     alt="AAIP RNBD"/>
                            </a>
                        </div>
                    </div>

                    <ul className="footer-options">
                        <li><a href="/garantia">Sobre Nosotros</a></li>
                        <li>
                            <ContactModal button="¿Dudas? Consultanos"/>
                        </li>
                        <li><Link className="site-logo" to="/privacidad"> Privacidad </Link></li>
                        <li><Link className="site-logo" to="/terminos"> Términos y condiciones </Link></li>
                    </ul>
                    <div className="contact-links">
                        <p><span>Seguinos en:</span></p>
                        <a href="https://www.facebook.com/FinaerSa/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="icon" icon={["fab", "facebook-f"]}/>
                        </a>
                        <a href="https://www.instagram.com/garantias_finaer/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="icon" icon={["fab", "instagram"]}/>
                        </a>
                        <a href="https://www.linkedin.com/in/finaer" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="icon" icon={["fab", "linkedin-in"]}/>
                        </a>
                    </div>
                </div>
                <LegalFooter/>


            </footer>
        );
    }
}
