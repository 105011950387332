class Manager {
    static attributeList(objects, attribute) {
        let list = [];
        for (let object of objects) {
            list.push(object[attribute]);
        }

        return list;
    }
}

export class RealEstateManager extends Manager{
    static getById(realEstate, id) {
        return realEstate.filter(realEstateItem => {
            return realEstateItem.id() === id;
        })[0];
    }

    static get(realEstate, realEstateName) {
        return realEstate.filter(realEstateItem => {
            return realEstateItem.name() === realEstateName;
        })[0];
    }

    static includes(realEstate, realEstateName) {
        return realEstate.some(realEstateItem => {
            return realEstateItem.name().toLowerCase().includes(realEstateName.toLowerCase());
        });
    }
}

export class ProvinceManager extends Manager {
    static get(provinces, provinceName) {
        return provinces.filter(province => {
            return province.name() === provinceName;
        })[0];
    }

    static getByCode(provinces, code) {
        return provinces.filter(province => {
            return province.code() === code;
        })[0];
    }
}

export class CityManager extends Manager {
    static get(cities, cityName) {
        return cities.filter(city => {
            return city.name() === cityName;
        })[0];
    }
}