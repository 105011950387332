import React, {Component} from "react";
import {RightButton} from "../../components/buttons/RightButton";
import {LeftButton} from "../../components/buttons/LeftButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Wizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actualStep: 1,
        };

        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
    };


    handleNext() {
        const actualStep = this.state.actualStep;
        this.setState({actualStep: actualStep + 1});
    }

    handlePrevious() {
        const actualStep = this.state.actualStep;
        this.setState({actualStep: actualStep - 1});
    }

    renderLeftButton() {
        if (this.state.actualStep > 1) {
            return <LeftButton onClick={this.handlePrevious}/>;
        }
    }

    renderRightButton() {
        if (this.state.actualStep < this.props.children.length) {
            return <RightButton onClick={this.handleNext}/>;
        }
    }

    renderContent() {
        return this.props.children[this.state.actualStep - 1];
    }

    renderIndicators() {
        let indicators = [];
        for (let step = 1; step <= this.props.children.length; step++) {
            const isActive = this.state.actualStep === step;
            indicators.push(<FontAwesomeIcon key={step} className={isActive ? "circle -active" : "circle"} icon="circle"/>);
        }

        return indicators;
    }

    render() {
        return (
            <div>
                {this.renderLeftButton()}
                {this.renderContent()}
                {this.renderRightButton()}
                <div className="slider-nav">
                    {this.renderIndicators()}
                </div>
            </div>
        )
    }
}