import React from "react";
import {Modal} from "react-materialize";
import {CloseButton} from "../buttons/CloseButton";

export class PaymentErrorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isModalOpen: true };
    }

    _onClose = () => {
        this.setState({isModalOpen: false}, () => {this.props.onClose()});
    }

    _header() {
        return (
            <div className="payment-error-header">
                <h2>Atención</h2>
                <h1>Pago no realizado</h1>
            </div>
        );
    }

    render() {
        return (
            <Modal open={this.state.isModalOpen} className="modal-payment-error"
                   modalOptions={{complete: this.props.onClose}}>
                <div className="modal-body">
                    <div className="modal-main modal-payment-error-body">
                        {this._header()}
                        <p>
                            {this.props.message}
                        </p>
                        <CloseButton onClick={this.props.onClose}/>
                        <button className="main-btn btn-back" onClick={this._onClose}>
                            Volver
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}