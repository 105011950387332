import React from "react";
import PaymentBase from "../PaymentBase";
import {LeftButton} from "../../buttons/LeftButton";

export default class SelectPaymentTransferStepThree extends PaymentBase{

    constructor(props) {
        super(props);

        this.name = 'transferencia'
    }

    title() {
        return <h1>Pago por tu transferencia</h1>
    }

    body() {
        return (
            <div className="middle-bar-content payment-bar transfer-payment">
                {this._amountToPay()}
                {this._onePaymentDiscount( this.props.warrantyInformation.transferDiscount)}
            </div>
        );
    }

    footer() {
        return (
            <div className="arrows-payment">
                <LeftButton onClick={this.props.handlePrevious}/>
                {this._confirmPaymentBtn(this.props.createPaymentLead)}
            </div>
        );
    }
}