import React, {Component} from 'react';

export default class SuccessMessage extends Component {
    render() {
        return (
            <div className="pre-request-result">
                <p className="secondary-title">En base a los datos suministrados<br/>¡podés acceder a tu PRE-APROBACIÓN!</p>
                <p className="basic-text">¿Qué te falta?</p>
                <p className="basic-text">¡Muy poco! Hacé click en el botón de abajo para cargar ya mismo el resto de
                    tus datos y documentación para que tu solicitud avance con mayor rapidez.</p>
                <button className="main-btn" onClick={this.props.handleRedirect}>Avanzá tu Solicitud</button>
            </div>

        );
    }
}
