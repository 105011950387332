import React from 'react';
import Link from "react-router-dom/es/Link";

export class MainButton extends React.Component {
    render() {
        return (
            <Link
                className={'main-btn ' + this.props.class}
                to={{pathname: this.props.to, search: this.props.search}}>{this.props.text}</Link>
        );
    }

}
