import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faUmbrella,
    faMobileAlt,
    faCalculator,
    faUser,
    faBars,
    faKey,
    faShieldAlt,
    faMapMarkerAlt,
    faEnvelope,
    faCheck,
    faClipboardList,
    faClipboardCheck,
    faClock,
    faTimes,
    faInfoCircle,
    faMapPin,
    faArrowLeft,
    faArrowRight,
    faSignOutAlt,
    faEye,
    faEdit,
    faCaretRight,
    faCircle,
    faChevronDown,
    faChevronRight,
    faExclamationTriangle,
    faHandPaper,
    faCalendar,
    faMoneyBill,
    faCopy,
    faCreditCard,
    faWallet
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

export function initIconsLibrary() {
    library.add(
        faUmbrella,
        faMobileAlt,
        faCalculator,
        faUser,
        faBars,
        faFacebookF,
        faInstagram,
        faWhatsapp,
        faLinkedinIn,
        faWhatsapp,
        faKey,
        faShieldAlt,
        faMapMarkerAlt,
        faEnvelope,
        faCheck,
        faClipboardList,
        faClipboardCheck,
        faClock,
        faTimes,
        faInfoCircle,
        faMapPin,
        faArrowLeft,
        faArrowRight,
        faSignOutAlt,
        faEye,
        faEdit,
        faCaretRight,
        faCircle,
        faChevronDown,
        faChevronRight,
        faExclamationTriangle,
        faHandPaper,
        faCalendar,
        faMoneyBill,
        faCopy,
        faCreditCard,
        faWallet
    );
}