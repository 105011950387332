import React, {Component} from "react";
import Select from 'react-select'
import PropTypes from 'prop-types';
import {SectionAside} from "./SectionAside";

export class FinaerSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: this.props.selectedOption
        };

        this.optionFor = this.optionFor.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
    }

    options() {
        return this.props.options
    }

    optionFor(value) {
        if (value !== undefined && value !== null) {
            return this.options().find(option => {
                return option.value === value
            });
        }

        return null
    }

    handleOnChange(selectedOption) {
        this.props.onChange({
            target: {value: selectedOption.value}
        })
    }

    propsExcept(excludedProps) {
        let props = {};

        for (let propName in this.props) {
            if (!excludedProps.includes(propName)) {
                props[propName] = this.props[propName];
            }
        }

        return props
    }

    isChecked() {
        return this.props.checked;
    }

    render() {
        return (
            <Select
                defaultValue={this.optionFor(this.state.selectedOption)}
                onChange={this.handleOnChange}
                placeholder="Seleccionar..."
                options={this.options()}
                isDisabled={this.props.isDisabled || this.isChecked()}
                {...this.propsExcept(['defaultValue', 'onChange', 'options'])}
            />
        )
    }
}

SectionAside.propTypes = {
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    selectedOption: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.any, label: PropTypes.string})),
    onChange: PropTypes.func
}