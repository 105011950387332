import IdNumberValidator from "./IdNumberValidator";

export default class ErrorMessageCreator {

    static errorMessageIfInvalidEmail(input) {
        if (input.checkValidity()) {
            return ''
        }
        return 'El email ingresado es inválido.';
    }

    static errorMessageIfIdNumberIsInvalid(idNumber, idType) {
        let validator = IdNumberValidator.NewFor(idType,idNumber);
        return validator.validate();
    }

    static errorMessageSimpleField(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }
}