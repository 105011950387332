import React from 'react';

export class FormNavInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {officeChange: false};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.requestOffice !== this.props.requestOffice){
            this.setState( {officeChange: true} );
            setTimeout( () => this.setState({officeChange: false}), 2000)
        }
    }

    render() {
        return (
            <div className="form-nav-info">
                <div className="request-code">
                    <p>Código de solicitud:</p>
                    <p className="code">{this.props.applicationNumber}</p>
                </div>
                <div className={this.state.officeChange ? "assigned-office-info -highlight-info" : "assigned-office-info"}>
                    <p className="info-title">{this.props.requestOffice['name']}</p>
                    <p>{this.props.requestOffice['address']}</p>
                    <a href={"tel:" + this.props.requestOffice['phone']}>
                        {this.props.requestOffice['phone']}
                    </a>
                    <a href={"mailto:" + this.props.requestOffice['email']}>
                        {this.props.requestOffice['email']}
                    </a>
                </div>
            </div>
        );
    }
}
