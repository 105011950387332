/*
* Wraps National Identity Document option sent by Mercadopago api
* */
class NIDMercadopagoCard {
    constructor(nidData) {
        this._id = nidData.id;
        this._name = nidData.name;
        this._minLength = nidData.min_length;
        this._maxLength = nidData.max_length;
        this._type = nidData.type;
    }

    respondsTo(name) {
        return this._name === name;
    }

    isNIDNumberValid(ndiNumber) {
        let isLengthValid = (ndiNumber.length >= this._minLength) && (ndiNumber.length <= this._maxLength);
        if (this._type === 'number'){
            return isLengthValid && !isNaN(ndiNumber);
        }
        return isLengthValid;
    }

    name() {
        return this._name;
    }

    type() {
        return this._name;
    }

    id() {
        return this._id;
    }
}

export class NIDMercadopagoOptions {
    constructor(nidOptionsData) {
        this.nidOptions = nidOptionsData.map((nidData) => new NIDMercadopagoCard(nidData));
    }

    renderToSelect() {
        return this.nidOptions.map((option) => {
            return {label: option.name(), value: option.name()}
        })
    }

    getByType(type) {
        return this.nidOptions.find(option => option.respondsTo(type));
    }

    first() {
        return this.nidOptions[0];
    }
}