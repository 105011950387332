import React, {Component} from "react";
import Link from "react-router-dom/es/Link";
import PropTypes from 'prop-types';

export default class EmailConfirmationBase extends Component {

    render () {
        return (
            <div className="layout-request begin">
                <div className="mail-container">
                    <Link className="site-logo" to="/">
                        <img src={this.headerImageUrl()} style={{width: 600, margin: 8}} alt="logo"/>
                    </Link>
                    <div style={{marginLeft: 50, marginRight: 50}}>
                        <div>
                            <p style={this.primaryTextStyle()}>
                                <strong style={{color: '#e30613'}}>{this.props.firstText}</strong>
                                <br/>
                                <strong>{this.props.secondText}</strong>
                            </p>
                            <p style={this.secondaryTextStyle()}>
                                <br/>
                                {this.props.thirdText}
                                <br/><br/>
                                <strong style={{fontWeight: 'bold'}}>{this.props.forthText}</strong>
                            </p>
                            <br/>
                        </div>
                    </div>
                    <img src={this.footerImageUrl()}
                         alt='footer'/>
                </div>
            </div>
        )
    }

    propTypes = {
        firstText: PropTypes.string,
        secondText: PropTypes.string,
        thirdText: PropTypes.string,
        forthText: PropTypes.string,
    }

    headerImageUrl() {
        return "http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_verificacion/img/cabezal.landing.jpg"
    }

    footerImageUrl(){
        return "http://www.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_verificacion/img/pie.jpg"
    }

    primaryTextStyle() {
        return {
            textAlign: 'left',
            color: '#2f3743',
            fontSize: 18,
            fontWeight: 'normal',
            lineHeight: 1.8
        };
    }

    secondaryTextStyle() {
        return {
            textAlign: 'justify',
            color: '#4A4A49',
            fontSize: 15,
            lineHeight: 1.8
        };
    }

}
