export class Province {
    constructor(code, name) {
        this._name = name;
        this._code = code;
        this._cities = [];
    }

    code() {
        return this._code;
    }

    name() {
        return this._name;
    }

    cities() {
        return this._cities;
    }

    addCity(city) {
        this._cities.push(city);
    }
}