import React from 'react';
import ReactDOM from 'react-dom';
import 'react-circular-progressbar/dist/styles.css';
import './index.css';
import App from './app/Routes';
import {initIconsLibrary} from './lib/initIconsLibrary';
import {initGoogleTagManager} from "./lib/googleTagManager";
import {initFacebookPixel} from "./lib/facebookPixel";

initIconsLibrary();
initGoogleTagManager();
initFacebookPixel();

ReactDOM.render(<App />, document.getElementById('root'));