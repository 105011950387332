import React, {Component} from 'react';
import MainNav from '../components/layout/MainNav';
import {Footer} from "../components/layout/Footer";
import Link from "react-router-dom/Link";

class TermsAndConditions extends Component {
    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="terminos">
                            <header>
                                <h1 className="highlight">TÉRMINOS Y CONDICIONES DEL SERVICIO DE GARANTÍA DE FIANZA</h1>
                            </header>
                            <p className="basic-text non-copy-text">
                                Los presentes términos y condiciones (en adelante los “Términos y Condiciones”) detallan el alcance, limitaciones y beneficios que otorga el Servicio de Garantía de Fianza (en adelante el/los “Servicio/s”) brindados por “SISTEMA FINAER S.A.”, CUIT: 30-71528749-4 (en adelante denominado el “Prestador”) en el territorio de Argentina (el “Territorio”).
                            </p>
                            <p className="basic-text non-copy-text">
                                En tal sentido, los Términos y Condiciones regirán la relación entre el Prestador y el Usuario que contrate los Servicios (en adelante el “SOLICITANTE” o “LOCATARIO” de forma indistinta).
                            </p>

                            <h2 className="non-copy-text">1. Modo de acceso a los Servicios</h2>

                            <p className="basic-text non-copy-text">
                                1.1. El Solicitante deberá acceder a los Servicios a través de la página web del Prestador <Link to="/">https://finaersa.com.ar</Link> para lo cual deberá completar una etapa de registración con determinados datos: identidad; laborales; inmobiliaria interviniente si la hubiera, zona geográfica en la que se pretende alquilar el inmueble, contar por qué medio conoció a la compañía, con más la aceptación de los Términos y Condiciones.
                            </p>
                            <h2 className="non-copy-text">2. Términos del Contrato de Fianza</h2>

                            <p className="basic-text non-copy-text">
                                2.1. Con base en los siguientes términos, al celebrarse el contrato de fianza ofrecido mediante los SERVICIOS de “SISTEMA FINAER S.A.”, el Prestador se constituirá frente al LOCADOR en Fiador subsidiario del LOCATARIO, por las obligaciones que le resultan exigibles este, en virtud del Contrato de Locación suscripto. Esta Fianza tendrá los alcances, detalles y limitaciones que se establecen en los Términos y Condiciones.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.2. Se entiende por <b>CONTRATO FIANZA</b> al acuerdo viculante por el cual el Prestador se obliga accesoria y subsidiariamente a cumplir con las OBLIGACIONES que que resulten exigibles al LOCATARIO, siempre que la parte LOCADORA aceptase los límites taxativamente previstos en dicho instrumento.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.3. Se entiende por <b>OBLIGACIONES</b>, (i) El pago mensual de los alquileres que se adeudasen, y que fueran oportunamente pactados en el Contrato de Locación y los ajustes resultantes de los índices previstos por la normativa vigente; (ii) El pago mensual de intereses punitorios que el Locatario adeudase por las obligaciones derivadas de la falta de pago en término del canon mensual de alquiler, hasta el 0,5 % diario, no capitalizable, siendo ello el tope máximo mensual; (iii) El pago de las expensas ordinarias mensuales desde la celebración del contrato de Locación hasta la restitución del inmueble Locado, y los intereses punitorios por pago fuera de plazo con el tope máximo indicado en el apartado (ii) precedente; (iv) El pago de la Tasa-ABL(v) El pago del Servicio de Agua Corriente, siempre que tales obligaciones hubieren sido asumidas por el Locatario en el contrato de locación. Todo lo enumerado, según los límites de plazos, términos y apercibimientos determinados en el contrato de fianza celebrado.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.4. Se entiende por <b>CONCEPTOS EXCLUIDOS DEL CONTRATO DE FIANZA</b> a aquellas obligaciones que escapan al contrato de fianza y que permanecen exclusivamente como obligaciones a cargo del LOCATARIO sin excepción. Las mismas se encuentran detalladas en el punto 3.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.5. Se entiende por <b>IMPORTE</b> a la suma que el Solicitante pagará a “SISTEMA FINAER S.A.” en ocasión de su prestación de servicio, derivada de su carácter de fiador conforme se detalla en el punto 7.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.6. Se entiende por <b>CONTRATO DE LOCACIÓN</b>  VÁLIDO a aquellos contratos de locación que sean celebrados dentro del plazo máximo de 90 días corridos de firmado el contrato de fianza. Asimismo, será requisito esencial que dicho contrato de locación contenga la cláusula especial de fianza que allí se detalla.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.7. Se entiende por <b>NOTIFICACIONES</b>, a las comunicaciones obligatorias y fehacientes que las partes deben mantener en los supuestos determinados en el contrato de fianza, de forma obligatoria, cuyo incumplimiento resultará en la caducidad de las obligaciones del Prestador.
                            </p>

                            <p className="basic-text non-copy-text">
                                2.8. Se entiende por <b>VIGENCIA</b>, al tiempo en el que el contrato de fianza permanecerá en vigor, el cual abarca desde la firma del contrato de locación hasta la efectiva restitución del inmueble al LOCADOR, de acuerdo a las reglas que se establecen en el punto X.
                            </p>

                            <h2 className="non-copy-text">3. Situaciones que no se encuentran incluidas en el Servicio.</h2>

                            <p className="basic-text non-copy-text">
                                Quedan exceptuadas del Servicio:
                            </p>

                            <p className="basic-text non-copy-text">
                                3.1. Los excedentes de los montos que como topes máximos mensuales se describen en los apartados (ii) y (iii) del punto 2.3.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.2. Las cláusulas penales por cualquier concepto que se hubieren establecido en el contrato de locación.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.3. Las multas y sus intereses contenidas en el contrato de locación.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.4. Las expensas de carácter extraordinaria y sus intereses de cualquier naturaleza con independencia de quién las hubiere asumido en el contrato de locación.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.5.  Los daños y/o perjuicios y/o reparaciones que puedan provocar los hechos o la conducta del Locatario, sean respecto del inmueble locado o de terceros y/o sus bienes.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.6. Ningún cargo, sanción y/o multa, sean legales o convenidas, derivadas de la rescisión anticipada del contrato de locación; se trate de preaviso de ley; meses de depósito; falta de reintegro del inmueble en el plazo concertado; y/o insertas dentro de las expensas ordinarias.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.7. Los impuestos que pesen sobre el inmueble sean nacionales o provinciales; tasas de servicios; contribuciones de cualquier naturaleza; gastos y/o precios de los servicios (ya se trate de servicio de Gas Natural; Energía Eléctrica; Telefonía; etc.;) sea que ellos existan al momento en que se celebre el contrato; o que la obligación nazca durante el curso del contrato y/o que resulten ulteriores a su vencimiento.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.8. Ningún deterioro y/o daño en la finca imputable a los sujetos contractuales y/o a cualquier otro. Tampoco los causados por caso fortuito; por fuerza mayor; por el desgaste natural del uso y goce; por accionar de terceros. Tampoco los deterioros provenientes del mero transcurso del tiempo y el uso regular, conforme lo previsto en el artículo 1210 del C.C.C.N.
                            </p>

                            <p className="basic-text non-copy-text">
                                3.9.  cláusulas penales contractuales.
                            </p>

                            <h2 className="non-copy-text">4. Obligaciones Del Solicitante</h2>

                            <p className="basic-text non-copy-text">
                                4.1. A modo de solicitar el Servicio, el Solicitante deberá ingresar a la página web <Link to="/">https://finaersa.com.ar</Link> y proceder a cargar los siguientes datos: Nombre; Apellido; Tipo y N° de DNI; Género; fecha de nacimiento; domicilio particular- Calle, N°; Piso; Departamento; Localidad/Ciudad; Provincia- número de teléfono fijo/celular y alternativos; dirección de correo electrónico y alternativo; ocupación, antigüedad laboral; ingreso neto; Datos del empleador y CUIT.
                            </p>

                            <p className="basic-text non-copy-text">
                                4.2. La contratación no se perfeccionará hasta tanto se encuentre firmado el contrato de fianza por todas las partes intervinientes.
                            </p>

                            <h2 className="non-copy-text">5. Procedimientos de Notificación de falta de pago</h2>

                            <p className="basic-text non-copy-text">
                                5.1. En caso de que un Solicitante se demorase en el pago de cualquier obligación pecuniaria inherente a la garantía, el LOCADOR deberá notificar fehacientemente al Prestador de tal situación, so pena de quedar extinta la presente fianza ante cualquier comunicación extemporánea, liberando totalmente al fiador de aquella obligación.
                            </p>

                            <p className="basic-text non-copy-text">
                                5.2. El LOCADOR deberá notificar fehacientemente la mora del Locatario a la fiadora “SISTEMA FINAER S.A.”, dentro de los <b>30 días corridos de acontecido cada vencimiento de pago respecto del Alquiler y Expensas Ordinarias</b> (quien responderá a correspondientes punitorios hasta el 0,5% diario no capitalizable), so pena de caducidad ante cada período reclamado extemporáneamente.
                            </p>

                            <p className="basic-text non-copy-text">
                                5.3. El LOCADOR deberá notificar fehacientemente la mora del Locatario a la fiadora “SISTEMA FINAER S.A.”, dentro de los <b>60 días corridos</b> de acontecido cada vencimiento de pago respecto de los restantes rubros objeto el servicio de fianza (<b>Servicio de Agua Corriente y Tasa-ABL</b>), so pena de caducidad ante cada período reclamado extemporáneamente.
                            </p>

                            <p className="basic-text non-copy-text">
                                5.4. Ante la falta de restitución de la propiedad por parte del LOCATARIO -en legal tiempo y forma- al vencimiento del plazo locativo pactado en el presente contrato y/o ante la situación de abandono del inmueble en cualquier momento y/o fallecimiento de sus ocupantes, el LOCADOR se obliga a notificar fehacientemente de aquellas contingencias al Prestador dentro del plazo de <b>30 días corridos</b> de haberse conocido el hecho, so pena de quedar totalmente extinta la presente fianza ante cualquier comunicación extemporánea, liberando al fiador de su carácter de tal.
                            </p>

                            <p className="basic-text non-copy-text">
                                5.5. Ante el incumplimiento contractual de la parte LOCATARIA sobre el pago de los alquileres y/o la no restitución del inmueble locado al vencimiento contractual, el LOCADOR se obliga a extender mandato a favor de los letrados asignados por el Prestador a efectos de promover las acciones legales correspondientes, en el plazo de <b>30 días corridos</b> de acontecidas las causales mencionadas, so pena de quedar totalmente extinta la presente fianza, liberando al fiador de su carácter de tal.
                            </p>

                            <h2 className="non-copy-text">6. Vigencia de los Servicios</h2>

                            <p className="basic-text non-copy-text">
                                6.1. Los Servicios se encontrarán vigentes desde el momento en que se firme un CONTRATO DE LOCACIÓN VALIDO, hasta la plena restitución del inmueble.
                            </p>

                            <h2 className="non-copy-text">7. Precio de los Servicios</h2>

                            <p className="basic-text non-copy-text">
                                7.1.  El Solicitante deberá abonar por la prestación del servicio un % que se determinará tomando como base de cálculo el valor de los alquileres consignados contractualmente; plazo locativo e importe de expensas iniciales si las hubiera. Con dichas variables se procederá a la multiplicación por el plazo contractual y de dicho coeficiente se tomará el 6% del total. En efecto, dicho 6% determinará el importe que deberá abonar el solicitante al “SISTEMA FINAER S.A.” por la prestación del servicio.
                            </p>

                            <p className="basic-text non-copy-text">
                                7.2. Se podrá abonar el servicio de la siguiente forma:
                            </p>

                            <p className="basic-text non-copy-text">
                                Al contado, mediante transferencia o depósito bancario; en cuotas con o sin tarjeta de crédito mediante la plataforma de mercadopago (según políticas vigentes de dicha plataforma y según el banco emisor con el recargo que el mismo aplique) a través de: VISA; Mastercard; American Express; Tarjeta Naranja; Tarjeta Shopping; Tarjeta Nativa; Tarjeta Cencosud; Tarjeta CABAL; Tarjeta Argencard; Tarjeta Diners Club; Tarjeta Cordobesa; Tarjeta  CMR Falabella; Tarjeta  Banco Patagonia; Tarjeta Walmart; Tarjeta VISA Débito; Tarjeta Maestro; Tarjeta Mastercard Débito; Tarjeta CABAL.
                            </p>

                            <p className="basic-text non-copy-text">
                                7.3. En caso de que el Solicitante no abonare íntegramente, en tiempo y forma, alguna alícuota derivada del financiamiento por el precio del servicio prestado por SISTEMA FINAER S.A., se adicionará a su deuda intereses correspondientes a la tasa activa mensual a 30 días del BCRA y eventualmente se promoverá acción de ejecución judicial por las sumas pendientes de pago. La mora por parte del usuario en el pago del servicio prestado por SISTEMA FINAER S.A., no obstara la vigencia y alcances de la fianza frente al LOCADOR.
                            </p>

                            <h2 className="non-copy-text">8. Rescisión de los Servicios</h2>

                            <p className="basic-text non-copy-text">
                                8.1 En el supuesto caso que, el Solicitante decidiere revocar el servicio extendido por el prestador, a efectos de extinguir el vínculo de fianza y obtener el reintegro de las sumas pagadas (art. 34, ley 24.240 y art. 1.110 CCCN), tendrá que formalizar su pretensión a través del “Botón de Arrepentimiento” ubicado en la página de inicio del portal <Link to="/">https://finaersa.com.ar</Link>, en un plazo que no exceda los 10 días corridos de celebrado el Contrato de Garantía de Fianza, en los términos de la Resolución Administrativa N° 424/2020 de la Secretaría de  Comercio Interior, dependiente del Ministerio de Desarrollo Productivo.
                                Asimismo, el arrepentimiento tendrá lugar si:
                            </p>

                            <p className="basic-text non-copy-text">
                                Asimismo, el arrepentimiento tendrá lugar si:
                            </p>

                            <p className="basic-text non-copy-text">
                                A) No habiéndose celebrado aun el Contrato de Locación, el solicitante hace uso del “Botón de Arrepentimiento” dentro de los 10 (DIEZ) días corridos de celebrado el Contrato de Fianza. Acto seguido, “SISTEMA FINAER S.A.” notificará electrónicamente de aquella situación a quienes hubieren intervenido en las diligencias preliminares para concertar el futuro Contrato de Locación, con la finalidad de que todos tomen conocimiento que la fianza que oportunamente se había emitido, quedará sin efecto ante tal acontecimiento revocatorio del solicitante;
                            </p>

                            <p className="basic-text non-copy-text">
                                B) Habiéndose celebrado el Contrato de Locación, el solicitante hace uso del “Botón de Arrepentimiento” dentro de los 10 (DIEZ) días corridos de celebrado el Contrato de Fianza, por el hecho de querer dejar sin efecto su garantía contractual, en tanto previamente hubiera notificado ello en forma fehaciente al Locador, y éste haya aceptado expresamente tal desvinculación, comunicándoselo formalmente a “SISTEMA FINAER S.A.”.
                            </p>

                            <p className="basic-text non-copy-text">
                                C) Habiéndose celebrado el Contrato de Locación, el solicitante hace uso del “Botón de Arrepentimiento” dentro de los 10 (DIEZ) días corridos de celebrado el Contrato de Fianza, notificando también fehaciente y simultáneamente al Locador sobre la rescisión locativa anticipada, y obteniendo de éste su conformidad escrita  sobre la revocación del Contrato de Alquiler por mismo plazo, en tanto cumpla el Locatario previamente con todas sus obligaciones patrimoniales y extrapatrimoniales derivadas de una resolución contractual. En tal sentido es indispensable obtener la conformidad del Locador, sin conculcar ni abusar irregularmente del derecho de terceros, sin colocar en estado de indefensión a sus acreedores.
                            </p>

                            <p className="basic-text non-copy-text">
                            En caso de no cumplirse expresamente las hipótesis enunciadas, la revocación del servicio no será automática ni devengará reembolso de precio alguno, obligándose el solicitante también por las alícuotas pendientes de pago, a tales efectos. Asimismo, en tal supuesto continuarán indemnes las obligaciones asumidas por la fianza, en fiel cumplimiento de las estipulaciones convenidas contractualmente. Cumplidos los 10 (DIEZ) días corridos de celebrado el Contrato de Garantía de Fianza, el uso del “Botón de Arrepentimiento” no surtirá efecto alguno como medio de revocación del Contrato por el cual se obligaron las partes.
                            </p>

                            <p className="basic-text non-copy-text">
                            En todos los casos referenciados, el fiador “SISTEMA FINAER S.A.” validará en tiempo prudencial, la veracidad, los plazos y el cumplimiento de los requisitos esenciales requeridos a sus efectos.
                            </p>

                            <h2 className="non-copy-text">9. Información Personal</h2>

                            <p className="basic-text non-copy-text">
                            9.1. El Prestador tratara toda la información personal que reciba en virtud de los presentes Términos y Condiciones y de los Servicios conforme la Ley Nº 25.326 y toda otra normativa aplicable en materia de Protección de Datos Personales. Asimismo toda la información personal será almacenada en una base de datos a nombre de Sistema Finaer S.A. y utilizados en forma permitida por ley y para los fines descriptos en los presentes Términos y Condiciones. En tal sentido, el Prestador podrá utilizar y compartir la información personal por Ud. brindada en la registración y prestación de los Servicios a empresas, afiliadas y/o vinculadas contractualmente, las cuales podrán encontrarse localizadas en países sin legislación adecuada en materia de protección de datos personales, y a modo de poder otorgar y brindar los Servicios, así como realizar acciones de marketing y segmentación de perfiles.
                            No obstante, se informa que, para este último supuesto, se requerirá contractualmente que quienes reciban estos datos cumplan con los estándares de protección, seguridad y derechos en materia de Protección de Datos Personales conforme la normativa aplicable Argentina.
                            </p>

                            <p className="basic-text non-copy-text">
                            9.2. Se informa que la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, (“AAIP”) en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
                            </p>

                            <p className="basic-text non-copy-text">
                            9.3. Se informa que Usted en su carácter de titular de sus datos personales tendrá la posibilidad en todo momento de ejercer los derechos de acceso, rectificación y supresión de los datos conforme lo establece la Ley de Protección de Datos Personales Nº 25.326 y normativa aplicable en la materia.
                            </p>

                            <p className="basic-text non-copy-text">
                            9.4. La aceptación de estos Términos y Condiciones conlleva la aceptación y conformidad a la Política de Privacidad de SISTEMA FINAER S.A. que obra en: <Link to="/privacidad">https://finaersa.com.ar/privacidad</Link>
                            </p>

                            <h2 className="non-copy-text"> 10. Uso de la web de SISTEMA FINAER S.A.</h2>

                            <p className="basic-text non-copy-text">
                            10.1. El Prestador se reserva el derecho de modificar, estos Términos y Condiciones, la Política de Privacidad y/o la página web <Link to="/">https://finaersa.com.ar</Link> de forma unilateral, su diseño, la presentación o su configuración, los servicios ofrecidos, los requisitos de registro o de utilización de la página, sin que ello genere derecho a reclamo o indemnización alguna en favor de algún usuario y/o visitante de la web y/o Solicitante. Para ello el Prestador dará aviso al Solicitante a través del E-mail registrado, y/u otro medio de comunicación electrónica.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.2. El Prestador no será responsable por los contenidos, ofertas, privacidad y legalidad de los sitios de terceros que se encontraren vinculados a través del sitio web de SISTEMA FINAER S.A.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.3. El usuario y/o visitante de la web y/o el Solicitante con el uso de la presente web otorga al Prestador el permiso para la utilización de cualquier información, con el propósito que el Prestador la utilice para la obtención de información estadística que permita mejorar el Servicio y/o el presente sitio web, sin ningún derecho de compensación en favor del usuario y/o visitante y/o Solicitante.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.4. El usuario y/o visitante de la web y/o el Solicitante se obliga a usar el presente sitio web de conformidad con estos Términos y Condiciones, en forma diligente, correcta y lícita, y conforme con la moral y las buenas costumbres.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.5. El Prestador podrá suspender transitoriamente o finalizar la publicación del sitio web o cualquier parte de este sin aviso previo y en cualquier momento, sin que ello genere derecho a indemnización alguna en favor del usuario y/o visitante de la web y/o el Solicitante.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.6. El usuario y/o visitante de la web y/o el Solicitante reconoce y acepta que el uso de esta página es bajo su propio y exclusivo riesgo.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.7. El usuario y/o visitante de la web y/o el Solicitante reconoce y acepta que ni el Prestador, ni sus directores, empleados o representantes de cualquiera de ellos, serán responsable por daños que surjan de o resulten del uso de esta página, incluyendo cualquier error, omisión, interrupción, falla, eliminación de archivos o correos electrónicos (e-mails), defectos, virus, y/o demoras en la operación o transmisión y/o de cualquier otro tipo.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.8. El usuario y/o visitante de la web y/o el Solicitante reconoce que el uso del presente sitio web es a su entera responsabilidad, aceptando mediante el uso de esta página web que el Prestador no controla y de ningún modo garantiza la ausencia de virus en el contenido de cualquier sitio enlazado con el presente sitio web, ni la ausencia de otros elementos que pudieran producir alteraciones en sus sistemas informáticos o en los documentos electrónicos y archivos almacenados en los archivos informáticos del usuario y/o visitante de la web y/o el Solicitante.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.9. A través de la presente página se pone a su disposición dispositivos técnicos de enlace tales como, entre otros, links, banners, botones, directorios y herramientas de búsqueda que les permiten acceder a páginas web pertenecientes a terceros. Este tipo de enlaces son a efectos de facilitar a los usuarios y/o visitantes de la web y/o a los Solicitantes, la búsqueda y acceso, a la información disponible y no presupone que existe ninguna clase de vínculo o asociación entre el Prestador, sus subsidiarias o afiliadas y los operadores de los sitios enlazados.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.10. El Prestador se reserva el derecho de restringir la disponibilidad de la presente página web o de los Servicios a cualquier individuo, región geográfica o jurisdicción que pudiera designar en cualquier momento y a su entera discreción.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.11. El uso de la presente página web, serán de exclusiva responsabilidad del Usuario por lo que todo uso que pueda hacerse de los contenidos, imágenes, información y/o Servicios, se encontrará supeditado a la ley aplicable, así como a los principios de buena fe y uso lícito por parte del usuario y/o visitante de la web y/o el Solicitante, siendo éste enteramente responsable por el correcto uso.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.12. El usuario y/o visitante de la web y/o el Solicitante es el único responsable por cualquier información, dato, texto, fotografía, imagen, y/o cualquier otra publicación y/o comunicación realizada en la presente página web.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.13.  El usuario y/o visitante de la web y/o el Solicitante se compromete a cumplir con toda la normativa aplicable y es el único responsable por todos los actos y omisiones que sucedan o se generen en consecuencia y en relación con su cuenta, registro o contraseña.
                            </p>

                            <p className="basic-text non-copy-text">
                            10.14. El usuario y/o visitante de la web y/o el Solicitante tiene deberá abstenerse de realizar cualquier tipo de actividad en perjuicio del Prestador y/o de cualquier tercero. En tal sentido usuario y/o visitante de la web y/o el Solicitante deberá abstenerse de utilizar la presente página web con fines ilegales, contrarios a la buena fe y/o la moral, no autorizados o prohibidos.
                            </p>

                            <h2 className="non-copy-text">11. Ley y Jurisdicción</h2>

                            <p className="basic-text non-copy-text">
                                1.1. Será aplicable la Ley Argentina a los presentes Términos y Condiciones, y en tal sentido toda controversia judicial que se plantee en relación a los presentes Términos y Condiciones y/o respecto del Servicio, se sustanciará ante la justicia competente con asiento en la Ciudad Autónoma de Buenos Aires
                            </p>

                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default TermsAndConditions;