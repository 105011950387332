import React, {Component} from 'react';
import MainNav from '../components/layout/MainNav';
import {Footer} from "../components/layout/Footer";
import {RequirementsAside} from "../components/elements/RequirementsAside";
import RequirementsAccordion from "./RequirementsAccordion";

class Requisitos extends Component {
    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="requisitos">
                            <header>
                                <h1>Requisitos</h1>
                            </header>
                            <p className="basic-text">Para solicitar una Garantía Finaer se requieren al menos dos personas que puedan demostrar ingresos en Argentina, debiendo presentar la documentación requerida en función de su actividad laboral.</p>
                            <p className="basic-text">Es importante que la suma de los ingresos presentados triplique el monto del primer valor de Alquiler más Expensas.</p>
                            <p className="basic-text">Si el SOLICITANTE (inquilino) no posee ingresos demostrables, podrá reforzar su Solicitud con la presentación adicional de uno (1) o más Co-Solicitantes.</p>
                            <p className="basic-text"><span>Si la antigüedad laboral del Solicitante es mayor a 6 meses, y su ingreso duplica el monto
                                indicado, puede consultar en nuestras Oficinas Comerciales su precalificación para iniciar la
                                Solicitud a sola firma ¡Sin necesidad de Co-Solicitantes!</span></p>
                            <RequirementsAccordion/>
                        </section>
                        <RequirementsAside/>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default Requisitos;
