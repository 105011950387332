import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class SelectPaymentMethodBtn extends React.Component {
    render() {
        return (
            <button className={'main-btn select-payment-btn payment-btn-wrap'}
                    onClick={this.props.onFinishDo}>
                <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                <p>{this.props.text}</p>
            </button>
        )
    }
}