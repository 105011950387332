import React from "react";
import "../../styles/App.scss";
import {Button, Row} from "react-materialize";
import {HelperModal} from "../modals/HelperModal";
import {containsSubstringLike} from "../../lib/others";
import {RealEstateManager} from "../../models/managers";
import {LeftButton} from "../buttons/LeftButton";
import {FinaerSelect} from "../elements/Select";
import {FinaerAutocomplete} from "../elements/Autocomplete";
import {FinaerTypeAhead} from "../elements/AutocompleteTypeAhead";
import {Loader} from "../elements/Loader";
import CreateWarrantyLeadWizardStep from "./CreateWarrantyLeadWizardStep";
import Link from "react-router-dom/es/Link";
import WarrantyApplicationSourceParameterMapper from "../../models/WarrantyApplicationSourceParameterMapper";
import {registerGoogleTagManagerEvent} from "../../lib/googleTagManager";


export default class CreateWarrantyLeadWizardStepThree extends CreateWarrantyLeadWizardStep {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            realEstateSelectOptions: this.realEstateNames(props.realEstate),
            realEstateSelected: this.realEstateFrom(props.leadFields.realEstate),
            acceptTermsAndConditions: false,
        };

        this.nextButtonRef = React.createRef();
        this.handleCityAutocomplete = this.handleCityAutocomplete.bind(this);
        this.handleRealEstateChange = this.handleRealEstateChange.bind(this);
        this.handleChangeInAcceptTermsAndConditions = this.handleChangeInAcceptTermsAndConditions.bind(this);
        this.handleLeadConvertingResponse = this.handleLeadConvertingResponse.bind(this);
        this.handleLedResponse = this.handleLedResponse.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.realEstate !== this.props.realEstate) {
            this.setState({realEstateSelectOptions: this.realEstateNames(this.props.realEstate)})
        }
    }

    realEstateFrom(realEstateData) {
        if (realEstateData.value) {
            return RealEstateManager.getById(this.props.realEstate, realEstateData.value).name();
        }

        return null;
    }

    realEstateNames(realEstateList) {
        return realEstateList.map(realEstate => realEstate.name())
    }

    // Validating
    _requiredFields() {
        return [];
    }

    _areValidLeadFields(){
        return true;
    }

    // Event handling
    handleChangeInAcceptTermsAndConditions(event){
        this.setState({
            acceptTermsAndConditions: event.target.checked
        });
    }

    handleCityAutocomplete(newCityOrNull) {
        const cityId = newCityOrNull ? newCityOrNull.id() : '';
        let leadFields = {...this.state.leadFields};
        leadFields.city = {value: cityId};
        this.updateLeadFieldsStateWith(leadFields);
    }

    handleRealEstateChange(realEstateName) {
        let updatedState = {leadFields: {...this.state.leadFields}};

        const realEstate = RealEstateManager.get(this.props.realEstate, realEstateName);

        if (realEstate) {
            updatedState.leadFields.realEstate = {value: realEstate.id()};
            updatedState.realEstateSelected = realEstate;
        } else {
            updatedState.leadFields.realEstate = {value: null};
            updatedState.realEstateSelected = null;
        }

        updatedState.leadFields.newRealEstate = {value: realEstateName};
        this.setState(updatedState);
    }

    nextButtonPressed(event) {
        super.nextButtonPressed(event);
        if (!this.nextButtonRef.current.disabled) {
            this.nextButtonRef.current.disabled = true;
            this.editLead();
        }
    }

    manageSuccessfulLeadResponse(response){
        this.newWarrantyFromLead(response.lead())
    }

    newWarrantyFromLead(warrantyLead) {
        this.setState({processing: true});
        this.props.newWarrantyFromLead(warrantyLead, this.handleLeadConvertingResponse)
    }

    handleLeadConvertingResponse(response) {
        // TODO: manejar de forma correcta los responses, podemos quitar este if con un double dispatch
        //  #handleLedResponse
        if (!response.hasErrors()) {
            registerGoogleTagManagerEvent({'event': 'CrearSolicitud', 'event_category': 'Finaer', 'event_label': ''});
            this.props.logInUserAndRedirectToDashboard(response.warrantyApplicationNumber());
            return;
        }

        if (response.errorCode() === 1) {
            this.updateFieldErrorsFrom(response.fieldErrors());
        }

        if (response.errorCode() === 2) {
            this.props.logInUserAndRedirectToDashboard(response.activeWarrantyApplication())
        }

        this.setState({processing: false});
    }

    textForRentZoneHelper() {
        return (
            <div className="calculator-helper-text">
                <p>
                    Ingresá la zona en la que te gustaría alquilar y te vamos a asignar la oficina más cercana.
                </p>
            </div>
        )
    }

    textForRealEstateHelper() {
        return (
            <div className="calculator-helper-text">
                <p>
                    Ingresá la inmobiliaria por la cual vas a alquilar. Si todavía no la sabés o no la encontrás en el
                    listado podés dejarlo en blanco e indicar tu zona de alquiler.
                </p>
            </div>
        )
    }

    cityWithId(cityIdOrNull) {
        if (cityIdOrNull) {
            return this.props.cities.find(city => city.id() === cityIdOrNull);
        }

        return '';
    }

    getNameDescriptionOf(city, cities){
        if (this.hasARepeatedName(city, cities)){
            return city.name() + " - " + city.province().name();
        }

        return city.name();
    }

    hasARepeatedName(city, cities){
        let i = 0;
        let quantity = 0;
        while(i < cities.length && quantity < 2){
            if(cities[i].name() === city.name()){
                quantity += 1;
            }
            i++;
        }
        return quantity >= 2
    }

    isCityOrRealEstateSelected() {
        let leadFields = this.state.leadFields;
        return this.existsInRealEstateList(leadFields.realEstate.value) ||
            this.existsInRealEstateList(leadFields.newRealEstate.value) ||
            leadFields.city.value;
    }

    existsInRealEstateList(realEstate) {
        let realEstateOptions = this.realEstateNames(this.props.realEstate);
        return realEstateOptions.includes(realEstate)
    }

    nextButtonShouldBeDisabled(){
        return super.nextButtonShouldBeDisabled() || !this.isCityOrRealEstateSelected() || !this.state.acceptTermsAndConditions;
    }

    // Rendering
    render() {
        return (
            <main>
                <h1>Solicitud de Garantía</h1>
                <p className="bajada">Completar los siguientes datos personales es importante para poder comenzar con la
                    solicitud</p>
                <form>
                    <div className="middle-bar">
                        {this.renderFormContent()}
                    </div>
                    <div className="button-group">
                        <LeftButton onClick={this.props.handlePrevious}/>
                        <Button className="round-btn" ref={this.nextButtonRef} onClick={this.nextButtonPressed}
                                disabled={this.nextButtonShouldBeDisabled()}>Crear Solicitud</Button>
                    </div>
                </form>
            </main>
        );
    }

    renderFormContent() {
        if(this.props.loading) {
            return <Loader message="Espere unos segundos"/>;
        }

        return (
            <div className="middle-bar-content">
                <Row className="-overlap-fix-above">
                    <div className="combined-input col input-field m6 s12 -helper -autocomplete">
                        <p className="combined-input-label">Inmobiliaria</p>
                        <FinaerTypeAhead
                            itemToString={item => (item ? item : '')}
                            items={this.state.realEstateSelectOptions}
                            error={this.errorMessageFor('realEstate')}
                            selectedItem={this.state.realEstateSelected}
                            filterFunction={(item, inputValue) => {
                                return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                            }}
                            onChange={this.handleRealEstateChange}
                        />
                        <HelperModal
                            title="Inmobiliaria"
                            text={this.textForRealEstateHelper()}/>
                    </div>
                    <div className="combined-input col input-field m6 s12 -helper -autocomplete">
                        <p className="combined-input-label">¿En qué zona planea alquilar?</p>
                        <FinaerAutocomplete
                            itemToString={item => (item ? this.getNameDescriptionOf(item, this.props.cities) : '')}
                            items={this.props.cities}
                            selectedItem={this.cityWithId(this.state.leadFields.city.value)}
                            filterFunction={(item, inputValue) => {
                                return inputValue.length > 2 && containsSubstringLike(inputValue, item.name())
                            }}
                            onSelect={this.handleCityAutocomplete}
                        />
                        <HelperModal
                            title="Zona de alquiler"
                            text={this.textForRentZoneHelper()}/>
                    </div>
                </Row>
                <Row className="-overlap-fix-below">
                    {this._renderHowDidYouHearAboutUsAccordingToSource()}
                    <div className="col s12 terms-and-conditions-container">
                        <input id="termsAndConditionCheck"
                               name="acceptTermsAndConditions"
                               type="checkbox"
                               defaultChecked={this.state.acceptTermsAndConditions}
                               onChange={this.handleChangeInAcceptTermsAndConditions}
                        />
                        <label htmlFor="termsAndConditionCheck">Acepto Términos y Condiciones y Políticas de
                            Privacidad</label>
                        <p>
                            Ver <Link to="/terminos" target="_blank">Términos y condiciones</Link> y <Link
                            to="/privacidad" target="_blank">Políticas de privacidad</Link>
                        </p>
                    </div>
                </Row>
            </div>
        );
    }

    _renderHowDidYouHearAboutUsAccordingToSource() {
        if (this._shouldShowHowDidYouHearAbout()) {
            return this._renderHowDidYouHearAboutUs()
        }
        return null
    }

    _shouldShowHowDidYouHearAbout(){
        return this.state.leadFields.source.value !== WarrantyApplicationSourceParameterMapper.mercadoLibreCode()
    }

    _renderHowDidYouHearAboutUs() {
        return <div className="combined-input col s12">
            <p className="combined-input-label">¿Cómo nos conoció?</p>
            <div className="react-select">
                <FinaerSelect
                    selectedOption={this.state.leadFields.poll.value}
                    onChange={this.handleInputChange('poll')}
                    placeholder="Seleccione una opción"
                    options={[
                        {value: 'AMIGO', label: 'Recomendación de un conocido'},
                        {value: 'INMOBILIARIA', label: 'Recomendación de una Inmobiliaria'},
                        {value: 'MERCADOLIBRE', label: 'Internet: Aviso en Mercado Libre'},
                        {value: 'ARGENPROP', label: 'Internet: Aviso en ArgenProp'},
                        {value: 'ZONAPROP', label: 'Internet: Aviso en ZonaProp'},
                        {value: 'BANNER', label: 'Internet: Banner'},
                        {
                            value: 'REDESSOCIALES',
                            label: 'Internet: Redes Sociales (Facebook/Instagram/LinkedIn/Youtube)'
                        },
                        {value: 'WEBINMOBILIARIA', label: 'Internet: Página web de una Inmobiliaria'},
                        {value: 'EMAILPROMO', label: 'Internet: Recibí un email promocional'},
                        {value: 'AVISOTV', label: 'Aviso en TV'},
                        {value: 'AVISOREVISTA', label: 'Aviso en Revista'},
                        {value: 'AVISODIARIO', label: 'Aviso en Diario'},
                        {value: 'EVENTO', label: 'Evento (Conferencia/Exposiciones/Etc)'},
                        {value: 'OTROS', label: 'Otros'},
                    ]}/>
            </div>
        </div>;
    }
}

