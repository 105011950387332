import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

export class ModalAside extends React.Component {

    phone_number(){
        return "(011) 6842-5100"
    }

    render() {
        return (
            <div className="modal-aside">
                <div className="contact-item">
                    <FontAwesomeIcon className="icon" icon="mobile-alt"/>
                    <div className="contact-number">
                        <p><span>{this.phone_number()}</span></p>
                        <p>Lineas rotativas</p>
                    </div>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon className="icon" icon={["fab", "whatsapp"]} />
                    <div className="contact-number">
                        <p><span>{this.phone_number()}</span></p>
                    </div>
                </div>

                <div className="contact-item">
                    <FontAwesomeIcon className="icon" icon="envelope"/>
                    <p><span>{this.props.showDestinationEmail}</span></p>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon className="icon" icon="clock"/>
                    <p>Horario de atención de Lunes a viernes de 10:00 a 19:00 Hs</p>
                </div>
            </div>
        );
    }
}

ModalAside.propTypes = {
    showDestinationEmail: PropTypes.string,
    hasWhatsApp: PropTypes.bool,
}

ModalAside.defaultProps = {
    showDestinationEmail: 'info@finaersa.com.ar',
    hasWhatsApp: false,
}