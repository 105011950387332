import {Input} from "react-materialize";
import React, {Component} from "react";

export class CheckedInput extends Component {
    render(){
        return (
            <Input disabled={this.props.checked} {...this.props}/>
        );
    }
}
