import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Input, Row} from "react-materialize";
import {app} from "../../app/app";
import {HelperModal} from "../../components/modals/HelperModal";
import {FinaerSelect} from "../elements/Select";
import CalculatorResult from "./CalculatorResult";
import MoneyFormatter from "../../lib/money";
import {LoaderSection} from "../elements/LoaderSection";

// AGUS - DESACTIVAR PROMOCION

export default class CalculatorControl extends Component {
    _CASH_DISCOUNT = 20;
    _MERCADOPAGO_SURCHARGE = 20;
    _FEES_QUANTITY_OPTIONS = [
        {value: 2, label: `2 Cuotas en efectivo Sin Interés (Anticipo + 1 cuota)`},
        {value: 3, label: `3 Cuotas en efectivo Sin Interés (Anticipo + 2 cuotas)`},
        {value: 4, label: '4 Cuotas en efectivo (Anticipo + 3 cuotas)'},
        {value: 5, label: '5 Cuotas en efectivo (Anticipo + 4 cuotas)'},
        {value: 6, label: '6 Cuotas en efectivo (Anticipo + 5 cuotas)'}
    ]

    constructor(props) {
        super(props);
        this.state = {
            emailProvided: {value: '', hasError: false, sending: false, displayConfirmation: 'no'},
            formData: {
                averageRentalValue: {parsedValue: 0, value: 0, error: false},
                averageExpensesValue: {parsedValue: 0, value: 0, error: false},
                contractPeriod: {value: '24',},
            },
            lastSearch: {averageRentalValue: 0, averageExpensesValue: 0, contractPeriod: 0},
            rentalType: {
                value: 'Vivienda',
            },
            warrantyType: {
                value: 'nueva',
            },
            nonFieldErrors: [],
            loading: false,
            mercadoPagoDiscount: undefined,
            amountOfInstallmentsSelected: 3,
            installmentsReport: this._initialInstallmentsReport(),
            amountInAdvanceUpToThreeInstallments: 0,
            feesAmountUpToThreeInstallments: 0,
            valid: false,
            contractPeriodOptions: [
                {value: '12', label: '12 Meses'},
                {value: '18', label: '18 Meses'},
                {value: '24', label: '24 Meses'},
                {value: '36', label: '36 Meses'}
            ],
            installments: [2, 3, 4, 5, 6]
        };
        this.formatter = new MoneyFormatter();
        this.showTermsAndConditionsCyberWeekIfValid = this.props.showTermsAndConditionsCyberWeekIfValid === undefined ? false : this.props.showTermsAndConditionsCyberWeekIfValid;
        this.calculateCosts = this.calculateCosts.bind(this);
        this.rentalRequestData = this.rentalRequestData.bind(this);
        this.textForHelper = this.textForHelper.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    contractPeriodOptions() {
        return this.state.contractPeriodOptions
    }

    _initialInstallmentsReport() {
        return [{}, {}, {}, {}, {}, {}].map(_ => {
            return {
                totalFeeAmount: 0,
                totalFeeAmountWithoutDiscounts: 0,
                feesQuantity: 1,
                discountFee: 0,
                discountPercentage: 0,
                amountInAdvance: 0,
                feesAmount: 0
            }
        })
    }

    handleAmountValueChange(event, valueField) {
        let formData = this.state.formData;
        let input = event.target;
        let value = this.formatter.formattedValue(input.value)
        let parsedValue = this.formatter.format(value);
        formData[valueField] = {
            parsedValue: parsedValue,
            value: parsedValue === '' ? 0 : value,
            error: this.isValid(input)
        };
        this._updateForm(formData, input.form);
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.value, error: this.isValid(input)};
            this._updateForm(formData, form);
        }
    }

    _updateForm(formData, form) {
        this.setState({formData: formData, valid: form.checkValidity()});
    }

    handleRentalTypeChange() {
        let rentalType = this.state.rentalType;
        return (event) => {
            let input = event.target;
            let form = input.form;
            rentalType = {value: input.value, error: this.isValid(input)};
            this.setState({rentalType: rentalType, valid: form.checkValidity()})
        }
    }

    handleWarrantyTypeChange() {
        let warrantyType = this.state.warrantyType;
        return (event) => {
            let input = event.target;
            let form = input.form;
            warrantyType = {value: input.value, error: this.isValid(input)};
            this.setState({warrantyType: warrantyType, valid: form.checkValidity()})
        }
    }

    handleContractPeriodChange(event) {
        const formData = this.state.formData
        let contractPeriod = formData.contractPeriod
        contractPeriod.value = event.target.value;
        this.setState({formData: {...formData, contractPeriod}})
    }

    handleFeesQuantityChange(event) {
        const newFeesQuantityValue = event.target.value;
        this.setState({amountOfInstallmentsSelected: newFeesQuantityValue});
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for (let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    rentalRequestData() {
        let formData = this.state.formData;
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {});
    }

    _expensesAndRentRequested(response) {
        return {
            averageRentalValue: response.rentValue(),
            averageExpensesValue: response.expenses(),
            contractPeriod: response.contractPeriod()
        };
    }

    _calculateInstallments(installmentsReport) {
        return installmentsReport.map(installmentReport => installmentReport.feesQuantity)
    }

    handleCalculatorCostResponse = (response) => {
        // aca tenemos que hacer la diferencia de por cuantas cuotas le pedimos
        // y editar el box correspondiente
        if (!response.hasErrors()) {
            const installmentsReport = response.installmentsReport();
            this.setState({
                installmentsReport: installmentsReport,
                mercadoPagoDiscount: response.mercadoPagoDiscount(),
                lastSearch: this._expensesAndRentRequested(response),
                installments: this._calculateInstallments(installmentsReport)
            });
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({formData: formData, loading: false, nonFieldErrors: response.nonFieldErrors()})
        }
    }

    calculateCosts() {
        this.clearFormErrors();
        let requestData = this.rentalRequestData();
        let isRenewal = this.state.warrantyType.value === 'renovacion';
        this.setState({loading: true}, async () => {
                await app.apiClient().calculateRentalCosts({...requestData, isRenewal}, this.handleCalculatorCostResponse);
                await this.setState({loading: false})
            }
        );
    }

    calculateCostsBtnAction(event) {
        event.preventDefault();
        this.calculateCosts();
    }

    formatMoney(number) {
        return this.formatter.format(number);
    }

    _feesQuantityOptionsForAvailableInstallments() {
        const installments = this.state.installments
        return this._FEES_QUANTITY_OPTIONS.filter(feesQuantity => installments.includes(feesQuantity.value))
    }

    renderFeesQuantityOptions() {
        const feesQuantityOptions = this._feesQuantityOptionsForAvailableInstallments();
        return (
            <div className="combined-input col s12 m8">
                <div className="react-select">
                    <FinaerSelect
                        placeholder={'Ver más opciones plan de cuotas'}
                        onChange={(event) => this.handleFeesQuantityChange(event)}
                        options={feesQuantityOptions}/>
                </div>
            </div>
        )
    }

    render() {
        return (
            <section className="section-box" id="calculadora">
                <header>
                    <FontAwesomeIcon className="icon" icon="calculator"/>
                    <h1>Calculadora de Costos</h1>
                </header>
                <form>
                    <p>Calculá el costo de tu Garantía Finaer completando todos los campos</p>
                    
                    <Row className="rental-type-and-duration-row">
                        <Col className="content-contract-period">
                            <div className="content-radio-buttons-rental-type">
                                <div className="col -text">
                                    <p>Consulto por una Garantía</p>
                                </div>
                                <div className="radio-button-option">
                                    <input type="radio" name="type" value={'nueva'}
                                           checked={this.state.warrantyType.value === 'nueva'}
                                           onChange={this.handleWarrantyTypeChange()}/> <p>Nueva</p>
                                </div>
                                <div className="radio-button-option">
                                    <input type="radio" name="type" value={'renovacion'}
                                           checked={this.state.warrantyType.value === 'renovacion'}
                                           onChange={this.handleWarrantyTypeChange()}/> <p>Renovación</p>
                                </div>
                            </div>
                        </Col>

                        <Col className="content-contract-period">
                            <div className="content-radio-buttons-rental-type">
                                <div className="col -text">
                                    <p>Tipo de alquiler</p>
                                </div>
                                <div className="radio-button-option">
                                    <input type="radio" name="rental" value={'Vivienda'}
                                           checked={this.state.rentalType.value === 'Vivienda'}
                                           onChange={this.handleRentalTypeChange()}/> <p>Vivienda</p>
                                </div>
                                <div className="radio-button-option">
                                    <input type="radio" name="rental" value={'Comercial'}
                                           checked={this.state.rentalType.value === 'Comercial'}
                                           onChange={this.handleRentalTypeChange()}/> <p>Comercial</p>
                                </div>
                            </div>
                        </Col>

                        <Col className="content-contract-period">
                            <div className="content-radio-buttons-rental-type">
                                <p>Duración de tu alquiler</p>
                                <div className="react-select" style={{width: '100%'}}>
                                    <FinaerSelect
                                        selectedOption={this.state.formData.contractPeriod.value}
                                        onChange={(event) => this.handleContractPeriodChange(event)}
                                        options={this.contractPeriodOptions()}/>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="calculate-row-container">
                        {this.renderRentalValue()}
                        {this.renderExpensesValue()}
                        <button className="main-btn calculate-btn calculate-row-container-item"
                                onClick={(event) => this.calculateCostsBtnAction(event)}>
                            Calcular
                        </button>
                    </Row>

                    <Row>
                        <p className="calculator-values-text">
                            <span>Valores consultados:</span> Alquiler mensual :
                            ${this.formatMoney(this.state.lastSearch.averageRentalValue)} - Expensas :
                            ${this.formatMoney(this.state.lastSearch.averageExpensesValue)} - Duración
                            alquiler: {this.formatMoney(this.state.lastSearch.contractPeriod)} meses -
                            Tipo de garantía: {this.state.warrantyType.value.charAt(0).toUpperCase() + this.state.warrantyType.value.slice(1)}
                        </p>
                        <p className="calculator-option-text">
                            <span>Elegí</span> el plan de pago de tu preferencia y conocé
                            por qué tenemos el mejor financiamiento del mercado
                        </p>
                    </Row>

                    {this.renderCalculation()}

                    <Row>
                        {this.renderFeesQuantityOptions()}
                    </Row>

                    {this._emailShippingRow()}

                    <Row className="warning-text">
                        <p className="warning">Importante</p>
                        <p>Los valores son calculados según los montos ingresados.<br/>
                            El costo final corresponderá al mismo cálculo en base a los valores<br/>
                            definitivos del alquiler, según figuren en el contrato de locación.</p>
                    </Row>

                    {this._termsAndConditionsCyberWeek()}
                </form>
            </section>
        );
    }

    _emailShippingRow() {
        return <Row>
            <span>Enviar por mail la cotización en función de los últimos valores calculados</span>
            <Row className="calculator-send-email-row">
                <Input className='input-mail'
                       type="email"
                       name="email"
                       id="email"
                       labelClassName={this.state.formData.averageRentalValue.parsedValue != null ? "active" : ""}
                       value={this.state.emailProvided.value}
                       placeholder="Indicá tu correo"
                       onChange={this._handleEmailChange()}/>

                {this._renderButtonOrLoadingWheel()}
                {this._renderFeedbackOfEmailSent()}
            </Row>
            <Row className="email-error-message">
                {this.state.emailProvided.hasError && <span>Ingrese una dirección de email válida</span>}
            </Row>
        </Row>
    }

    _renderFeedbackOfEmailSent() {
        return <>
            {this.state.emailProvided.displayConfirmation === "success" &&
                <span className={"email-status success"}>Email enviado correctamente</span>}
            {this.state.emailProvided.displayConfirmation === "failure" &&
                <span className={"email-status error"}>El email no fue enviado</span>}
        </>;
    }

    _renderButtonOrLoadingWheel() {
        return <>
            {!this.state.emailProvided.sending &&
                <button
                    onClick={(event) => this._userRequestedEmailShipping(event)}> Enviar Mail </button>}
            {this.state.emailProvided.sending && <LoaderSection/>}
        </>;
    }

    _handleEmailChange() {
        return (event) => {
            let input = event.target;
            this.setState(previousState => {
                previousState.emailProvided.value = input.value
                return previousState
            });
        }
    }

    _userRequestedEmailShipping(event) {
        event.preventDefault()
        let valid = this._validateEmail(this.state.emailProvided.value)
        if (valid) {
            let dataForEmailShipping = {
                email: this.state.emailProvided.value,
                averageRentalValue: this.state.lastSearch.averageRentalValue,
                averageExpensesValue: this.state.lastSearch.averageExpensesValue,
                contractPeriod: this.state.formData.contractPeriod.value,
                isRenewal: this.state.warrantyType.value === 'renovacion',
            }
            this.setState(prevState => {
                prevState.emailProvided.sending = true;
                return prevState
            }, async () => app.apiClient().requestEmailShipping(dataForEmailShipping).then(result => {
                if (result['errors'].length === 0) {
                    this.setState((prevState) => {
                        prevState.emailProvided.sending = false;
                        prevState.emailProvided.displayConfirmation = "success";
                        return prevState
                    })
                } else {
                    this.setState((prevState) => {
                        prevState.emailProvided.sending = false;
                        prevState.emailProvided.displayConfirmation = "failure";
                        return prevState
                    })
                }
            }))
        } else {
            this.setState((prevState) => {
                prevState.emailProvided.displayConfirmation = "failure";
                return prevState
            })
        }
    }

    _validateEmail(anEmail) {
        anEmail = anEmail.trim()
        let valid = /\w+@\w+\.\w{2,10}/.test(anEmail)
        this.setState((prevState) => {
            prevState.emailProvided.hasError = !valid;
            return prevState
        })
        return valid
    }

    _isThereAMercadoPagoDiscunt() {
        return this.props.mercadoPagoDiscount !== undefined && this.props.mercadoPagoDiscount.isActive()
    }

    _termsAndConditionsCyberWeek() {
        // return (
        //     <Row className="warning-text">
        //         <p className="terms-and-conditions-cyber-week" style={{marginTop: '40px', maxWidth: '600px'}}>
        //             <span>Términos & Condiciones -  Promoción Sale Week:</span> Promoción válida para clientes de Sistema Finaer S.A., con: A)Solicitudes de garantía iniciadas entre el 01 y 07 de Julio de 2024, y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía dentro del mismo período. B)Solicitudes de garantía iniciadas entre el  01 y 07 de Julio de 2024, y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía a más tardar el 12 de Julio de 2024. C)Solicitudes de garantía iniciadas con fecha previa al 01 de Julio de 2024 y que realicen el pago de contado y en efectivo de la totalidad del costo de la garantía entre el 01 y el 05 de Julio de 2024. Promoción no acumulable con otros descuentos o formas de pago. Descuento de 25% válido para pagos de contado en efectivo y en los términos expresados, independientemente de la duración del contrato.<br/>
        //         </p>
        //     </Row>
        // )
    }

    renderCalculation() {
        const installmentsReportOnePayment = this.state.installmentsReport[0];
        let amountOfInstallmentsSelected = this.state.amountOfInstallmentsSelected;
        let amountOfInstallmentsForTwoOrThreeCalculator = amountOfInstallmentsSelected < 4 ? amountOfInstallmentsSelected : 3
        let installmentsReportTwoOrThreePayments = this.state.installmentsReport[amountOfInstallmentsForTwoOrThreeCalculator - 1];
        let amountOfInstallmentsForMoreThanThreeCalculator = amountOfInstallmentsSelected >= 4 ? amountOfInstallmentsSelected : 6;
        let installmentsReportMoreThanThreePayments = this.state.installmentsReport[amountOfInstallmentsForMoreThanThreeCalculator - 1];
        return <Row className="paids-information">
            {this.renderCalculatorResult(installmentsReportOnePayment, 1, this._CASH_DISCOUNT)}
            {this.renderCalculatorResult(installmentsReportTwoOrThreePayments, amountOfInstallmentsForTwoOrThreeCalculator)}
            {this.renderCalculatorResult(installmentsReportMoreThanThreePayments, amountOfInstallmentsForMoreThanThreeCalculator)}
        </Row>
    }

    renderCalculatorResult(installmentReport, feesQuantity, discount = 0) {
        let calculatorResultProps = {
            mercadoPagoSurchargePercentage: this._MERCADOPAGO_SURCHARGE,
            loading: this.state.loading,
            discountPercentage: discount,
            mercadoPagoDiscount: this.props.mercadoPagoDiscount ? this.props.mercadoPagoDiscount.percentage() : 0,
            feesQuantity: feesQuantity
        }
        if (installmentReport) {
            calculatorResultProps = {
                ...calculatorResultProps,
                totalAmount: Math.round(installmentReport.totalFeeAmount),
                feesAmount: installmentReport.feesAmount,
                amountInAdvance: installmentReport.amountInAdvance,
                totalFeeAmountWithoutDiscounts: Math.round(installmentReport.totalFeeAmountWithoutDiscounts),
                discountFee: installmentReport.discountFee,
                isFeesAmountPermitted: true
            }
        } else {
            calculatorResultProps = {
                ...calculatorResultProps,
                isFeesAmountPermitted: false
            }
        }

        return <CalculatorResult
            {...calculatorResultProps}
        />
    }

    renderHelper(hasCommercialType) {
        if (hasCommercialType) {
            return (<HelperModal
                title="Valor promedio de tu alquiler"
                text={this.textForHelper()}/>);
        }
    }

    renderRentalValue() {
        let hasCommercialType = this.state.rentalType.value === 'Comercial';
        let rentalLabel = hasCommercialType ? 'Valor promedio de tu alquiler' : 'Ingresá el valor del primer mes de alquiler';
        return (
            <Input m={5} s={12}
                   label={rentalLabel}
                   labelClassName={this.state.formData.averageRentalValue.parsedValue != null ? "active" : ""}
                   value={this.state.formData.averageRentalValue.parsedValue}
                   maxLength={10}
                   className="calculate-row-container-item"
                   onChange={(event) => this.handleAmountValueChange(event, 'averageRentalValue')}
                   error={this.hasError('averageRentalValue')}>
                {this.renderHelper(hasCommercialType)}
            </Input>
        )
    }

    renderExpensesValue() {
        return (
            <Input m={5} s={12}
                   label="Valor de las expensas actuales (si las hubiera)"
                   labelClassName={this.state.formData.averageExpensesValue.parsedValue != null ? "active" : ""}
                   value={this.state.formData.averageExpensesValue.parsedValue}
                   maxLength={10}
                   className="calculate-row-container-item"
                   onChange={(event) => this.handleAmountValueChange(event, 'averageExpensesValue')}
                   error={this.hasError('averageExpensesValue')}/>
        );
    }

    textForHelper() {
        return (
            <div className="calculator-helper-text">
                <p>
                    ¡Es muy fácil! Deberás conocer los <span>valores e incrementos de cada
                    período dentro del tiempo de tu alquiler</span> (ejemplo: incrementos semestrales,
                    anuales, etc).
                </p>
                <ul>
                    <p>Por ejemplo, tu alquiler tiene 6 incrementos:</p>

                    <li>
                        <span>Primer semestre:</span> Pagás $10.000 de Alquiler
                    </li>
                    <li>
                        <span>Segundo semestre:</span> Pagás $11.500 de Alquiler
                    </li>
                    <li>
                        <span>Tercer semestre:</span> Pagás $13.225 de Alquiler
                    </li>
                    <li>
                        <span>Cuarto semestre:</span> Pagás $15.208 de Alquiler
                    </li>
                    <li>
                        <span>Quinto semestre:</span> Pagás $16.500 de Alquiler
                    </li>
                    <li>
                        <span>Sexto semestre:</span> Pagás $18.200 de Alquiler
                    </li>
                    <li>
                        <span>Sumá los montos y dividí el resultado entre la cantidad de períodos:</span>
                        [(10.000+11.500+13.225+15.208+16.500+18.200)/6] = Promedio = $14.106
                    </li>
                </ul>
                <p className="warning-text">
                    <span>Nota:</span> Si desconocés los valores o los incrementos, podés indicar un monto
                    estimativo, recordá que el valor final dependerá de los valores reales
                    plasmados en el contrato de locación."
                </p>
            </div>
        )
    }
}

