import React from 'react';


export class RedirectToMercadoPagoButton extends React.Component {
    constructor(props) {
        super(props);
        this.mercadoPagoBtnId = "button-checkout";
        this.state = {loading : true}
    }

    componentDidMount(){
        this._createButton(this.props.preferenceId);
    }

    _createButton(preferenceId) {
        const script = document.createElement("script");

        script.src = "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
        script.type = "text/javascript";
        script.dataset.preferenceId = preferenceId;
        script.onload = () => this.setState({loading: false});
        document.getElementById(this.mercadoPagoBtnId).innerHTML = "";
        document.querySelector("#" + this.mercadoPagoBtnId).appendChild(script);
    }

    render() {
        return (
            <div>
                <div id={this.mercadoPagoBtnId}  className="main-btn confirm-payment-btn" hidden={this.state.loading}/>
            </div>
        );
    }
}