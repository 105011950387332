

export default class WarrantyApplicationSourceParameterMapper  {

    sourceCodeFor(sourceParameter, ifAbsentDo){
        const source = Object.values(this.sources()).find(value => value.parameter === sourceParameter);
        if (source === undefined) {
            return ifAbsentDo()
        }
        return source.code
    }

    sources() {
        return {
            mercadoLibre: {
                parameter: this.constructor.mercadoLibreParameter(),
                code: this.constructor.mercadoLibreCode()
            }
        }
    }

    static mercadoLibreParameter(){
        return "m"
    }

    static mercadoLibreCode(){
        return "MERCADO_LIBRE"
    }
}