import React from 'react';
import {Input, Modal, Row} from 'react-materialize'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalAside} from "./ModalAside";
import {CloseButton} from "../buttons/CloseButton";
import {app} from "../../app/app";

export class NewRealStateModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            formData: {
                legalName: {},
                legalAddress: {},
                phoneAreaCode: {},
                phoneNumber: {},
                email1: {},
                email2: {},
                website: {},
                manager: {},
                logo: {}
            },
            nonFieldErrors: [],
            valid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.hasError = this.hasError.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onCloseModal() {
        this.setState({submitted: false});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clearFormErrors();
        app.apiClient().newRealState(this.collectFormData(), this.handleApiResponse);
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.setState({submitted: true});
        } else {
            let formData = this.state.formData;
            let fieldErrors = response.fieldErrors();

            for (let fieldName in fieldErrors) {
                formData[fieldName].error = fieldErrors[fieldName];
            }

            this.setState({formData: formData, nonFieldErrors: response.nonFieldErrors()})
        }
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.value, error: this.isValid(input)};
            this.setState({formData: formData, valid: form.checkValidity()})
        }
    }

    handleFileSelected(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            let form = input.form;
            formData[inputName] = {value: input.files[0], error: this.isValid(input)};
            this.setState({formData: formData, valid: form.checkValidity()})
        }
    }

    collectFormData() {
        let formData = this.state.formData;
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {})
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for (let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    render() {
        return (
            <Modal header='Solicitud de adhesión'
                   trigger={
                       <button className={this.props.class}>
                           <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                           <p>{this.props.button}</p>
                       </button>}
                   modalOptions={{complete: this.onCloseModal}}>
                <div className="modal-body">
                    <div className="modal-main">
                        {this.modalContent()}
                        <CloseButton/>
                    </div>
                    <ModalAside/>
                </div>
            </Modal>
        );
    }

    modalContent() {
        if (this.state.submitted) {
            return this.successMessage()
        } else {
            return this.form()
        }
    }

    successMessage() {
        return (
            <p>La inmobiliaria ha sido registrada. Será contactado a la brevedad.</p>
        )
    }

    form() {
        return (
            <form>
                <Row>
                    <Input s={12} onChange={this.handleInputChange('legalName')}
                           error={this.hasError('legalName')} label="Razón Social"/>
                </Row>
                <Row>
                    <Input s={12} onChange={this.handleInputChange('legalAddress')}
                           error={this.hasError('legalAddress')} label="Domicilio comercial completo (Calle, Número, Localidad, Provincia)"/>
                </Row>
                <Row>
                    <div className="combined-input col m8 s12">
                        <p className="combined-input-label">Teléfono</p>
                        <Input s={4} onChange={this.handleInputChange('phoneAreaCode')} type={'number'}
                               error={this.hasError('phoneAreaCode')} label="Cód. de área"/>
                        <Input s={8} onChange={this.handleInputChange('phoneNumber')} type={'number'}
                               error={this.hasError('phoneNumber')} label="Nro. de teléfono"/>
                    </div>
                </Row>
                <Row>
                    <Input m={6} s={12} onChange={this.handleInputChange('email1')}
                           error={this.hasError('email1')} label="Email"/>
                    <Input m={6} s={12} onChange={this.handleInputChange('email2')}
                           error={this.hasError('email2')} label="Confirmar email"/>
                </Row>
                <Row>
                    <Input m={6} s={12} onChange={this.handleInputChange('website')}
                           error={this.hasError('website')} label="Sitio web"/>
                    <Input m={6} s={12} onChange={this.handleInputChange('manager')}
                           error={this.hasError('manager')} label="Titular responsable"/>
                </Row>
                <Row>
                    <p className="combined-input-label">Logo</p>
                    <Input type="file" label="Cargar" onChange={this.handleFileSelected('logo')}
                           error={this.hasError('logo')}
                           placeholder="Adjuntá una imagen de tu logo" s={12}/>
                </Row>
                <Row className="submit-row">
                    <button className="main-btn" onClick={this.handleSubmit}
                            disabled={!this.state.valid}>Enviar</button>
                </Row>
            </form>
        )
    }

}
