import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {FinaerSelect} from "../../elements/Select";
import {CheckedInput} from "../../elements/CheckedInput";
import {ControlledCheck} from "../../elements/ControlledCheck";
import {MailButton} from "../../buttons/MailButton";
import {CheckedEmailInput} from "../../elements/CheckedEmailInput";
import {HelperModal} from "../../modals/HelperModal";
import {BirthdayDateField, DateField} from "../../../models/Field";

export class PersonalDataOne extends Component {
    static EMAIL_CONFIRMED = 'CONFIRMADO';
    static EMAIL_NOT_CONFIRMED = 'NO CONFIRMADO';
    static CONFIRMATION_REQUEST_SENT = 'ENVIO REALIZADO';

    dayOptions() {
        const month = this.props.participantData['birthdate'].month();
        const year = this.props.participantData['birthdate'].year();
        let options = [];

        DateField.dayOptions(month, year).forEach((day) => {
            options.push({label: day, value: '_day-' + day});
        });

        return options;
    }

    monthOptions() {
        let options = [];
        DateField.monthOptions().forEach((month, index) => {
            options.push({label: month, value: '_month-' + (index + 1)});
        });

        return options;
    }

    yearOptions() {
        let options = [];
        BirthdayDateField.yearOptions().forEach((year) => {
            options.push({label: year, value: '_year-' + year});
        });

        return options;
    }

    emailConfirmationButtonIsDisabled() {
        const email = this.props.participantData['email'];
        return email.value() === "" || email.error() || this.props.isEmailConfirmationSent
    }

    warningAccordingToEmailState() {
        if (this.shouldWarningAccordingToEmailStateBeShown()) {
            if (this.props.isEmailConfirmed) {
                return "-info-email-verified";
            } else {
                if (this.props.isEmailConfirmationSent) {
                    return "-info-email-confirmation-sent";
                } else {
                    return "-info-confirmation";
                }
            }
        }
    }

    shouldWarningAccordingToEmailStateBeShown() {
        const hasEmail = this.props.participantData['email'].value() &&
            !this.props.participantData['email'].error();
        return this.props.confirmButtonIsShown && hasEmail;
    }

    renderEmailField() {
        return (
            <div className={"-mail-btn-container " + this.warningAccordingToEmailState()}>
                <CheckedEmailInput s={this.props.isMobile ? 12 : 12} label="Email" type='email'
                                   defaultValue={this.props.participantData['email'].value()}
                                   onChange={this.props.handleInputChange('email')}
                                   error={this.props.participantData['email'].error()}
                                   checked={this.props.personalDataChecked}
                />
                {this.renderEmailConfirmationButton()}
            </div>
        )
    }

    renderEmailConfirmationButton() {
        if (this.props.confirmButtonIsShown) {
            return <MailButton handleSubmit={this.props.emailConfirmation}
                               isDisabled={this.emailConfirmationButtonIsDisabled()}/>
        }
    }

    textForMobileNumberHelper() {
        return (
            <div className="basic-text">
                <p>
                    Deberá ingresar el código de área seguido del número de teléfono como se muestra
                    a continuación, sin el 15, solo números, sin puntos (.) ni guiones (-):
                    <p>
                        <span>Código de área + Número. Ejemplo: 011 23456789</span>
                    </p>
                </p>
            </div>
        )
    }

    textForPhoneNumberHelper() {
        return (
            <div className="basic-text">
                <p>
                    Deberá ingresar el código de área seguido del número de teléfono como se muestra
                    a continuación, solo números, sin puntos (.) ni guiones (-):
                    <p>
                        <span>Código de área + Número. Ejemplo: 011 23456789</span>
                    </p>
                </p>
            </div>
        )
    }

    birthdayField() {
        return (
            <div className={this.props.isMobile ? "" : "birthdate-field"}>
                <p className="combined-input-label">Fecha de nacimiento</p>
                <div className="col input-field s4">
                    <div className="react-select -padding-adjustment">
                        <FinaerSelect
                            selectedOption={'_day-' + this.props.participantData['birthdate'].day()}
                            onChange={this.props.handleInputChange('birthdate')}
                            placeholder="Día"
                            options={this.dayOptions()}
                            className={this.props.personalDataChecked ?  "-disable-select -padding-adjustment" : "-padding-adjustment"}
                            checked={this.props.personalDataChecked}/>
                    </div>
                </div>
                <div className="col input-field s4">
                    <div className="react-select -padding-adjustment">
                        <FinaerSelect
                            selectedOption={'_month-' + this.props.participantData['birthdate'].month()}
                            onChange={this.props.handleInputChange('birthdate')}
                            placeholder="Mes"
                            options={this.monthOptions()}
                            className={this.props.personalDataChecked ?  "-disable-select -padding-adjustment" : "-padding-adjustment"}
                            checked={this.props.personalDataChecked}/>
                    </div>
                </div>
                <div className="col input-field s4">
                    <div className="react-select -padding-adjustment">
                        <FinaerSelect
                            selectedOption={'_year-' + this.props.participantData['birthdate'].year()}
                            onChange={this.props.handleInputChange('birthdate')}
                            placeholder="Año"
                            options={this.yearOptions()}
                            className={this.props.personalDataChecked ?  "-disable-select -padding-adjustment" : "-padding-adjustment"}
                            checked={this.props.personalDataChecked}/>
                    </div>
                </div>
            </div>
        );
    }

    sexField() {
        const classes = this.props.isMobile ? "" : " sex-select-field"
        return (
            <div className={"react-select sex-select" + classes}>
                <p className="combined-input-label">Sexo</p>
                <FinaerSelect
                    selectedOption={this.props.participantData['sex'].value()}
                    onChange={this.props.handleInputChange('sex')}
                    placeholder="Seleccione su sexo"
                    options={[
                        {label: 'Femenino', value: 'F'},
                        {label: 'Masculino', value: 'M'},
                    ]}
                    className={this.props.personalDataChecked ? "-disable-select -padding-adjustment" : "-padding-adjustment"}
                    checked={this.props.personalDataChecked}/>
            </div>
        )
    }

    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Datos personales</h2>
                    <ControlledCheck checked={this.props.personalDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row>
                        <CheckedInput s={this.props.isMobile ? 12 : 6} label="Nombre" maxLength={50}
                                      defaultValue={this.props.participantData['firstName'].value()}
                                      onChange={this.props.handleInputChange('firstName')}
                                      error={this.props.participantData['firstName'].error()}
                                      checked={this.props.personalDataChecked}/>
                        <CheckedInput s={this.props.isMobile ? 12 : 6} label="Apellido" maxLength={50}
                                      defaultValue={this.props.participantData['lastName'].value()}
                                      onChange={this.props.handleInputChange('lastName')}
                                      error={this.props.participantData['lastName'].error()}
                                      checked={this.props.personalDataChecked}/>
                    </Row>
                    <Row>
                        <div className={this.props.isMobile ? "combined-input col s12" : "combined-input col s6"}>
                            <p className="combined-input-label">Documento</p>
                            <div className="col input-field s4">
                                <div className="react-select">
                                    <label className="active">Tipo</label>
                                    <FinaerSelect
                                        isDisabled={!this.props.canEditId || this.props.personalDataChecked}
                                        selectedOption={this.props.participantData['docType'].value()}
                                        onChange={this.props.handleInputChange('docType')}
                                        placeholder="Tipo"
                                        className={this.props.canEditId && !this.props.personalDataChecked ? "-padding-adjustment" : "-disable-select -padding-adjustment"}
                                        options={[
                                            {label: 'DNI', value: 'DNI'},
                                            {label: 'Pasaporte', value: 'PAS'},
                                        ]}/>
                                </div>
                            </div>

                            <CheckedInput s={8} label="Nro. de Documento" maxLength={10}
                                          defaultValue={this.props.participantData['docNumber'].value()}
                                          onChange={this.props.handleInputChange('docNumber')}
                                          error={this.props.participantData['docNumber'].error()}
                                          disabled={!this.props.canEditId || this.props.personalDataChecked}
                                          checked={this.props.personalDataChecked}/>
                        </div>
                        <div className={this.props.isMobile ? "combined-input col s12 -helper" : "combined-input col s6 -helper"}>
                            <p className="combined-input-label">Teléfono celular</p>
                            <CheckedInput s={4} type="number" label="Cód. de área" maxLength={6}
                                          defaultValue={this.props.participantData['cellPhoneAreaCode'].value()}
                                          onChange={this.props.handleInputChange('cellPhoneAreaCode')}
                                          error={this.props.participantData['cellPhoneAreaCode'].error()}
                                          checked={this.props.personalDataChecked}
                            />
                            <CheckedInput className={"-helper-space"} s={8} type="number" label="Nro. de celular" maxLength={20}
                                          defaultValue={this.props.participantData['cellPhoneNumber'].value()}
                                          onChange={this.props.handleInputChange('cellPhoneNumber')}
                                          error={this.props.participantData['cellPhoneNumber'].error()}
                                          checked={this.props.personalDataChecked}
                            />
                            <HelperModal
                                title="¿Cómo cargo correctamente un número de teléfono celular?"
                                text={this.textForMobileNumberHelper()}/>
                        </div>
                    </Row>
                    <Row className={this.props.isMobile ? "combined-input col s12 overlap-fix" : "combined-input col s6 overlap-fix birthday-and-sex-row"}>
                        {this.birthdayField()}
                        {this.sexField()}
                    </Row>
                    <Row>
                        {this.renderEmailField()}
                    </Row>
                </div>
            </div>
        );
    }
}
