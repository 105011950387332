import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import EmailConfirmationBase from "./EmailConfirmationBase";


class FailedEmailConfirmation extends Component {
    render () {
        return <EmailConfirmationBase firstText='¡Lo sentimos!'
                                      secondText='No pudimos confirmar tu email.'
                                      thirdText='El pedido de confirmación ha expirado o no existe.'
                                      forthText='Por favor comunicate con la Oficina Comercial que
                                                 actualmente se ocupa de tu Solicitud.'/>

    }
}

export default withRouter(FailedEmailConfirmation);