import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "react-router-dom/es/Link";
import logo from "../../images/logo-finaer.svg";
import {withRouter} from "react-router-dom";
import {app} from "../../app/app";


class RequestNav extends React.Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        app.logOutUser();
        this.props.history.push('/');
    }

    renderApplicantName() {
        return <p className="user-name">{app.loggedInUser().name()}</p>;
    }

    renderWeb() {
        return (
            <header className="request-nav-bar">
                <Link className="site-logo" to="/">
                    <img src={logo} className="app-logo" alt="logo"/>
                </Link>
                <div className="request-user">
                    {this.renderApplicantName()}
                    <button className="logout-btn" onClick={this.handleLogout}>
                        <p>Salir</p>
                        <FontAwesomeIcon className="icon" icon="sign-out-alt"/>
                    </button>
                </div>
            </header>
        );
    }

    renderMobile() {
        return (
            <header className="request-nav-bar -mobile-menu">
                <Link className="site-logo" to="/">
                    <img src={logo} className="app-logo" alt="logo"/>
                </Link>
                <div className="request-user">
                    {this.renderApplicantName()}
                    <button className="form-mobile-open" onClick={this.props.openMobileMenu}>
                        <FontAwesomeIcon className="icon" icon="bars"/>
                    </button>
                </div>
            </header>
        );
    }

    render() {
        if (this.props.isMobile) {
            return this.renderMobile();
        } else {
            return this.renderWeb();
        }
    }
}

export default withRouter(RequestNav);