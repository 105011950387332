import React from "react";
import {Input} from "react-materialize";
import {FinaerSelect} from "../../elements/Select";
import 'react-credit-cards/es/styles-compiled.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class LoadNIDForm extends React.Component {

    _cardNumberToDisplay() {
        let nidCardNumber = this.props.nidData.number;
        if (nidCardNumber === '') {
            return '**********';
        }
        if (nidCardNumber.length > 8) {
            return nidCardNumber.substring(0, 8) + '...';
        }
        return nidCardNumber;
    }

    _nidCardView() {
        let nidCardTitle = this.props.nidData.type !== '' ? this.props.nidData.type : 'Nro';
        let nidCardNumber = this._cardNumberToDisplay();
        return (
            <div className="nid-card">
                <div className="nid-card-image">
                    <div className="icon-user-nid">
                        <FontAwesomeIcon className="icon" icon="user"/>
                    </div>
                </div>
                <div className="nid-card-number">
                    <p className="nid-type-field">{nidCardTitle}</p>
                    <h4 className="nid-number-field">{nidCardNumber}</h4>
                </div>
            </div>
        );
    }

    _nidError(type, number) {
        let nidType = this.props.nidOptions.getByType(type);
        if (!nidType) {
            return 'Invalido';
        }
        return nidType.isNIDNumberValid(number) ? '' : "Invalido";
    }

    handleNIDChange = (newValue, changedField) => {
        let nidData = this.props.nidData;
        let number = changedField === 'number' ? newValue : nidData.number;
        let type = changedField === 'type' ? newValue : nidData.type;
        let error = this._nidError(type, number);
        this.props.updateFormData({number: number, type: type, error: error});
    }

    _ndiForm() {
        return (
            <form className="mp-card-form nid-form">
                <div className="credit-row" style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
                    <div className="select-nid-type">
                        <div className="react-select">
                            <FinaerSelect
                                selectedOption={this.props.nidData.type}
                                onChange={(event) => this.handleNIDChange(event.target.value, 'type')}
                                placeholder="Tipo"
                                required={true}
                                options={this.props.nidOptions.renderToSelect()}/>
                        </div>
                    </div>
                    <div className="nid-number">
                        <Input placeholder="Número de documento" id="docNumber" name="docNumber"
                               defaultValue={this.props.nidData.number}
                               onChange={(event) => this.handleNIDChange(event.target.value, 'number')}
                               data-checkout="docNumber" type="text"
                               error={this.props.nidData.error}/>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div id="PaymentForm" className="mercado-pago-payment">
                {this._nidCardView()}
                {this._ndiForm()}
            </div>
        );
    }
}
