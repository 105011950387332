import React from "react";
import PaymentBase from "../PaymentBase";
import {SelectPaymentMethodBtn} from "../../buttons/SelectPaymentMethodBtn";
import {MercadoPagoBtn} from "../../buttons/MercadoPagoBtn";

export default class SelectPaymentMethodStep extends PaymentBase{

    title() {
        return <h1>Elije un medio de pago</h1>
    }

    subtitle(){
        return (
            <p className="bajada discount-text">
                Descuento de {this.props.warrantyInformation.cashDiscount}% por pago contado efectivo<br/>
                Descuento de {this.props.warrantyInformation.transferDiscount}% por pago contado por transferencia<br/>
                {this.props.mercadoPagoDiscount !== undefined && this.props.mercadoPagoDiscount.isActive() ?
                    `Descuento ${this.props.mercadoPagoDiscount.percentage()}% MEGA SALE por pago con Mercado Pago`
                : ''
                }
            </p>
        )

    }

    body() {
        return (
            <div className="middle-bar-content payment-bar">
                <SelectPaymentMethodBtn text="Efectivo" icon="money-bill" onFinishDo={this.props.onCashSelected}/>
                <SelectPaymentMethodBtn text="Transferencia" icon="credit-card" onFinishDo={this.props.onTransferSelected}/>
                <MercadoPagoBtn onFinishDo={this.props.onMercadoPagoSelected} class={'main-btn select-payment-btn payment-btn-wrap'}/>
                <SelectPaymentMethodBtn text="Mercado Pago con Billetera" onFinishDo={this.props.onWalletSelected}/>
            </div>
        );
    }

}