import React from "react";
import {LoaderSection} from "../elements/LoaderSection";

export default class PaymentBase extends React.Component {

    constructor(props) {
        super(props);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.state = {
            'paying': false
        }
    }

    getFee() {
        return this.props.paymentInfo ? this.props.paymentInfo.firstSettlementFee +
            this.props.paymentInfo.administrativeExpenses : this.props.warrantyInformation.fee;
    }

    getMercadoPagoFee() {
        let originalFee = this.getFee();
        let cyberWeekFee = this._blackWeekFee();
        return cyberWeekFee < originalFee ? cyberWeekFee : originalFee;
    }

    title() {
        throw Error("subclass Responsibility");
    }

    body() {
        throw Error("subclass Responsibility");
    }

    footer() {
    }

    subtitle() {
    }

    handleApiResponse(response) {
        if (!response.hasErrors()) {
            this.props.handleNext();
        }
    }

    _conceptToPay() {
        return this.props.hasOnePayment ? 'Garantía Finaer' : 'anticipo';

    }

    _amountToPay(conceptToPay=undefined) {
        conceptToPay = conceptToPay ? conceptToPay : this._conceptToPay();
        return (
            <p className="payment-information-text">Tu &nbsp;
                <span className='span-garantia'>{conceptToPay}</span> tiene un valor de &nbsp;
                <span className='span-precio'>${this.getFee()}</span>
            </p>
        );
    }

    _amountToPayMercadoPagoSection() {
        if (this._blackWeekDiscountApplies()) {
            return this._blackWeekFeeBox();
        }
        return this._amountToPay();
    }

    _blackWeekFee() {
        let mercadoPagoDiscount = this.props.paymentInfo.mercadoPagoDiscount;
        let originalFee = this.props.paymentInfo.originalFee;
        let administrativeExpenses = this.props.paymentInfo.administrativeExpenses;
        return Math.round(originalFee - originalFee * mercadoPagoDiscount / 100) + administrativeExpenses;
    }

    _blackWeekDiscountApplies() {
        let isThereDiscountActive = this.props.paymentInfo.mercadoPagoDiscount > 0;
        let mercadoPagoFee = this._blackWeekFee();
        return isThereDiscountActive && mercadoPagoFee <= this.props.paymentInfo.fee;
    }

    _onePaymentDiscount(discountPercentage) {
        if (this.props.hasOnePayment) {
            const discountDescription = <>El pago por {this.name} cuenta con un <span>{discountPercentage}%</span> de descuento</>
            return this._discountDescriptionForOnePayment(discountPercentage, discountDescription);
        }
    }

    _discountDescriptionForOnePayment(discountPercentage, discountDescription) {
        const discount = this.props.warrantyInformation.fee * discountPercentage / 100;
        return (
            <div>
                <p className="payment-information-text">
                    {discountDescription}
                </p>
                <p className="payment-information-text remark-discount">
                    Tu total a pagar es de <span>${this._fee(discount)}</span>
                </p>
            </div>
        );
    }

    _discount(discountPercentage, discountDescription) {
        let paymentBreakdown;
        console.log(this.props.warrantyInformation)
        if (this.state.amountOfInstallments === 2 ) {
            paymentBreakdown = <p className="payment-information-text remark-discount">
                Anticipo: ${ this.props.warrantyInformation.twoInstallmentsAdvanceAmount } - {this.state.amountOfInstallments - 1} cuotas de  ${ this.props.warrantyInformation.twoInstallmentsAmount}
            </p>;
        }
        if (this.state.amountOfInstallments === 3 ) {
            paymentBreakdown = <p className="payment-information-text remark-discount">
                Anticipo: ${ this.props.warrantyInformation.threeInstallmentsAdvanceAmount } - {this.state.amountOfInstallments - 1} cuotas de  ${ this.props.warrantyInformation.threeInstallmentsAmount}
            </p>
        }
        if (this.state.amountOfInstallments === 4 ) {
            paymentBreakdown = <p className="payment-information-text remark-discount">
                Anticipo: ${ this.props.warrantyInformation.fourInstallmentsAdvanceAmount } - {this.state.amountOfInstallments - 1} cuotas de  ${ this.props.warrantyInformation.fourInstallmentsAmount}
            </p>
        }
        if (this.state.amountOfInstallments === 5 ) {
            paymentBreakdown = <p className="payment-information-text remark-discount">
                Anticipo: ${ this.props.warrantyInformation.fiveInstallmentsAdvanceAmount } - {this.state.amountOfInstallments - 1} cuotas de  ${ this.props.warrantyInformation.fiveInstallmentsAmount}
            </p>
        }
        if (this.state.amountOfInstallments === 6 ) {
            paymentBreakdown = <p className="payment-information-text remark-discount">
                Anticipo: ${ this.props.warrantyInformation.sixInstallmentsAdvanceAmount } - {this.state.amountOfInstallments - 1} cuotas de  ${ this.props.warrantyInformation.sixInstallmentsAmount}
            </p>
        }
        return (
            <div>
                <p className="payment-information-text">
                    {discountDescription}
                </p>
                { paymentBreakdown }
            </div>
        );
    }

    _fee(discount) {
        let administrativeExpenses = this.props.paymentInfo ? this.props.paymentInfo.administrativeExpenses : 0;
        return Math.floor(this.props.warrantyInformation.fee - discount) + administrativeExpenses;
    }

    _blackWeekFeeBox() {
        return (
            <div className="installments-bar">
                <p className="payment-information-text aclaration">
                    Valor original de la Garantía Finaer ${this.props.paymentInfo.originalFee} sin descuentos
                </p>
                <p className="payment-information-text remark-black-week-discount">
                    {this.props.paymentInfo.mercadoPagoDiscount}% OFF por MEGA SALE
                </p>
                <p className="payment-information-text">Ahora el valor de tu&nbsp;
                    <span className='span-garantia'>Garantía Finaer</span> es de &nbsp;
                    <span className='span-precio'>${this._blackWeekFee()}&nbsp;(*)</span>
                </p>
            </div>
        );
    }

    _confirmPaymentBtn(onBtnClick) {
        let _onBtnClick = () => {
            this.setState({'paying' : true}, () => onBtnClick());
        }
        return (
            <button onClick={_onBtnClick}
                    className={"main-btn confirm-payment-btn"}
                    disabled={this.state.paying}>
                CONFIRMAR
            </button>
        );
    }

    _promotionsFooter() {
        if (this._blackWeekDiscountApplies()) {
            return (
                <p className="one-promotion-text">
                    <br/>(*) No aplica con otras promociones. Se tomará el descuento de mayor valor
                </p>
            );
        }
    }

    mainBody() {
        if (this.props.loading) {
            return <LoaderSection message="Espere unos segundos mientras recuperamos los datos"/>;
        }
        return this.body();
    }

    render() {
        return (
            <main>
                {this.title()}
                {this.subtitle()}
                <div className="middle-bar">
                    {this.mainBody()}
                </div>
                {this.footer()}
            </main>);
    }
}