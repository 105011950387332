import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {FinaerAutocomplete} from "../../elements/Autocomplete";
import {containsSubstringLike} from "../../../lib/others";
import {CheckedInput} from "../../elements/CheckedInput";
import {ControlledCheck} from "../../elements/ControlledCheck";


export class JobDataTwo extends Component {
    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Domicilio laboral</h2>
                    <ControlledCheck checked={this.props.jobDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row>
                        <CheckedInput s={6} label="Calle" maxLength={100}
                               defaultValue={this.props.participantData['jobAddressStreet'].value()}
                               onChange={this.props.handleInputChange('jobAddressStreet')}
                               error={this.props.participantData['jobAddressStreet'].error()}
                               checked={this.props.jobDataChecked}/>
                        <CheckedInput s={2} label="Número" maxLength={10}
                               defaultValue={this.props.participantData['jobAddressStreetNumber'].value()}
                               onChange={this.props.handleInputChange('jobAddressStreetNumber')}
                               error={this.props.participantData['jobAddressStreetNumber'].error()}
                               checked={this.props.jobDataChecked}/>
                        <CheckedInput s={2} label="Piso" maxLength={10}
                               defaultValue={this.props.participantData['jobAddressStreetFloor'].value()}
                               onChange={this.props.handleInputChange('jobAddressStreetFloor')}
                               error={this.props.participantData['jobAddressStreetFloor'].error()}
                               checked={this.props.jobDataChecked}/>
                        <CheckedInput s={2} label="Depto." maxLength={10}
                               defaultValue={this.props.participantData['jobAddressStreetApartment'].value()}
                               onChange={this.props.handleInputChange('jobAddressStreetApartment')}
                               error={this.props.participantData['jobAddressStreetApartment'].error()}
                               checked={this.props.jobDataChecked}/>
                    </Row>
                    <Row className="-overlap-fix-above">
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Provincia'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.participantData['jobAddressProvince'].options()}
                                selectedItem={this.props.participantData['jobAddressProvince'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={(province) => {this.props.handleAutoComplete('jobAddressProvince', province)}}
                                checked={this.props.jobDataChecked}
                            />
                        </div>
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Ciudad / Localidad'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.participantData['jobAddressCity'].options(this.props.participantData['jobAddressProvince'])}
                                selectedItem={this.props.participantData['jobAddressCity'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={(city) => this.props.handleAutoComplete('jobAddressCity', city)}
                                checked={this.props.jobDataChecked}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className={this.props.isMobile ? "combined-input col s12" : "combined-input col s6"}>
                            <p className="combined-input-label">Teléfono Laboral</p>
                            <CheckedInput s={4} label="Cód. de área" maxLength={6}
                                   defaultValue={this.props.participantData['jobPhoneAreaCode'].value()}
                                   onChange={this.props.handleInputChange('jobPhoneAreaCode')}
                                   error={this.props.participantData['jobPhoneAreaCode'].error()}
                                   checked={this.props.jobDataChecked}/>
                            <CheckedInput s={8} label="Nro. de teléfono" maxLength={20}
                                   defaultValue={this.props.participantData['jobPhoneNumber'].value()}
                                   onChange={this.props.handleInputChange('jobPhoneNumber')}
                                   error={this.props.participantData['jobPhoneNumber'].error()}
                                   checked={this.props.jobDataChecked}/>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

