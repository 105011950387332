import React, {Component} from "react";
import "../../../styles/App.scss";
import {Input, Row} from "react-materialize";
import {FinaerAutocomplete} from "../../elements/Autocomplete";
import {containsSubstringLike} from "../../../lib/others";


export class PropertyDataOne extends Component {
    constructor(props) {
        super(props);

        this.handleProvinceAutocomplete = this.handleProvinceAutocomplete.bind(this);
        this.handleCityAutocomplete = this.handleCityAutocomplete.bind(this);
    }

    handleProvinceAutocomplete(province) {
        let fieldsToUpdate = {'addressProvince': province};

        if(!province) {
            fieldsToUpdate['addressCity'] = null;
        }

        this.props.handleAutoComplete(fieldsToUpdate);
    }

    handleCityAutocomplete(city) {
        let fieldsToUpdate = {'addressCity': city};

        if(!city) {
            fieldsToUpdate['addressProvince'] = null;
        }

        this.props.handleAutoComplete(fieldsToUpdate);
    }

    isEmpty(strField){
        return (!strField || strField === '');
    }


    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <h2 className="form-header">Domicilio del Inmueble</h2>
                <div className="form-inputs">
                    <Row>
                        <Input s={6} label="Calle" maxLength={100}
                               defaultValue={this.props.property['addressStreet'].value()}
                               onChange={this.props.handleInputChange('addressStreet')}
                               error={this.props.property['addressStreet'].error()}/>
                        <Input s={2} label="Número" maxLength={10}
                               defaultValue={this.props.property['addressNumber'].value()}
                               onChange={this.props.handleInputChange('addressNumber')}
                               error={this.props.property['addressNumber'].error()}/>
                        <Input s={2} label="Piso" maxLength={10}
                               defaultValue={this.props.property['addressFloor'].value()}
                               onChange={this.props.handleInputChange('addressFloor')}
                               error={this.props.property['addressFloor'].error()}/>
                        <Input s={2} label="Depto." maxLength={10}
                               defaultValue={this.props.property['addressApartment'].value()}
                               onChange={this.props.handleInputChange('addressApartment')}
                               error={this.props.property['addressApartment'].error()}/>

                    </Row>
                    <Row>
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Provincia'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.property['addressProvince'].options()}
                                selectedItem={this.props.property['addressProvince'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={this.handleProvinceAutocomplete}
                                disabled={this.isEmpty(this.props.property['addressStreet'].value()) ||
                                    this.isEmpty(this.props.property['addressNumber'].value())}
                            />
                        </div>
                        <div className={this.props.isMobile ? "col input-field s12 -autocomplete" : "col input-field s6 -autocomplete"}>
                            <FinaerAutocomplete
                                label={'Ciudad / Localidad'}
                                itemToString={item => (item ? item : '')}
                                items={this.props.property['addressCity'].options(this.props.property['addressProvince'])}
                                selectedItem={this.props.property['addressCity'].displayValue()}
                                filterFunction={(item, inputValue) => {
                                    return inputValue.length > 3 && containsSubstringLike(inputValue, item)
                                }}
                                onSelect={this.handleCityAutocomplete}
                                disabled={this.isEmpty(this.props.property['addressStreet'].value()) ||
                                    this.isEmpty(this.props.property['addressNumber'].value())}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

