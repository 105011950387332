import React from "react";
import { Modal, Row } from "react-materialize";

export class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isModalOpen: true }; 
    }

    _onConfirm = () => {
        this.setState({ isModalOpen: false }, () => {
            this.props.onConfirm(); 
        });
    }

    _onClose = () => {
        this.setState({ isModalOpen: false }, () => {
            this.props.onClose();
        });
    }

    _header() {
        return (
            <div className="modal-confirmation-header">
                <h1>Confirmación</h1>
            </div>
        );
    }

    render() {
        return (
            <Modal open={this.state.isModalOpen} className="modal-confirmation"
                   modalOptions={{ complete: this.props.onClose }}>
                <div className="modal-body">
                    <div className="modal-main modal-confirmation-body">
                        {this._header()}
                        <p>{this.props.message}</p>

                        <Row className="modal-confirmation-buttons">
                            <button className="main-btn btn-back" onClick={this._onConfirm}>
                                Aceptar
                            </button>

                            <button className="main-btn btn-back" onClick={this._onClose}>
                                Cancelar
                            </button>
                        </Row>
                    </div>
                </div>
            </Modal>
        );
    }
}
