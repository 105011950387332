import React from "react";
import {Input} from "react-materialize";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

export default class LoadCreditCardForm extends React.Component {

    constructor(props) {
        super(props);
        // Needed for Card View
        this.state = {
            focus: '',
            number: this.props.form.creditCardNumber.value,
        };
    }

    _formatExpirationDateInForm() {
        let expirationDate = this.props.form.expirationDate;
        return this.props.paymentMethod.formatExpirationDate(expirationDate.month + expirationDate.year)
    }

    handleInputFocus = (e) => {
        this.setState({focus: e.target.name});
    }

    handleNumberChange = ({target}) => {
        let newValue = this.props.paymentMethod.formatCreditCardNumber(target.value)
        target.value = newValue;
        this.props.completeCreditNumber(newValue);
        this.setState({'number': newValue});
    }

    handleCvcChange = ({target}) => {
        target.value = this.props.paymentMethod.formatCVC(target.value)
        this.props.completeSecurityCode(target.value);
    }

    handleExpirationDateChange = ({target}) => {
        target.value = this.props.paymentMethod.formatExpirationDate(target.value);
        this.props.completeExpirationDateFor(target.value);
    }

    handleCardholdersName = ({target}) => {
        target.value = this.props.paymentMethod.formatCardholdersName(target.value);
        this.props.completeCardHolderName(target.value);
    };

    _expirationDateField() {
        return (
            <div className="credit-row expiration-date">
                <Input
                    type="tel"
                    name="expiry"
                    className="form-control"
                    placeholder="Valida Hasta"
                    pattern="\d\d/\d\d"
                    error={this.props.errorMessage('expirationDate')}
                    onChange={this.handleExpirationDateChange}
                    onFocus={this.handleInputFocus}
                    defaultValue={this._formatExpirationDateInForm()}
                />
            </div>
        );
    }

    _creditNumberField() {
        let defaultValue = this.props.paymentMethod.formatCreditCardNumber(this.state.number);
        return (
            <div className="card-number">
                <Input placeholder="Nro. de tarjeta"
                       type="tel"
                       className="mp-input"
                       name="number"
                       onFocus={this.handleInputFocus}
                       defaultValue={defaultValue}
                       onChange={this.handleNumberChange}
                       error={this.props.errorMessage('creditCardNumber')}/>
            </div>
        );
    }

    _cardholderNameField() {
        return (
            <div className="cardholder-name">
                <Input placeholder="Nombre del titular" name="name" data-checkout="cardholderName"
                       type="text"
                       defaultValue={this.props.form.cardholderName.value}
                       onChange={this.handleCardholdersName}
                       onFocus={this.handleInputFocus}
                       error={this.props.errorMessage('cardholderName')}/>
            </div>
        );
    }

    _securityCodeField() {
        return (
            <div className="security-code">
                <Input placeholder="CVV" name="cvc" data-checkout="securityCode" type="tel"
                       pattern="\d{3,4}"
                       onFocus={this.handleInputFocus}
                       onChange={this.handleCvcChange}
                       defaultValue={this.props.form.securityCode.value}
                       error={this.props.errorMessage('securityCode')}/>
            </div>
        );
    }

    _cardView() {
        return <Cards
            cvc={this.props.form.securityCode.value}
            expiry={this._formatExpirationDateInForm()}
            focused={this.state.focus}
            name={this.props.form.cardholderName.value}
            number={this.state.number}
            placeholders={
                {name: 'Nombre del titular'}
            }
            locale={{valid: 'valida hasta'}}
        />;
    }

    render() {
        return (
            <div id="PaymentForm" className="mercado-pago-payment">
                {this._cardView()}
                <form className="mp-card-form">
                    <div className="credit-row number-cvv-row">
                        {this._creditNumberField()}
                    </div>
                    <div className="credit-row number-cvv-row">
                        {this._cardholderNameField()}
                    </div>
                    <div className="credit-row">
                        {this._expirationDateField()}
                        {this._securityCodeField()}
                    </div>
                </form>
            </div>
        );
    }
}