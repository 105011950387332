import React from "react";
import "../../styles/App.scss";
import {Button, Input, Row} from "react-materialize";
import CreateWarrantyLeadWizardStep from "./CreateWarrantyLeadWizardStep";


export default class CreateWarrantyLeadWizardStepOne extends CreateWarrantyLeadWizardStep {
    constructor(props) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this._form = React.createRef();
    }

    // Validating

    _requiredFields() {
        return ['name', 'surname', 'email', 'email2'];
    }

    _areValidLeadFields(leadFields) {
        // Pensar si hace falta validar emailsMatch, hay algun caso en que no se llame a checkEmailsMatchIfCompleted?
        return this.form().checkValidity() && !this.hasErrorsIn(leadFields) && this.emailsMatch();
    }

    checkEmailsMatchIfCompleted(leadFields) {
        const email1 = leadFields.email.value;
        const email2 = leadFields.email2.value;


        if (this.emailIsValid('email') && this.emailIsValid('email2')) {
            if(email1 && email2 && email1 !== email2) {
                this.setError(leadFields, 'email', 'Los emails ingresados no coinciden.');
                this.setError(leadFields, 'email2', 'Los emails ingresados no coinciden.');
            } else {
                this.resetError(leadFields, 'email');
                this.resetError(leadFields, 'email2');
            }
        }
    }

    emailIsValid(inputName) {
        const validationError = 'El email ingresado es inválido.';
        return this.errorMessageFor(inputName) !== validationError;
    }

    emailsMatch() {
        let leadFields = this.state.leadFields;
        const email1 = leadFields.email.value;
        const email2 = leadFields.email2.value;

        return email1 && email2 && email1 === email2;
    }

    // Convenience
    form() {
        return this._form.current;
    }

    // Event handling
    handleEmailChange(inputName) {
        let leadFields = this.state.leadFields;
        return (event) => {
            let input = event.target;
            leadFields[inputName] = {value: input.value};
            this.resetError(leadFields, inputName);

            if(input.checkValidity()) {
                this.checkEmailsMatchIfCompleted(leadFields);
            } else {
                this.setError(leadFields, inputName, 'El email ingresado es inválido.');
            }

            this.updateLeadFieldsStateWith(leadFields);
        }
    }

    nextButtonPressed(event) {
        super.nextButtonPressed(event);
        this.setState({processing: true});
        if (this.isEditingLead()) {
            this.editLead()
        }
        else {
            this.createLead()
        }
    }

    isEditingLead() {
        return this.state.leadFields.id.value !== undefined
    }

    createLead() {
        this.props.createLead(this.state.leadFields, this.handleLedResponse);
    }

    // Rendering
    render() {
        return (
            <main>
                <h1>Solicitud de Garantía</h1>
                <p className="bajada">Completar los siguientes datos personales es importante para poder comenzar con la solicitud</p>
                <form ref={this._form}>
                    <div className="middle-bar">
                        <div className="middle-bar-content">
                            <Row>
                                <Input m={6} s={12} onChange={this.handleInputChange('name')} label="Nombre"
                                       value={this.state.leadFields.name.value} required={true} maxLength={50}
                                       type={'text'} error={this.errorMessageFor('name')} validate autoFocus/>
                                <Input m={6} s={12} onChange={this.handleInputChange('surname')}
                                       label="Apellido" value={this.state.leadFields.surname.value}
                                       required={true} maxLength={50}
                                       error={this.errorMessageFor('surname')} validate/>
                            </Row>
                            <Row>
                                <Input m={6} s={12} onChange={this.handleEmailChange('email')}
                                       onKeyPress={this.handleEnterPress} label="Email" maxLength={50}
                                       value={this.state.leadFields.email.value} type={'email'} required={true}
                                       error={this.errorMessageFor('email')} autoFocus/>
                                <Input m={6} s={12} onChange={this.handleEmailChange('email2')}
                                       onKeyPress={this.handleEnterPress} maxLength={50}
                                       label="Confirmar Email" value={this.state.leadFields.email2.value}
                                       type={'email'} required={true}
                                       error={this.errorMessageFor('email2')} />
                            </Row>
                        </div>
                    </div>
                    <div className="button-group">
                        <Button className="round-btn"
                                onClick={this.nextButtonPressed}
                                disabled={this.nextButtonShouldBeDisabled()}>
                                {this.props.hasPrequalification ? 'Confirmar' : 'Siguiente'}
                        </Button>
                    </div>
                </form>
            </main>
        );
    }
}
