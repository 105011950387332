import React from 'react';
import { Input, Row } from 'react-materialize';
import { FinaerSelect } from "../components/elements/Select";
import { app } from '../app/app';
import Link from "react-router-dom/es/Link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { HelperModal } from '../components/modals/HelperModal';
import logo from "../images/logo-finaer.svg";

export class Login extends React.Component {
   

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                docType: {value: 'DNI'},
                docNumber: {},
                requestCode: {}
            },
            nonFieldErrors: [],
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEnterPress = this.handleEnterPress.bind(this);
        this.requestData = this.requestData.bind(this);
        this.hasError = this.hasError.bind(this);
        this.redirectOnSuccess = this.redirectOnSuccess.bind(this);
        this.successfulLogin = this.successfulLogin.bind(this);
        this.failedLogin = this.failedLogin.bind(this);

        this._form = React.createRef();
    }

    form() {
        return this._form.current;
    }

    redirectOnSuccess() {
        this.props.history.push('/dashboard');
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({loading: true});
        this.clearFormErrors();

        let logInCredentials = this.requestData();
        app.loginUser(logInCredentials.docType, logInCredentials.docNumber, logInCredentials.requestCode,
            this.successfulLogin, this.failedLogin);
    }

    successfulLogin() {
        this.redirectOnSuccess();
    }

    failedLogin(response) {
        let formData = this.state.formData;
        let fieldErrors = response.fieldErrors();

        for (let fieldName in fieldErrors) {
            formData[fieldName].error = fieldErrors[fieldName];
        }

        this.setState({formData: formData, nonFieldErrors: response.nonFieldErrors(), loading: false})
    }

    handleEnterPress(event) {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    requestData() {
        let formData = this.state.formData;
        return Object.keys(formData).reduce((data, currentKey) => {
            data[currentKey] = formData[currentKey].value;
            return data;
        }, {});
    }

    handleInputChange(inputName) {
        let formData = this.state.formData;
        return (event) => {
            let input = event.target;
            formData[inputName] = {value: input.value};
            this.setState({formData: formData, valid: this.form().checkValidity()})
        }
    }

    isValid(input) {
        return input.checkValidity() ? '' : 'Campo inválido';
    }

    hasError(inputName) {
        let inputHasError = this.state.formData[inputName].error;
        return inputHasError ? this.state.formData[inputName].error : '';
    }

    clearFormErrors() {
        let formData = this.state.formData;

        for (let field in formData) {
            formData[field].error = '';
        }

        this.setState({formData: formData})
    }

    textForRequestCodeHelper() {
        return (
            <div className="calculator-helper-text">
                <p>
                    Si ya iniciaste tu solicitud, encontrarás el código en los correos que hemos enviado a
                    tu casilla de email. <br/> Por ejemplo: A-AWE5TY Q-YTRMN3 <br/>
                </p>
            </div>
        )
    }

    defaultButton(){
        return <button className="main-nav-item menu-item-logout">
            <FontAwesomeIcon className="icon" icon="user"/>
            <p>Seguí tu Solicitud</p>
        </button>
    }

    selectButtonTrigger(){
        if (this.props.buttonTrigger) {
            return this.props.buttonTrigger;
        }
        return this.defaultButton();
    }

    render() {
        return (
            <div className="layout-request begin">
                <header>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo" />
                    </Link>
                </header>
                <main>
                    <h1 style={{fontSize:'36px', fontWeight: '600',  marginBottom:'20px'}}>Seguí tu Solicitud</h1>
                   
                    <h2 style={{fontSize:'25px', fontWeight: '500', marginBottom:'32px'}}>Ingrese los datos para iniciar sesión</h2>
                 
                            <form ref={this._form}>
                    <div className="middle-bar" style={{padding: '40px', margin:'14px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Row>
                                    <div className={"combined-input col s12"}>
                                        <div className="col input-field s4">
                                            <div className="react-select -padding-adjustment" >
                                                <FinaerSelect
                                                    selectedOption={this.state.formData.docType.value}
                                                    onChange={this.handleInputChange('docType')}
                                                    placeholder="Tipo"
                                                    options={[
                                                        {label: 'DNI', value: 'DNI'},
                                                        {label: 'Pasaporte', value: 'PAS'},
                                                    ]}/>
                                            </div>
                                        </div>

                                        <Input s={8} label="Nro. de Documento" maxLength={10}
                                            onChange={this.handleInputChange('docNumber')}
                                            error={this.hasError('docNumber')}/>
                                    </div>
                                    <Input s={12} label="Código de solicitud"
                                        maxLength={8}
                                        onChange={this.handleInputChange('requestCode')}
                                        onKeyPress={this.handleEnterPress}
                                        error={this.hasError('requestCode')}>
                                        <HelperModal
                                            title="Código de solicitud"
                                            text={this.textForRequestCodeHelper()}/>
                                    </Input>
                                </Row>
                                <span className="error" style={{marginBottom: '20px'}}>{this.state.nonFieldErrors[0]}</span>
                                <Row className="submit-row">
                                    <button className="main-btn" onClick={this.handleSubmit} style={{width: '292px'}}
                                            disabled={this.state.loading}>Ingresar
                                    </button>
                                </Row>
                                </div>
                            </form>
                </main>
            </div>
              
        );
    }
}
