
export default class MoneyFormatter {
    constructor() {
        this._formatter = new Intl.NumberFormat({
            style: 'currency',
            currency: 'ARS',
            prefix: '$'
        });
    }

    format(value) {
        if (value === '' || isNaN(value)) return ''
        return this._formatter.format(value);
    }

    formattedValue(formatedValue) {
        return formatedValue.replaceAll('.', '').replaceAll(',', '');
    }
}