import React, {Component} from "react";
import "../../../styles/App.scss";
import {Row} from "react-materialize";
import {ControlledCheck} from "../../elements/ControlledCheck";


export class PersonalDataThree extends Component {
    render() {
        return (
            <div key={this.props.actualParticipantId}>
                <div className={"title-checked-container"}>
                    <h2 className="form-header">Sobre el alquiler</h2>
                    <ControlledCheck checked={this.props.personalDataChecked}/>
                </div>
                <div className="form-inputs">
                    <Row className="content-radio-buttons">
                        <div className="col -text">
                            <p>¿Habitará el inmueble?</p>
                        </div>
                        <div className="radio-button-option">
                            <input type="radio" value={true}
                                   name={'liveInTheProperty'}
                                   defaultChecked={this.props.participantData['liveInTheProperty'].value()}
                                   onChange={this.props.handleInputChange('liveInTheProperty')}
                                   disabled={this.props.personalDataChecked}
                            />
                            <p>Si</p>
                        </div>
                        <div className="radio-button-option">
                            <input type="radio" value={false}
                                   name={'liveInTheProperty'}
                                   defaultChecked={!this.props.participantData['liveInTheProperty'].value()}
                                   onChange={this.props.handleInputChange('liveInTheProperty')}
                                   disabled={this.props.personalDataChecked}
                           />
                            <p>No</p>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}
