/*
* Wraps Credit Card data  sent by Mercadopago when first 6 numbers are entered
* */

class PaymentCardMercadopagoBase {

    isSecurityCodeValid(securityCode) {
        throw Error("subclass Responsibility");
    }

    formatCVC(value) {
        throw Error("subclass Responsibility");
    }

    formatCreditCardNumber(value) {
        throw Error("subclass Responsibility");
    }

    isCardNumberValid(cardNumber) {
        throw Error("subclass Responsibility");
    }

    // TODO: should belong to utils
    clearNumber(value = "") {
        return value.replace(/\D+/g, "");
    }

    formatExpirationDate(value) {
        const clearValue = this.clearNumber(value);

        if (clearValue.length >= 3) {
            return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
        }

        return clearValue;
    }

    formatCardholdersName(name) {
        return name.replace(/[^a-z ]/gi, '')
    }

    binFor(cardNumber) {
        return cardNumber.substring(0, 6);
    }

    id() {
        throw Error("subclass Responsibility");
    }
}

export class PaymentCardMercadopago extends PaymentCardMercadopagoBase {
    constructor(paymentMethodData) {
        super()
        this._id = paymentMethodData.id;
        this.thumbnail = paymentMethodData.thumbnail;
        this.issuer = paymentMethodData.issuer;
        this.paymentTypeId = paymentMethodData.payment_type_id;
        this.name = paymentMethodData.name;
        this.validSecturityCodeLenghts = this._validSecturityCodeLenghts(paymentMethodData);
        this.validCardNumberLenghts = this._validCardNumberLenghts(paymentMethodData);
        this.maxCardNumberLength = Math.max.apply(null, this.validCardNumberLenghts);
        this.maxSecturityCodeLength = Math.max.apply(null, this.validSecturityCodeLenghts);
    }

    _validSecturityCodeLenghts(paymentMethodData) {
        return paymentMethodData.settings.map((rest) => {
            return rest.security_code.length;
        });
    }

    _validCardNumberLenghts(paymentMethodData) {
        return paymentMethodData.settings.map((rest) => {
            return rest.card_number.length;
        });
    }

    isSecurityCodeValid(securityCode) {
        return this.validSecturityCodeLenghts.includes(securityCode.length);
    }

    isCardNumberValid(cardNumber) {
        return this.validCardNumberLenghts.includes(cardNumber.length);
    }

    formatCVC(value) {
        const clearValue = this.clearNumber(value);
        return clearValue.slice(0, this.maxSecturityCodeLength);
    }

    formatCreditCardNumber(value) {
        const clearValue = this.clearNumber(value);
        let nextValue;

        switch (this.issuer.name) {
            case "American Express":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                )} ${clearValue.slice(10, this.maxCardNumberLength)}`;
                break;
            case "Diners":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                )} ${clearValue.slice(10, this.maxCardNumberLength)}`;
                break;
            default:
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    8
                )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, this.maxCardNumberLength)}`;
                break;
        }

        return nextValue.trim();
    }

    id() {
        return this._id;
    }
}

export class PaymentCardMercadopagoNull extends PaymentCardMercadopagoBase {

    isSecurityCodeValid(securityCode) {
        return false;
    }

    formatCVC(value) {
        return this.clearNumber(value);
    }

    formatCreditCardNumber(value) {
        return this.clearNumber(value);
    }

    isCardNumberValid(cardNumber) {
        return false;
    }

    id() {
        return 'invalid';
    }
}