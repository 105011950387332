import React, {Component} from 'react';
import '../styles/App.scss';
import RequestNav from "../components/layout/RequestNav.js";
import Link from "react-router-dom/es/Link";
import {RecordItem} from "../components/request-history/RecordItem.js";
import {app} from "../app/app";
import {Loader} from "../components/elements/Loader";

class RequestHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            errorMessage: undefined,
            requestHistory: {}
        };

        this.handleApiResponse = this.handleApiResponse.bind(this);
    }

    _warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    handleApiResponse(response) {
        if (response.hasErrors()) {
            this.setState({errorMessage: response.nonFieldErrors(), loading: false});
        } else {
            this.setState({requestHistory: response.requestHistory(), loading: false});
        }
    }

    componentWillMount() {
        const warrantyApplicationNumber = this._warrantyApplicationNumber();
        app.apiClient().getRequestHistory(warrantyApplicationNumber, this.handleApiResponse);
    }

    renderHistory() {
        if (this.state.requestHistory.length > 0) {
            return (
                <div className="record-list">
                    {this.state.requestHistory.map(request => {
                        return (
                            <RecordItem code={request['number']} state={request['state']}
                                        address={request['address'] || '-------'}/>
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div>
                    <p>No hay solicitudes anteriores</p>
                </div>
            )
        }
    }

    renderContent() {
        if (this.state.loading) {
            return <Loader message="Espere unos segundos mientras recuperamos los datos"/>;
        } else {
            return this.renderHistory();
        }
    }

    render() {
        return (
            <div className="layout-request record">
                <RequestNav/>
                <main>
                    <div className="request-content">
                        <h1>Historial de solicitudes</h1>
                        <Link to="/dashboard" className="back-btn">Volver al menú inicial</Link>
                        {this.renderContent()}
                    </div>
                </main>
            </div>
        );
    }
}

export default RequestHistory;
