import React from 'react'

import BackgroundImage1 from '../../images/fondo-inmobiliaria.png'
import BackgroundImage2 from '../../images/fondo-propietario.png'
import BackgroundImage3 from '../../images/fondo-solicitante-1.png'
import BackgroundImage4 from '../../images/fondo-solicitante-2.png'

export default class LandingBackground extends React.Component {

    LOCAL_STORAGE_IMAGE_NUMBER_KEY = 'lastNumberOfBackgroundDisplayed'

    render(){
        const backgrounds = [BackgroundImage1, BackgroundImage2, BackgroundImage3, BackgroundImage4];

        const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];

        return <img src={randomBackground} className="home-background" alt="fondo" />
    }

    _nextNumberOfImageToDisplay() {
        const amountOfBackgrounds = this.images.length;
        const lastNumberOfBackgroundDisplayed = this._currentNumberOfImageToDisplay();
        const nextNumberOfBackgroundToDisplay = (lastNumberOfBackgroundDisplayed + 1) % amountOfBackgrounds
        localStorage.setItem(this.LOCAL_STORAGE_IMAGE_NUMBER_KEY, nextNumberOfBackgroundToDisplay.toString())
        return nextNumberOfBackgroundToDisplay;
    }

    _currentNumberOfImageToDisplay() {
        return parseInt(localStorage.getItem(this.LOCAL_STORAGE_IMAGE_NUMBER_KEY)) || 0;
    }

    _backgroundAnimation(imageIndex){
        const imageData = this.images[imageIndex];
        return [imageData.frameUrls, this._styleTranslateXPixelsToTheLeft(imageData.horizontalMobileOffset)];
    }

    _styleTranslateXPixelsToTheLeft(pixelsToTheLeft){
        return {transform: `translate(${-pixelsToTheLeft}px, ${0}px)`}
    }

    advanceFrameNumber() {
        const totalFrames = 8;
        const newFrameNumber = (this.state.frameNumber + 1) % totalFrames;
        this.setState({frameNumber: newFrameNumber});
    }
}