class IdNumberValidator {
    constructor(idNumber){
        this._idNumber = idNumber;
    }

    static NewFor(idType, idNumber){
        let subClasses = [DNIValidator, PassportValidator];
        for (let subclass of subClasses){
            if (subclass.accepts(idType)){
                return new subclass(idNumber);
            }
        }
        throw new Error("The subclass doens't exists");
    }
}

class DNIValidator extends  IdNumberValidator{
    static accepts(idType){
        return idType === 'DNI';
    }

    validate(){
        if (!this.validDNI(this._idNumber)){
            return 'Sólo se permiten números de 7 u 8 dígitos.';
        }
        if(this.idNumberStartWithZero(this._idNumber)){
            return 'El DNI no puede comenzar con 0';
        }
        return '';
    }

    validDNI() {
        let regexValidator = new RegExp('^[0-9]{7,8}$');
        return regexValidator.test(this._idNumber);
    }

    idNumberStartWithZero(){
        return this._idNumber.startsWith("0");
    }
}

class PassportValidator extends IdNumberValidator{
    static accepts(idType){
        return idType === 'PAS';
    }

    validate(){
        if(!this.validPassport(this._idNumber)) {
            return 'Sólo se permiten letras y números.';
        }
        if(!this.validPassportLength(this._idNumber)) {
            return 'Debe tener entre 6 y 10 dígitos.'
        }
    }

    validPassport() {
        let regexValidator = new RegExp('^[A-Za-z0-9]*$');
        return regexValidator.test(this._idNumber);
    }

    validPassportLength() {
        if(this._idNumber !== undefined) {
            let idNumberLength = this._idNumber.length
            return 6 <= idNumberLength && idNumberLength <= 12;
        }
    }
}

export default IdNumberValidator;