import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import EmailConfirmationBase from "./EmailConfirmationBase";

class SuccessfulEmailConfirmation extends Component {
    render () {
        return <EmailConfirmationBase firstText='¡Felicitaciones!'
                                      secondText='Tu email ya ha sido verificado.'
                                      thirdText='Si sos Solicitante recordá completar todos los datos y
                                                 documentación de tu Solicitud para que pueda avanzar y comenzar
                                                 el período de 24 Hs. para su resolución.'
                                      forthText='Si necesitás más información, no dudes en
                                                  contactarnos a la Oficina Comercial que actualmente
                                                  se ocupa de tu Solicitud.'/>

    }
}

export default withRouter(SuccessfulEmailConfirmation);