import React from "react";
import {LoaderSection} from "../elements/LoaderSection";
import PropTypes from 'prop-types'

// AGUS - DESACTIVAR PROMOCION

export default class CalculatorResult extends React.Component {
    INSTALLMENTS_INTEREST_RATES_FOOT_NOTES = {
        4: '4 Cuotas (Anticipo + 3 cuotas):\nTNA: 75 % - TEA: 95.31 % - CTF: 95.31 %',
        5: '5 Cuotas (Anticipo + 4 cuotas):\nTNA: 60 % - TEA: 70.84 % - CTF: 70.84 %',
        6: '6 Cuotas (Anticipo + 5 cuotas):\nTNA: 50% - TEA: .56.25 % - CTF: 56.25 %'
    }

    advancePayment() {
        return Math.round(this.props.amountInAdvance);
    }

    feesAmount() {
        return this.props.feesAmount
    }

    renderInstallmentsAdvancePayment() {
        return (
            <div className="advance-payment-container">
                <p className="advance-payment-text">Anticipo</p>
                <p className="advance-payment-amount">${this.advancePayment().toLocaleString()}</p>
            </div>
        );
    }

    _renderTotalFee(totalAmount, cssClass, itsFinanced = false) {
        const title = itsFinanced ? 'Costo Total del servicio financiado' : 'Total costo por servicio'
        return <div className={cssClass}>
            <p>{title}:</p>
            <p>${totalAmount.toLocaleString()}</p>
        </div>
    }

    renderFeesPayment(feesQuantity, feesAmount) {
        return <div className="amount-paids">
            <div className="paid-number">
                <p className="highlight-numbers">{feesQuantity}</p>
                <p>cuotas de</p>
            </div>
            <p className="highlight-numbers total">${feesAmount.toLocaleString()}</p>
        </div>;
    }

    // Mercadopago installment plan chosen

    _isPreferentialInstallmensSelected() {
        return this.props.feesQuantity > 1 && this.props.feesQuantity < 4;
    }

    // Cash selected

    _isCashPaymentSelected() {
        return this.props.feesQuantity === 1;
    }

    renderCashTotal() {
        return (
            <div className="total-paid mercadopago-total-footer">
                <div className="amount-paids">
                    <div className="paid-number">
                        <p>Costo original del Servicio</p>
                    </div>
                    <p className="total">${this.props.totalFeeAmountWithoutDiscounts.toLocaleString()}</p>
                </div>
                <div className="amount-paids">
                    <div className="paid-number">
                        <p> Descuento {this.props.discountPercentage.toLocaleString()}%</p>
                    </div>
                    <p className="total">-${this.props.discountFee.toLocaleString()}</p>
                </div>
                {this._renderTotalFee(this.totalAmountForCashPayment(), "amount-paids")}
            </div>
        );
    }

    renderCashPayment(amount, isACashPayment = true) {
        return <React.Fragment>
            <div className="amount-paids">
                <div className="paid-number">
                    <p className="highlight-numbers">1</p>
                    <p>pago</p>
                </div>
                <p className="highlight-numbers total">${amount.toLocaleString()}</p>
            </div>
            {isACashPayment ?
                <div className="amount-paids">
                    <p className="discount-note-text"> (con {this.props.discountPercentage}% de descuento incluído)</p>
                </div>
                :
                <div></div>
            }
        </React.Fragment>
    }

    totalAmountForCashPayment() {
        let total = this.props.totalAmount;
        return Math.round(total);
    }

    // Render Box

    _installmentCalculatorBox(label, title, footNote) {
        if (!this.isFeesAmountPermitted()) {
            return null
        }
        const renderAdvancePayment = this.renderInstallmentsAdvancePayment();
        const renderFees = this.renderFeesPayment(this.props.feesQuantity - 1, this.feesAmount());
        const renderTotal = this._renderTotalFee(this.props.totalAmount, "total-paid");
        return this._calculatorBox(label, title, renderFees, renderTotal, footNote, renderAdvancePayment);
    }

    _installmentsCalculatorBox() {
        const title = `Hasta ${this.props.feesQuantity} cuotas`;
        return this._installmentCalculatorBox("mp-label", title, this._feesQuantityFootNote())
    }

    _feesQuantityFootNote() {
        const defaultFeesAmount = this.feesAmount() === 0;
        const feesQuantity = this.props.feesQuantity;
        return defaultFeesAmount ? '' : this.INSTALLMENTS_INTEREST_RATES_FOOT_NOTES[feesQuantity];
    }

    _upToThreeInstallmentsCalculatorBox() {
        const title = `${this.props.feesQuantity} cuotas sin interés`;
        return this._installmentCalculatorBox("installment-label", title)
    }

    _cashCalculatorBox() {
        let title = `${this.props.discountPercentage}% OFF - Pago contado`;
        let renderFees = this.renderCashPayment(this.totalAmountForCashPayment(), true);
        let renderTotal = this.renderCashTotal();
        return this._calculatorBox("cash-label", title, renderFees, renderTotal, this.cashPaymentFootNote());
    }

    cashPaymentFootNote() {
        // Pasarlo a un único lugar
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const day = new Date(year, month, 0).getDate();
        return `Promoción válida hasta el ${day}/${month}/${year}`;
        // return `Promoción válida hasta el 07/07/2024`;
    }

    renderLoadingBox() {
        return (
            <div className="information-paids-box  col m6 col s12">
                <LoaderSection/>
            </div>
        )
    }

    isFeesAmountPermitted() {
        return this.props.isFeesAmountPermitted
    }

    renderBoxContent(renderFees, renderTotal, footNote, renderAdvancePayment) {
        return (
            <div className="information-paids-box col m6 col s12">
                {renderAdvancePayment}
                {renderFees}
                {renderTotal}
                <div className="calculator-footer">
                    {footNote}
                </div>
            </div>
        )
    }

    _calculatorBox(label, title, renderFees, renderTotal, footNote = '', renderAdvancePayment = undefined) {
        return (
            <div className={`calculator-control-result ${label}`}>
                <div className="calculator-control-label">{title}</div>
                {this.props.loading ? this.renderLoadingBox() : this.renderBoxContent(renderFees, renderTotal, footNote, renderAdvancePayment)}
            </div>
        );
    }

    render() {
        if (this._isPreferentialInstallmensSelected()) {
            return this._upToThreeInstallmentsCalculatorBox();
        }
        if (this._isCashPaymentSelected()) {
            return this._cashCalculatorBox()
        }
        return this._installmentsCalculatorBox()
    }
}

CalculatorResult.propTypes = {
    mercadoPagoSurchargePercentage: PropTypes.number,
    loading: PropTypes.bool,
    discountPercentage: PropTypes.number,
    mercadoPagoDiscount: PropTypes.number,
    feesQuantity: PropTypes.number,
    totalAmount: PropTypes.number,
    feesAmount: PropTypes.number,
    amountInAdvance: PropTypes.number,
    totalFeeAmountWithoutDiscounts: PropTypes.number,
    discountFee: PropTypes.number,
    isFeesAmountPermitted: PropTypes.bool
}