import {
    BirthdayDateField,
    CityAutoCompleteField,
    DocumentField,
    Field,
    ProvinceAutoCompleteField,
    RealEstateAutoCompleteField
} from "./Field";
import {app} from "../app/app";


export class Form {
    constructor(name, fields) {
        this.name = name;
        this._saver = this.newSaverInstance();
        this.initializeFields(fields);
        this.resetDirtyFields();
    }

    initializeFields(fields) {
        this.fields = {};
        fields.forEach((field) => {
            field.setForm(this);
            this.fields[field.name()] = field
        });
    }

    saver() {
        return this._saver;
    }

    newSaverInstance() {
        throw new Error('Subclass responsibility');
    }

    resetDirtyFields() {
        this.dirtyFieldsNames = [];
    }

    setDirtyField(field) {
        const fieldName = field.name();
        if(!this.dirtyFieldsNames.includes(fieldName)) {
            this.dirtyFieldsNames.push(fieldName);
        }
    }

    isDirty(field) {
        return this.dirtyFieldsNames.includes(field.name());
    }

    fieldsToUpdate() {
        let fields = {};

        let dirtyFields = this.dirtyFields();
        for(let fieldName in dirtyFields) {
            let field = dirtyFields[fieldName];

            if(field.shouldBeSaved()) {
                fields[fieldName] = field.value();
            }
        }

        return fields;
    }

    resetFieldErrors() {
        this.onFieldsDo((name, field) => { field.resetErrors(); });
    }

    onFieldsDo(aFunction) {
        for(let fieldName in this.fields) {
            let field = this.fieldNamed(fieldName);
            aFunction(fieldName, field);
        }
    }

    fieldsByName() {
        return this.fields;
    }

    fieldNamed(fieldName) {
        return this.fields[fieldName];
    }

    valueOfFieldNamed(aFieldName) {
        let field = this.fieldNamed(aFieldName);
        return field.value()
    }

    hasFieldNamed(fieldName) {
        return fieldName in this.fields;
    }

    setInitialData(data) {
        for(let fieldName in data) {
            if(this.hasFieldNamed(fieldName)) {
                let field = this.fieldNamed(fieldName);
                field.setInitialValue(data[fieldName]);
            }
        }

        this.resetDirtyFields();
    }

    updateFieldValue(fieldName, value) {
        let field = this.fieldNamed(fieldName);

        this.resetFieldErrors();
        field.updateValue(value);
        field.markAsDirty();
    }

    dirtyFields() {
        let dirtyFields = {};
        this.dirtyFieldsNames.forEach((fieldName) => { dirtyFields[fieldName] = this.fieldNamed(fieldName) });

        return dirtyFields;
    }

    fieldCount() {
        return Object.keys(this.fields).length;
    }

    requiredFieldCount() {
        let fieldCount = 0;
        this.onFieldsDo((fieldName, field) => {
           if(field.isRequired()) {
               fieldCount += 1;
           }
        });

        return fieldCount;
    }

    save(onSuccess) {
        this.saver().save(this, onSuccess);
    }
}


export class ParticipantForm extends Form {
    static
    typeOfData() {
        return 'participantData'
    }

    constructor(name, participantId, regionsData) {
        const provinces = regionsData.provinces();
        const cities = regionsData.cities();

        let docTypeField = new Field("docType");
        let docNumberField = new Field("docNumber");

        docTypeField.isRelatedTo(docNumberField);
        docNumberField.isRelatedTo(docTypeField);

        const fields = [
            new Field("firstName"),
            new Field("lastName"),
            docTypeField,
            docNumberField,
            new Field("cellPhoneAreaCode"),
            new Field("cellPhoneNumber"),
            new Field("email"),
            new Field("addressStreet"),
            new Field("addressStreetNumber"),
            new Field("addressStreetFloor", false),
            new Field("addressStreetApartment", false),
            new ProvinceAutoCompleteField("addressProvince", provinces),
            new CityAutoCompleteField("addressCity", cities),
            new Field("sex"),
            new Field("liveInTheProperty"),
            new BirthdayDateField("birthdate"),
            new Field("employeeType"),
            new Field("currentCompanyCuit"),
            new Field("currentCompany"),
            new Field("jobAddressStreet"),
            new Field("jobAddressStreetNumber"),
            new Field("jobAddressStreetFloor", false),
            new Field("jobAddressStreetApartment", false),
            new ProvinceAutoCompleteField("jobAddressProvince", provinces),
            new CityAutoCompleteField("jobAddressCity", cities),
            new Field("jobPhoneAreaCode"),
            new Field("jobPhoneNumber"),
            new Field("cuit"),
            new Field("seniority"),
            new Field("income"),
            new Field("stateEmail"),
        ];

        super(name, fields);

        this._participantId = participantId;
    }

    warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    identifier() {
        return ParticipantForm.typeOfData();
    }

    newSaverInstance() {
        let delayWhenSaving = new DelaySaving((fields, callback) => {
            const warrantyApplicationNumber = this.warrantyApplicationNumber();
            app.apiClient().editParticipant(
                warrantyApplicationNumber, fields, this.typeOfParticipant(), this.participantId(), callback
            );
        }, 750);

        return new FormSaver(delayWhenSaving);
    }

    typeOfParticipant() {
        return this.name
    }

    participantId() {
        return this._participantId;
    }
}


export class DocumentsForm extends Form {
    static
    typeOfData() {
        return 'documentsData'
    }

    constructor(name, participantId, controlledDocuments) {
        const fields = [
            new DocumentField("documents")
        ];

        super(name, fields);

        this._participantId = participantId;
        this._controlledDocuments = controlledDocuments || [];
    }

    warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    identifier() {
        return DocumentsForm.typeOfData();
    }

    newSaverInstance() {
        let saveNow = new SaveNow((fields, callback) => {
            const documentsField = this.fieldNamed('documents');
            const filesToSend = documentsField.unSentFiles();
            const warrantyApplicationNumber = this.warrantyApplicationNumber();

            return app.apiClient().addParticipantDocuments(
                warrantyApplicationNumber, this.typeOfParticipant(), this.participantId(), filesToSend, callback
            )
        });

        return new FormSaver(saveNow);
    }

    typeOfParticipant() {
        return this.name
    }

    participantId() {
        return this._participantId;
    }

    controlledDocuments(){
        return this._controlledDocuments;
    }
}

export class PropertyForm extends Form {
    static
    typeOfData() {
        return 'propertyData'
    }

    constructor(name, regionsData, realStateOptions) {
        const provinces = regionsData.provinces();
        const cities = regionsData.cities();

        const cityAutoCompleteField = new CityAutoCompleteField("addressCity", cities);
        const provinceAutoCompleteField = new ProvinceAutoCompleteField("addressProvince", provinces);

        cityAutoCompleteField.isRelatedTo(provinceAutoCompleteField);
        provinceAutoCompleteField.isRelatedTo(cityAutoCompleteField);

        const fields = [
            new Field("addressStreet"),
            new Field("addressNumber"),
            new Field("addressFloor", false),
            new Field("addressApartment", false),
            cityAutoCompleteField,
            provinceAutoCompleteField,
            new Field("rentalType"),
            new Field("rentalLength"),
            new Field("rentalPeriod"),
            new Field("amountPeriod1"),
            new Field("expenses"),
            new Field("isContractExtension"),
            new RealEstateAutoCompleteField("realState", realStateOptions),
        ];

        super(name, fields);
    }

    warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    newSaverInstance() {
        let delaySaving = new DelaySaving((fields, callback) => {
            const warrantyApplicationNumber = this.warrantyApplicationNumber();
            app.apiClient().editProperty(warrantyApplicationNumber, fields, callback);
        }, 750);
        return new FormSaver(delaySaving);
    }
}


export class CompanyAgreementForm extends Form {
    static
    typeOfData() {
        return 'propertyData'
    }

    constructor(name) {
        const fields = [
            new Field("companyAgreement")
        ];

        super(name, fields);
    }

    warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    newSaverInstance() {
        let saveNow = new SaveNow((fields, callback) => {
            const warrantyApplicationNumber = this.warrantyApplicationNumber();
            app.apiClient().editCompanyAgreement(warrantyApplicationNumber, fields, callback);
        });
        return new FormSaver(saveNow);
    }
}


export class FormProgressCalculator {
    constructor(form) {
        this.form = form
    }

    progress() {
        return Math.min(Math.round((this.completedFieldCount() / this.totalFieldCount()) * 100), 100);
    }

    completedFieldCount() {
        let completedFields = 0;

        this.form.onFieldsDo((name, field) => {
            if (field.isRequired() && field.isCompleted()) {
                completedFields += 1;
            }
        });

        return completedFields
    }

    totalFieldCount() {
        return this.form.requiredFieldCount();
    }
}


export class DocumentsFormProgressCalculator extends FormProgressCalculator {
    constructor(form, employeeType) {
        super(form);

        this.employeeType = employeeType
    }

    completedFieldCount() {
        return this.form.controlledDocuments().length;
    }

    totalFieldCount() {
        switch (this.employeeType) {
            case 'RESPONSABLE INSCRIPTO/AUTONOMO':
                return 10;
            case 'MONOTRIBUTISTA':
                return 6;
            case 'EMPLEADO':
                return 2;
            default:
                return 1
        }
    }
}


export class FormSaver {
    constructor(savingStrategy) {
        this.savingStrategy = savingStrategy;
    }

    save(form, onSave) {
        let fieldsToUpdate = form.fieldsToUpdate();
        this.saveFields(fieldsToUpdate, onSave);
        form.resetDirtyFields();
    }

    saveFields(fields, onSave) {
        this.savingStrategy.saveWhenNecessary(fields, onSave)
    }
}


export class FormSavingStrategy {
    constructor(apiCall) {
        this.apiCall = apiCall;
    }

    saveWhenNecessary(fields, onSuccess) {
        throw new Error('Subclass responsibility');
    }
}

export class SaveNow extends FormSavingStrategy {
    saveWhenNecessary(fields, onSuccess) {
        this.apiCall(fields, onSuccess);
    }
}

export class DelaySaving extends FormSavingStrategy {
    constructor(apiCall, delayInMilliseconds) {
        super(apiCall);

        this.lastCallback = null;
        this.timer = null;
        this.delayInMilliseconds = delayInMilliseconds;
        this.fieldsToUpdate = {};
    }

    updateFields(newValues) {
        Object.assign(this.fieldsToUpdate, newValues);
    }

    timerIsRunning() {
        return this.timer !== null;
    }

    startTimer() {
        this.timer = setTimeout(this.saveAndReset.bind(this), this.delayInMilliseconds);
    }

    destroyTimer() {
        this.timer = null;
    }

    stopTimer() {
        clearInterval(this.timer);
        this.destroyTimer();
    }

    restartTimer() {
        this.stopTimer();
        this.startTimer();
    }

    saveWhenNecessary(fields, onSuccess) {
        this.updateFields(fields);
        if(this.timerIsRunning()) {
            this.restartTimer();
        } else {
            this.startTimer();
        }

        this.lastCallback = onSuccess;
    }

    saveAndReset() {
        this.makeApiCall();
        this.reset();
    }

    reset() {
        this.fieldsToUpdate = {};
        this.lastCallback = null;
        this.destroyTimer();
    }

    makeApiCall() {
        this.apiCall(this.fieldsToUpdate, this.lastCallback);
    }
}