import React, {Component} from 'react';
import Link from "react-router-dom/Link";

export default class FailureMessageWithMaximumNumberOfCoApplicants extends Component {
    render() {
        return (
            <div className="pre-request-result">
                <p className="secondary-title">¡Estamos a punto de terminar!</p>
                <p className="exclamation-text">Pero todavía necesitamos ingresar un nuevo Co-Solicitante para cumplir con nuestros requisitos</p>

                <p className="basic-text">Recordá que tu Solicitud está casi lista. Podrás consultarla y
                    completarla cuando lo desees.</p>
                <button className="main-btn" onClick={this.props.handleRedirectToWarrantyRequest}>
                    Avanzá tu Solicitud
                </button>

                <div className="contact">
                    <p>Si deseás más información, recordá que siempre podés comunicarte con la
                        <Link className="link" to="/sucursales" target="_blank">Oficina Finaer más cercana</Link>
                    </p>
                </div>
            </div>

        )
    }
}
