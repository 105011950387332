import React from 'react';
import CircularProgressbar from "react-circular-progressbar";
import {Collapsible, CollapsibleItem, Preloader} from "react-materialize";

export class NavBarItemCollapsible extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.isActive = this.isActive.bind(this);
        this.coApplicantIsActive = this.coApplicantIsActive.bind(this);
        this.addCoApplicant = this.addCoApplicant.bind(this);
        this.fullName = this.fullName.bind(this);
        this.maxAmountOfCoApplicants = this.maxAmountOfCoApplicants.bind(this);
    }

    isActive() {
        return this.props.active === "CoApplicant";
    }

    coApplicantIsActive(coApplicantId) {
        return coApplicantId === this.props.actualCoApplicantId;
    }

    addCoApplicant() {
        this.setState({loading: true});
        this.props.addCoApplicant(() => this.setState({loading: false}));
    }

    maxAmountOfCoApplicants(){
        return Object.keys(this.props.coApplicants).length >= 5
    }

    fullName(coApplicantId) {
        let name = '';
        const firstName = this.props.coApplicants[coApplicantId].participantData.firstName.value() || '';
        const lastName = this.props.coApplicants[coApplicantId].participantData.lastName.value() || '';

        if(!firstName && !lastName) {
            return 'Sin nombre'
        }

        name += firstName;
        if(firstName && lastName) {
            name += ' ';
        }
        name += lastName;

        return name;
    }

    render() {
        return (
            <div className={this.isActive() ? "collapsible-item-container -active" : "collapsible-item-container"}>
                <CircularProgressbar className="section-progressbar" percentage={this.props.percentage}
                                     text={this.props.percentage + '%'} strokeWidth="7"/>
                <Collapsible accordion={false} className="collapsible-item" onSelect={this.props.onClick}>
                    <CollapsibleItem header="Co-Solicitante" className={this.isActive() ? '' : 'inactive'}>
                        <ul>
                            {Object.keys(this.props.coApplicants).map(coApplicantId => {
                                return (
                                    <li key={coApplicantId}>
                                        <button className={this.coApplicantIsActive(coApplicantId) ?
                                            "co-solicitant-id -active" : "co-solicitant-id"}
                                                onClick={() => this.props.changeCoApplicant(coApplicantId)}>
                                            {this.fullName(coApplicantId)}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                        <button onClick={this.addCoApplicant} className="main-btn" disabled={this.state.loading || this.maxAmountOfCoApplicants()}>
                            {this.state.loading ? <Preloader className="btn-spinner" size='small'/> : 'Agregar nuevo Co-Solicitante'}
                        </button>
                    </CollapsibleItem>
                </Collapsible>
            </div>
        );
    }
}
