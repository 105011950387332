import React from 'react';
import Link from "react-router-dom/es/Link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class DashboardNavButton extends React.Component {
    render() {
        return (
            <Link className={"dashboard-nav-btn" + this.props.class} to={this.props.to}>
                <FontAwesomeIcon className="icon" icon={this.props.icon}/>
                <p>{this.props.text}</p>
                <FontAwesomeIcon className="caret" icon="caret-right"/>
            </Link>
        );
    }
}
