import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Link from "react-router-dom/es/Link";
import calculadora from "../../images/calculadora.svg";
import {MainButton} from "../../components/buttons/MainButton";
import {ContactModal} from "../../components/modals/ContactModal";

export class RequirementsAside extends React.Component {

    render() {
        return (
            <aside className="section-aside">
                <div className="aside-block">
                    <img src={calculadora} className="calculadora" alt="ilustración de una calculadora"/>
                    <div className="btn-list">
                        <MainButton to="/calculadora" text="Calcular Costos"/>
                    </div>
                </div>
                <MainButton to="/inicio" text="Solicitar Garantía"/>
                <div className="aside-links">
                    <div className="link">
                        <Link className="link-btn" to="/sucursales">
                            <FontAwesomeIcon className="icon" icon="map-marker-alt"/>
                            <p>Oficinas Finaer</p>
                        </Link>
                    </div>
                    <div className="link">
                        <ContactModal class="link-btn" icon="envelope" button="¿Alguna duda? Consultanos"/>
                    </div>
                </div>
            </aside>
        );
    }
}
