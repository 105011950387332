import React from "react";
import {Modal} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CloseButton} from "../buttons/CloseButton";

export class HelperModal extends React.Component {
    render() {
        return (
            <Modal className="helper-modal" header={this.props.title}
                   trigger={
                       <button  className={` helper-modal-btn ${this.props.buttonClassName}`}>
                           <FontAwesomeIcon className="helper-icon" icon="info-circle"/>
                       </button>
                   }>
                <div className="modal-body">
                    <div className="modal-main">
                        <div className="helper-modal-header">
                            <FontAwesomeIcon className="icon" icon="info-circle"/>
                        </div>
                        {this.props.text}
                        <CloseButton/>
                    </div>
                </div>
            </Modal>
        );
    }
}
