import React, {Component} from 'react';
import RequestNav from "../components/layout/RequestNav.js";
import {DashboardNavButton} from "../components/buttons/DashboardNavButton";
import CircularProgressbar from 'react-circular-progressbar';
import {Loader} from "../components/elements/Loader";
import {app} from "../app/app";
import {HelperModal} from "../components/modals/HelperModal";
import {RequestStatusModal} from "../components/modals/RequestStatusModal";
import {PaymentMethodModal} from "../components/modals/PaymentMethodModal";
import {DashboardMainNavButton} from "../components/buttons/DashboardMainNavButton";
import {MercadopagoStatusModal} from "../components/modals/MercadopagoStatusModal";

class DashboardRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            errorMessage: undefined,
            requestDetails: {},
            paymentInformation: null,
        };

        this.handleApiResponse = this.handleApiResponse.bind(this);
        this.successMessageIfApplicationIsCompleted = this.successMessageIfApplicationIsCompleted.bind(this);
        this.completeButtonIfApplicationIsEditable = this.completeButtonIfApplicationIsEditable.bind(this);
        this.redirectToPayment = this.redirectToPayment.bind(this);
        this.clearLocationSearch = this.clearLocationSearch.bind(this);
    }

    _warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    _mercadopagoRedirectPaymentInfo(){
        const search = this.props.location.search;
        let status = new URLSearchParams(search).get('status');
        let message = new URLSearchParams(search).get('message');
        let paymentInformation =  {
            "status": status,
            "message": message
        }
        return status && message ? paymentInformation : null;
    }

    handleApiResponse(response) {
        if (response.hasErrors()) {
            this.setState({errorMessage: response.nonFieldErrors(), loading: false});
        } else {
            const applicantName = response.requestDetails()['applicant'];
            app.loggedInUser().update(applicantName);
            this.setState({requestDetails: response.requestDetails(),
                                paymentInformation: this._mercadopagoRedirectPaymentInfo(),
                                loading: false});
        }
    }

    componentWillMount() {
        const warrantyApplicationNumber = this._warrantyApplicationNumber();
        app.apiClient().getRequestDetails(warrantyApplicationNumber, this.handleApiResponse);
    }

    officeData() {
        return (
            <div className="assigned-office">
                <p className="label">Oficina Asignada</p>
                <p className="office">{this.state.requestDetails['office']['name']}</p>
                <p>{this.state.requestDetails['office']['address']}</p>
                <p>{this.state.requestDetails['office']['location']}</p>
                <a href={"tel:" + this.state.requestDetails['office']['phone']}>
                    {this.state.requestDetails['office']['phone']}
                </a>
                <a href={"mailto:" + this.state.requestDetails['office']['email']}>
                    {this.state.requestDetails['office']['email']}
                </a>
            </div>
        )
    }

    applicationIsCompleted() {
        return this.state.requestDetails['progress'] >= 80;
    }

    successMessageIfApplicationIsCompleted() {
        if (!this.applicationIsCompleted()) {
            return null;
        }

        return (
            <div className="completed-forms-message">
                <p className="title">¡Felicitaciones!</p>
                <p className="message">Próximamente nuestra Oficina Comercial te estará contactando</p>
            </div>
        )
    }

    completeButtonIfApplicationIsEditable() {
        const state = this.state.requestDetails['state'];
        let nav_button_class = "";
        if (state === 'NUEVA' || state === 'INCOMPLETA') {
            if (this.props.location.state) {
                nav_button_class = this.props.location.state.active;
            }
            return (<DashboardNavButton class={nav_button_class} to="/formulario" icon="edit" text="Completar Solicitud"/>)
        }
    }

    redirectToPayment() {
        this.props.history.push({
            pathname: '/pagar_garantia',
            state: {
                office: this.state.requestDetails['office'],
                fee: this.state.requestDetails['fee'],
                transferDiscount: this.state.requestDetails['transferDiscount'],
                cashDiscount: this.state.requestDetails['cashDiscount'],
                twoOrThreeInstallmentsDiscount: this.state.requestDetails['twoOrThreeInstallmentsDiscount'],
                applicantEmail: this.state.requestDetails['applicantEmail'],
                sixInstallmentsAdvanceAmount: this.state.requestDetails['sixInstallmentsAdvanceAmount'],
                sixInstallmentsAmount: this.state.requestDetails['sixInstallmentsAmount'],
                fiveInstallmentsAdvanceAmount: this.state.requestDetails['fiveInstallmentsAdvanceAmount'],
                fiveInstallmentsAmount: this.state.requestDetails['fiveInstallmentsAmount'],
                fourInstallmentsAdvanceAmount: this.state.requestDetails['fourInstallmentsAdvanceAmount'],
                fourInstallmentsAmount: this.state.requestDetails['fourInstallmentsAmount'],
                threeInstallmentsAdvanceAmount: this.state.requestDetails['threeInstallmentsAdvanceAmount'],
                threeInstallmentsAmount: this.state.requestDetails['threeInstallmentsAmount'],
                twoInstallmentsAdvanceAmount: this.state.requestDetails['twoInstallmentsAdvanceAmount'],
                twoInstallmentsAmount: this.state.requestDetails['twoInstallmentsAmount'],
            }
        });
    }


    buttonWarrantyMethodPayment(nav_button_class) {
        if (this.state.requestDetails['showPaymentMethod'] === 'true'){
            return <PaymentMethodModal class={nav_button_class + ' payment-btn'}
                                       office={this.state.requestDetails['office']}/>
        } else if (this.state.requestDetails['showChoosePayment'] === 'true'){
            return <DashboardMainNavButton class={nav_button_class + ' payment-btn'}
                                           icon='money-bill'
                                           onClick={this.redirectToPayment}
                                           text="Pagar tu garantia"/>
        }
    }

    content() {
        const warrantyApplicationNumber = this._warrantyApplicationNumber();
        let nav_button_class = "";
        if (this.state.loading) {
            return <Loader message="Espere unos segundos mientras recuperamos los datos"/>;
        } else {
            if (this.props.location.state) {
                nav_button_class = this.props.location.state.disabled;
            }
            return (
                <main>
                    <div className="dashboard-col">
                        <h1>Solicitud de garantía</h1>
                        <div className="request-code">
                            <p>Código de solicitud:</p>
                            <p className="code">{warrantyApplicationNumber}</p>
                        </div>
                        <div className="helper-modal-container request-state">
                            <p>Estado:</p>
                            <RequestStatusModal requestState={this.state.requestDetails['state']} requestOffice={this.state.requestDetails['office']}/>
                        </div>
                        <div className="dashboard-nav">
                            { this.completeButtonIfApplicationIsEditable() }
                            { this.buttonWarrantyMethodPayment(nav_button_class) }
                            <DashboardNavButton class={nav_button_class} to="/historial" icon="clock" text="Historial de Solicitudes"/>
                            <DashboardNavButton class={nav_button_class} to="/calculadora" icon="calculator" text="Calcular Costos"/>
                            <div className="helper-modal-container">
                                <HelperModal
                                    text={this.officeData()}/>
                                <p className="helper-modal-text">Oficina Asignada</p>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-col -right">
                        <CircularProgressbar
                            className="dashboard-progressbar"
                            percentage={this.state.requestDetails['progress']}
                            text={this.state.requestDetails['progress'] + "%"}
                            strokeWidth="5"/>



                        {this.successMessageIfApplicationIsCompleted()}
                        {this.officeData()}
                    </div>
                </main>
            )
        }
    }

    clearLocationSearch() {
        this.props.history.replace()
    }

    _mercadoPagoRedirectModal(){
        const paymentInformation = this.state.paymentInformation;
        if (paymentInformation !== null){
            return <MercadopagoStatusModal
                    office={this.state.requestDetails['office']}
                    onClose={this.clearLocationSearch}
                    paymentInformation={paymentInformation}/>
        }
    }

    render() {
        return (
            <div className="layout-request dashboard">
                <RequestNav/>
                {this._mercadoPagoRedirectModal()}
                {this.content()}
            </div>
        );
    }
}

export default DashboardRequest;
