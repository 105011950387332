import React, { Component } from "react";
import '../styles/App.scss';
import { app } from "../app/app";

class InsuranceInformationRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applicant_name : ''
        }
    }

    componentDidMount() {
        app.apiClient().sendEmailInsuranceInformationRequested(this.props.match.params.nro).then(result => {
            let applicant = result.applicant_name();
            this.setState({
                applicant_name: applicant
            });
        })

    }

    render() {
        return (
            <table style={styles.table} border="0" align="center" cellPadding="0" cellSpacing="0">
                <tr>
                    <td width="600">
                        <table width="600" border="0" align="center" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td><a href="/"><img
                                    src="https://ftp.finaersa.com.ar/newsletter/mails.sistema.2024/solicitante_seguro/img/respuesta.jpg" alt=""
                                    width="600" height="490" useMap="#Map" border="0"/></a></td>
                            </tr>
                            <tr/>
                        </table>
                    </td>
                </tr>
            </table>
        );
    }
}

const styles = {
    table: {
        width: 600
    }
}

export default InsuranceInformationRequest;
