import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class RightButton extends React.Component {
    render() {
        return (
            <button className="direction-btn -right" onClick={this.props.onClick}>
                <FontAwesomeIcon className="icon" icon="arrow-right"/>
            </button>
        );
    }
}
