import React from "react";
import {FinaerSelect} from "../../elements/Select";

/*
* Wraps information of an installment returned by MercadoPago
* */
class InstallmentMercadoPago {
    constructor(labels, amountOfInstallments, installmentsFee, originalFee, fee, installmentRate) {
        this._cftAndLeaLabel = labels; //Not used yet, but will be needed
        this._amountOfInstallments = amountOfInstallments;
        this._installmentsFee = installmentsFee;
        this._originalTotalFee = originalFee;
        this._totalFeeWithMPSurcharges = fee;
        this._message = this._createMessage();
        this._installmentRate = installmentRate;
    }

    _createMessage() {
        const totalFee = Math.round(this._totalFeeWithMPSurcharges);
        const installmentSpec = this._amountOfInstallments === 1 ? 'cuota' : 'cuotas';
        return `${this._amountOfInstallments} ${installmentSpec} de ${this._installmentsFee} ($ ${totalFee})`;
    }

    message() {
        return this._message;
    }

    amountOfInstallments() {
        return this._amountOfInstallments;
    }

    originalTotalFee() {
        return this._originalTotalFee;
    }

    installmentRate() {
        return this._installmentRate;
    }
}

/*
* Keeps installments returned by MercadoPago
* First installments keep soriginal fee and ignores information returned
* restriction added by business logic
* */
export class InstallmentsMercadoPago {
    constructor(installmentsData, originalFee) {
        this._installments = installmentsData.map((installmentData, index) => {
            if (index === 0) {
                return this._createInstallment([], 1, originalFee, originalFee, originalFee, 0);
            }
            return this._createInstallmentForData(installmentData, originalFee);
        })
    }

    map(toApply) {
        return this._installments.map(toApply);
    }

    getInstallmentWith(amountOfInstallments) {
        return this._installments.find(installment =>
            installment.amountOfInstallments() === amountOfInstallments)
    }

    _createInstallment(labels, amountOfInstallments, installmentFee, originalFee, fee, installmentRate) {
        return new InstallmentMercadoPago(
            labels, amountOfInstallments, installmentFee, originalFee, fee, installmentRate
        );
    }

    _createInstallmentForData(installmentData, originalTotalFee) {
        return this._createInstallment(
            installmentData.labels[0],
            installmentData.installments,
            installmentData.installment_amount,
            originalTotalFee,
            installmentData.total_amount,
            installmentData.installment_rate
        )
    }
}

export class SelectInstallments extends React.Component {
    constructor(props) {
        super(props);
        this._installments = this.props.installments;
    }

    _onInstallmentSelected = (amountOfInstallmentSelected) => {
        let installmentSelected = this._installments.getInstallmentWith(amountOfInstallmentSelected);
        this.props.onInstallmentsSelected(installmentSelected);
    }

    _renderToSelect() {
        return this._installments.map((installment) => {
            return {
                label: installment.message(),
                value: installment.amountOfInstallments()
            };
        })
    }

    _showInstallmentsToSelect() {
        return (
            <div className="react-select -padding-adjustment">
                <FinaerSelect
                    onChange={(event) => {
                        this._onInstallmentSelected(event.target.value);
                    }}
                    placeholder="Seleccione una opción"
                    options={this._renderToSelect()}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="select-installments-container">
                <h2>Elija la cantidad de cuotas</h2>
                {this._showInstallmentsToSelect()}
            </div>
        );
    }
}