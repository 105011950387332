import React from "react";
import PaymentBase from "../PaymentBase";
import {LeftButton} from "../../buttons/LeftButton";
import LoadCreditCard from "../mercadopago-payment/LoadCreditCard";
import {SelectInstallments, InstallmentsMercadoPago} from "../mercadopago-payment/InstallmentsMercadoPago";
import {NIDMercadopagoOptions} from "../mercadopago-payment/NIDMercadopago";
import {getSetting} from "../../../settings/settings";
import LoadNIDForm from "../mercadopago-payment/LoadNIDForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loader} from "../../elements/Loader";
import {PaymentCardMercadopago, PaymentCardMercadopagoNull} from "../mercadopago-payment/PaymentCardMercadopago";
import {PaymentErrorModal} from "../../modals/PaymentErrorModal";

const STEP_WARNING_CREDIT_CARD = 0;
const STEP_LOAD_CARD = 1;
const STEP_LOAD_NID = 2;
const STEP_SELECT_INSTALLMENTS = 3;


export default class SelectPaymentMercadopago extends PaymentBase {

    constructor(props) {
        super(props);
        this.name = 'Mercadopago';
        this.state = {
            mercadopago: undefined,
            cardData: this.cleanCardData(),
            nidData: this.cleanNidData(),
            creditCardToken: '',
            paymentMethod: new PaymentCardMercadopagoNull(),
            nidOptions: [],
            installments: [],
            installmentsSelected: undefined,
            step: STEP_WARNING_CREDIT_CARD,
            responseErrors: undefined,
            loading: false,
            paying: false
        }
        this._loadMercadoPagoScript();
        this.createPayment = this.createPayment.bind(this);
        this.guessPaymentMethod = this.guessPaymentMethod.bind(this);
    }

    cleanCardData() {
        return {
            creditCardNumber: {value: '', error: ''},
            cardholderName: {value: '', error: ''},
            securityCode: {value: '', error: ''},
            expirationDate: {year: '', month: '', error: ''},
        }
    }

    cleanNidData() {
        return {number: '', type: '', error: ''}
    }

    // Mercadopago API

    _loadMercadoPagoScript() {
        // Check https://github.com/mercadopago/sdk-js#checkout-api for documentation
        const script = document.createElement("script");

        script.src = "https://sdk.mercadopago.com/js/v2";
        script.async = true;
        script.onload = () => this.scriptLoaded();

        document.body.appendChild(script);
    }

    scriptLoaded() {
        if (window.MercadoPago !== undefined) {
            const publicKey = getSetting('MERCADOPAGO_PUBLIC_KEY');
            this.setState({mercadopago: new window.MercadoPago(publicKey)},
                async () => {
                    const ndiOptions = await this.state.mercadopago.getIdentificationTypes();
                    const options = new NIDMercadopagoOptions(ndiOptions);
                    this.setState({nidOptions: options});
                })

        }
    }

    async getIdentificationTypes() {
        await this.state.mercadopago.getIdentificationTypes();
    }

    async createCardToken() {
        let cardData = this.state.cardData;
        let nidData = this.state.nidData;
        let monthSelected = cardData.expirationDate.month;
        let cardExpirationMonth = monthSelected.length === 1 ? `0${monthSelected}` : monthSelected;
        return await this.state.mercadopago.createCardToken({
            cardNumber: cardData.creditCardNumber.value,
            cardholderName: cardData.cardholderName.value,
            cardExpirationMonth: cardExpirationMonth,
            cardExpirationYear: `20${cardData.expirationDate.year}`,
            securityCode: cardData.securityCode.value,
            identificationType: this.state.nidOptions.getByType(nidData.type).id(),
            identificationNumber: nidData.number,
        });
    }

    async guessPaymentMethod(cardNumber, onResponse) {
        if (cardNumber.length < 6) {
            return new PaymentCardMercadopagoNull();
        }
        let paymentMethod = await this.getPaymentMethod(cardNumber);
        let paymentCard = paymentMethod ? new PaymentCardMercadopago(paymentMethod) : new PaymentCardMercadopagoNull();
        this.setState({paymentMethod: paymentCard}, () => {
            onResponse(cardNumber, paymentCard);
        })
    };

    async getPaymentMethod(cardNumber) {
        let request = {"bin": this.state.paymentMethod.binFor(cardNumber)};
        try {
            let options = await this.state.mercadopago.getPaymentMethods(request);
            return options.results[0];
        } catch (e) {
            return undefined;
        }
    }

    async getInstallments() {
        const fee = this.getMercadoPagoFee();
        const request = {
            amount: fee.toString(),
            locale: 'es-AR',
            bin: this.state.paymentMethod.binFor(this.state.cardData.creditCardNumber.value),
            processingMode: 'gateway' // TODO: consultar con Mercadopago
        }
        let installmentsResponse;
        try {
            installmentsResponse = await this.state.mercadopago.getInstallments(request);
        } catch (e) {
            // TODO: manage error with mp down or with 500 error
            return;
        }
        return new InstallmentsMercadoPago(installmentsResponse[0].payer_costs, fee);
    }

    // View

    _isWaitingForAnswer() {
        return this.state.mercadopago === undefined ||
            this.state.nidOptions.length === 0 || this.state.loading;
    }

    _installmentsView() {
        return <div className="installments-container middle-bar-content payment-bar">
            {this._amountToPayMercadoPagoSection()}
            <SelectInstallments
                installments={this.state.installments}
                onInstallmentsSelected={
                    (installmentSelected) => {
                        this.setState({'installmentsSelected': installmentSelected})
                    }
                }
            />
            {this._promotionsFooter()}
        </div>
    }

    _warningCreditCard() {
        return <div className="card-warning-container">
            <p>
                A fin de evitar posibles demoras o rechazos en la transacción, sugerimos dar aviso a la tarjeta emisora previo a la realización de la compra.
            </p>
            <p> Encontrá el número de Atención al Cliente en el dorso de la tarjeta que utilices para el pago.</p>
        </div>
    }

    _creditCardView() {
        return <div className="card-data-container">
            <h2>Ingresa los datos de tu tarjeta</h2>
            <LoadCreditCard
                formData={this.state.cardData}
                paymentMethod={this.state.paymentMethod}
                guessPaymentMethod={this.guessPaymentMethod}
                nidOptions={this.state.nidOptions}
                updateFormData={(creditCardData) => {
                    this.setState({"cardData": creditCardData});
                }
                }
            />
        </div>
    }

    _nidCardView() {
        return <div className="card-data-container">
            <h2>Ingresa tus datos personales</h2>
            <LoadNIDForm
                nidData={this.state.nidData}
                nidOptions={this.state.nidOptions}
                updateFormData={(nidData) => {
                    this.setState({"nidData": nidData});
                    }}
            />
        </div>
    }

    _isCardDataValid = () => {
        let formData = this.state.cardData;
        return Object.values(formData).every(
            field => field.error === ''
        );
    }

    _isNidDataValid = () => {
        const nidData = this.state.nidData;
        return nidData.error === '' &&
            nidData.number !== '' && nidData.type !== '';
    }

    _continueToNidForm = () => {
        if (!this._isCardDataValid()) {
            return;
        }
        this._goToStep(STEP_LOAD_NID);
    }

    async _getInstallments(creditCardToken) {
        let installments = await this.getInstallments();
        this.setState({
            installments: installments,
            creditCardToken: creditCardToken,
            step: STEP_SELECT_INSTALLMENTS,
            loading: false
        });
    }

    _manageErrorInResponse(e) {
        if (e.status === 500) {
            this._restoreFormWithError('Algo salió mal desde Mercadopago, por favor intentá en unos minutos', false);
        } else {
            this._restoreFormWithError('Los datos ingresados son erroneos, por favor ingresalos nuevamente', true);
        }
    }

    continueToInstallments = async () => {
        if (this._isNidDataValid() === false) {
            return;
        }
        this.setState({loading: true}, async () => {
            try {
                let creditCardToken = await this.createCardToken();
                await this._getInstallments(creditCardToken);
            } catch (e) {
                return this._manageErrorInResponse(e);
            }
        });
    }

    _showFooter(onClick, handlePrevious, btnName) {
        return (
            <div className="arrows-payment">
                <LeftButton onClick={handlePrevious}/>
                <button
                    className={"main-btn confirm-payment-btn"}
                    disabled={this.state.paying}
                    onClick={onClick}>
                    {btnName}
                </button>
            </div>
        )
    }

    _goToStep(stepNumber) {
        this.setState({step: stepNumber});
    }

    _loadWarningCreditCardFooter() {
        let handleNext = () => {this._goToStep(STEP_LOAD_CARD)}
        return this._showFooter(handleNext, this.props.handlePrevious, 'Siguiente');
    }

    _loadCreditCardFooter() {
        return this._showFooter(this._continueToNidForm, this.props.handlePrevious, 'Siguiente');
    }

    _loadNidCardFooter() {
        let handlePrevious = () => {this._goToStep(STEP_LOAD_CARD)}
        return this._showFooter(this.continueToInstallments, handlePrevious, 'Seleccionar cuotas');
    }

    _selectInstallmentsFooter() {
        let handlePrevious = () => {this._goToStep(STEP_LOAD_NID)}
        return this._showFooter(this.createPayment, handlePrevious, 'Pagar');
    }

    _onPaymentRespond(response) {
        this.setState({paying: false}, () => {
            if (response.hasErrors()) {
                this._restoreFormWithError(response.errorMessage(), true);
            } else {
                this.props.handleMercadoPagoPaymentWithStatus(response.successfulCode(), response.successfulMessage());
            }
        })
    }

    _restoreFormWithError(error, restoreAllFields) {
        this.setState({
            'step': STEP_LOAD_CARD,
            'cardData': restoreAllFields ? this.cleanCardData() : this.state.cardData,
            'nidData': restoreAllFields ? this.cleanNidData() : this.state.nidData,
            'responseErrors': error,
            'loading': false
        });
    }

    createPayment() {
        let paymentCard = {
            "token": this.state.creditCardToken.id,
            "issuerId": this.state.paymentMethod.id()
        }
        this.setState({loading: true, paying: true}, () => {
            this.props.createMercadoPagoApiPayment(
                paymentCard,
                this.state.installmentsSelected,
                this.state.nidData,
                (response) => this._onPaymentRespond(response));
        })
    }

    title() {
        return <h1>Pago por Mercadopago</h1>
    }

    renderIndicators() {
        return Array(3).fill().map((_, i) => {
            const isActive = this.state.step === i + 1;
            return <FontAwesomeIcon key={i + 1} className={isActive ? "circle -active" : "circle"} icon="circle"/>
        });
    }

    _paymentResponseHadErrors() {
        return this.state.responseErrors !== undefined;
    }

    _displayErrorModal() {
        let onClose = () => {
            this.setState({responseErrors: undefined});
        }
        return <PaymentErrorModal
            onClose={onClose}
            message={this.state.responseErrors}/>;
    }

    body() {
        if (this._paymentResponseHadErrors()) {
            return this._displayErrorModal();
        }
        return (
            <div className="middle-bar-content payment-bar">
                <div className="mercadopago-container">
                    <div className="mercado-pago-payment">
                        {this._selectView()}
                    </div>
                    <div className="slider-nav">
                        {this.renderIndicators()}
                    </div>
                </div>
            </div>
        );
    }

    _selectView() {
        if (this._isWaitingForAnswer()) {
            return (<Loader message="Espere unos segundos mientras procesamos sus datos"/>);
        }
        if (this.state.step === STEP_WARNING_CREDIT_CARD) {
            return this._warningCreditCard();
        } else if (this.state.step === STEP_LOAD_CARD) {
            return this._creditCardView();
        } else if (this.state.step === STEP_LOAD_NID) {
            return this._nidCardView();
        }
        return this._installmentsView();
    }

    footer() {
        if (this.state.step === STEP_WARNING_CREDIT_CARD) {
            return this._loadWarningCreditCardFooter();
        } else if (this.state.step === STEP_LOAD_CARD) {
            return this._loadCreditCardFooter();
        } else if (this.state.step === STEP_LOAD_NID) {
            return this._loadNidCardFooter();
        }
        return this._selectInstallmentsFooter();
    }
}