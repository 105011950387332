import  ReactPixel from 'react-facebook-pixel';
import {getSetting} from "../settings/settings";

export function initFacebookPixel() {
    const fbPixelId = getSetting('FB_PIXEL_ID');
    const advancedMatching = {};
    const options = {
        autoConfig: true,
        debug: false, // enable logs
    };
    ReactPixel.init(fbPixelId, advancedMatching, options);

    ReactPixel.pageView(); // For tracking page view
}
