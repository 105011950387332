import React, {Component} from "react";
import MainNav from "../components/layout/MainNav";
import {Footer} from "../components/layout/Footer";
import {MainButton} from "../components/buttons/MainButton";
import CalculatorControl from "../components/calculator/CalculatorControl";
// import {app} from "../app/app";

// AGUS - DESACTIVAR PROMOCION

export default class CalculatorPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mercadoPagoDiscount: undefined
        }
    }

    // componentDidMount() {
    //     app.apiClient().mercadoPagoCyberWeekDiscount((response) => {this.setState({mercadoPagoDiscount: response})})
    // }

    render() {
        return (
            <div>
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <CalculatorControl mercadoPagoDiscount={this.state.mercadoPagoDiscount}/>
                        {this.renderPaidBox()}
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }

    cashPaymentFootNote(){
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const day = new Date(year, month, 0).getDate();
        return `Promoción válida hasta el ${day}/${month}/${year}`;
        // return `Promoción válida hasta el 07/07/2024`;
    }

    renderPaidBox() {
        return <section className="info-paids-box">
            <div className="primary-info">
                <p className="title">Formas de pago</p>
                <div className="primary-info-content">
                    <ul className="primary">
                        <li>Pago Contado<br/><span>20% OFF</span></li>
                        <p className="observation">{this.cashPaymentFootNote()}</p>
                    </ul>
                    <ul className="primary">
                        <li>3 Cuotas sin interés</li>
                    </ul>
                </div>
            </div>
            
            {/* <div className="primary-info">
                <p className="title">Formas de pago</p>
                <div className="primary-info-content">
                    <p className="title" style={{border: 'none', marginTop: '10px', marginBottom: '-10px'}}>SALE WEEK</p>
                    <ul className="primary">
                        <li>Pago Contado<br/><span>25% OFF</span></li>
                        <p className="observation">{this.cashPaymentFootNote()}</p>
                    </ul>
                </div>
            </div> */}

            {/* <div className="info-for-installents">
                <p className={'highlight-two'}>SALE Weeks</p>
                <p className={'highlight-one'}>¡es ahora!</p>
                <p className={'highlight-two'}>Pagá de contado con un 25%OFF</p>
                <p className={'highlight-two'}>o hasta en 6 cuotas sin interés</p>
            </div>
            <div className="info-for-installents">
                <p>6 Cuotas fijas</p>
                <p>sin tarjeta de crédito</p>
            </div> */}

            { this.state.mercadoPagoDiscount && this.state.mercadoPagoDiscount.isActive() ?
                <div className="info-for-installents">
                    <p>¡Aprovechá! ¡Finaer está de MEGA SALE! {this.state.mercadoPagoDiscount.percentage()}% OFF </p>
                    <p>pagando con Mercado Pago</p>
                </div>
                :
                <></>
            }
            <MainButton to="/inicio" text="Solicitar Garantía"/>
            <MainButton to="/requisitos" text="Requisitos"/>
        </section>;
    }

}
