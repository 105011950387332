import React, {Component} from 'react';
import '../styles/App.scss';
import MainNav from '../components/layout/MainNav';
import {Footer} from "../components/layout/Footer";
import facil from "../images/facil.svg";
import seguro from "../images/seguro.svg";
import confiable from "../images/confiable.svg";
import {InfoBox, UserBox} from "../components/home/Boxes";
import {ItemButton} from "../components/buttons/ItemButton";
import {MainButton} from "../components/buttons/MainButton";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {RegretModal} from "../components/modals/RegretModal";
import LandingBackground from "../components/home/LandingBackground";


class Home extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL;
        let fileUrl = publicUrl + '/contrato-adhesion.pdf';
        return (
            <div id="App">
                <MainNav/>
                <main>
                    <section className="site-cover">
                        <div className="site-content -home-align">
                            <h1><span>¡</span>Alquilá con<br/><span>Garantía Finaer</span>!</h1>
                            <p>Sin garantía propietaria <span>{"//"}</span> Sólo con tus ingresos</p>
                            <MainButton to="/inicio" text="Solicitá tu Garantía"/>
                        </div>
                        <div className="home-background-container">
                            <LandingBackground/>
                        </div>
                        <FontAwesomeIcon className="scroll-icon" icon="chevron-down"/>
                    </section>
                    <section id="top" className="home-section">
                        <div className="site-content">
                            <div id="institucional">
                                <h2>
                                    <div>
                                        <span>¡</span>La mejor<span> Garantía</span> para tu <span>Alquiler</span>!
                                    </div>
                                </h2>
                                <p>El Sistema de <span>Garantías Finaer</span> es la solución para inquilinos,
                                    propietarios e
                                    inmobiliarios para garantizar los contratos de alquiler.</p>
                                <p className="cuote">Tenés Finaer, tenés Garantía.</p>
                            </div>
                            <InfoBox/>
                            <UserBox/>
                        </div>
                    </section>
                    <section id="middle" className="home-section">
                        <div className="site-content">
                            <ItemButton icon="shield-alt" text="Nuestra Garantía" to="/garantia"
                                        description="Conocé más"/>
                            <ItemButton icon="umbrella" text="Seguros complementarios" to="/seguros-complementarios"
                                        description="Protección al 100%"/>
                            <ItemButton icon="file-alt" text="Contrato de adhesión" to={fileUrl} target="_blank"
                                        description="Descargalo"/>
                            <ItemButton icon="map-marker-alt" text="Oficinas Finaer" to="/sucursales"
                                        description="Ubicá la más cercana"/>
                            <ItemButton icon="key" text="Inmobiliarias Adheridas" to="/inmobiliarias"
                                        description="Buscá tu inmobiliaria  de confianza"/>
                            <RegretModal buttonTitle="Arrepentimiento" class="home-link-item" icon="hand-paper"
                                         buttonDescription="Formulario"/>
                        </div>
                    </section>
                    <section id="bottom" className="home-section">
                        <div className="site-content">
                            <InfoBox class="-top-corner"/>
                            <UserBox class="-top-corner"/>
                            <div className="features">
                                <ul className="feature-list">
                                    <li className="feature-item">
                                        <img src={facil} className="facil"
                                             alt="ilustración de una mano con un smart phone"/>
                                        <div className="feature-data">
                                            <h3>Fácil</h3>
                                            <p>Mínimos requisitos</p>
                                            <p>Resolución en 24 Hs</p>
                                            <p>una vez completada tu Solicitud</p>
                                        </div>
                                    </li>
                                    <li className="feature-item">
                                        <img src={seguro} className="seguro" alt="ilustración de un contrato sellado"/>
                                        <div className="feature-data">
                                            <h3>Sólido</h3>
                                            <p>Afianzamiento hasta la restitución del inmueble</p>
                                            <p>Soporte legal incluido</p>
                                        </div>
                                    </li>
                                    <li className="feature-item">
                                        <img src={confiable} className="confiable"
                                             alt="ilustración de dos manos estrechadas"/>
                                        <div className="feature-data">
                                            <h3>Confiable</h3>
                                            <p>Desde el 2011, nuestra trayectoria avala el<br/>
                                                compromiso de Finaer con el sector inmobiliario</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default Home;
